<template>
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>
    <div class="content">
      <div class="top">
        <div :class="activeTab == 1 ? 'top top-active' : 'top'" @click="handleChangeActiveTab(1)">车辆管理</div>
        <div :class="activeTab == 2 ? 'top top-active' : 'top'" @click="handleChangeActiveTab(2)">司机管理</div>
      </div>

      <div class="content-body" v-if="activeTab == 1">
        <div class="search">
          <el-input style="width:250px;margin-right:20px" size="small" v-model="paramsCar.licencePlate"
            placeholder="请输入车牌号"></el-input>
          <el-button size="small" type="primary" @click="searchClick">查询</el-button>
          <el-button size="small" type="primary" @click="handleAddCar">新增</el-button>
          <el-button size="small" type="primary" @click="handleImportDriver(1)">批量导入</el-button>
        </div>
        <div class="table">
          <el-table :data="carList" style="width: 100%">
            <el-table-column prop="licencePlate" label="车牌号" />
            <el-table-column prop="vehicleType" label="车辆类型" />
            <el-table-column prop="driverUserName" label="司机姓名" />
            <el-table-column prop="driverPhone" label="司机手机号" />
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="editCarClick(scope.row)">编辑</el-button>
                <el-button size="mini" type="success" @click="bindClick(scope.row.id)">绑定司机</el-button>
                <el-button size="mini" type="danger" @click="delCarClick(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination v-if="carCount" background :page-size="paramsCar.limit" layout="prev, pager, next"
            @current-change="pageTeamVehicleChange" :total="carCount">
          </el-pagination>
        </div>
      </div>

      <div class="content-body" v-if="activeTab == 2">
        <div class="search">
          <el-input v-model="paramsDriver.driverName" style="width:250px;margin-right:20px"
            placeholder="请输入司机姓名"></el-input>
          <el-input v-model="paramsDriver.driverPhone" style="width:250px;margin-right:20px"
            placeholder="请输入电话"></el-input>

          <el-button size="small" type="primary" @click="searchDriverClick">查询</el-button>
          <el-button size="small" type="primary" @click="handleAddDriver">新增</el-button>
          <el-button size="small" type="primary" @click="handleExportDriver">导出</el-button>
          <el-button size="small" type="primary" @click="handleImportDriver(2)">批量导入</el-button>
        </div>
        <div class="table">
          <el-table :data="driverList" style="width: 100%">
            <el-table-column prop="driverName" label="司机姓名" />
            <el-table-column prop="driverPhone" label="司机手机" />
            <el-table-column prop="idCard" label="身份证号码" />
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="editDriveClick(scope.row)">编辑</el-button>
                <el-button size="mini" type="danger" @click="delDriveClick(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination v-if="driverCount" background :page-size="paramsDriver.limit" layout="prev, pager, next"
            @current-change="pageTeamDriveChange" :total="driverCount">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 添加车辆信息 弹窗-->
    <el-dialog title="车辆信息" :visible.sync="dialogAddCarVisible" width="70%">
      <span class="dialog-text">添加/编辑车辆： 增加车辆品牌、保险截止日期、保险金额的信息项内容</span>
      <el-form :model="carForm" :rules="carRules" ref="carForm" label-width="170px" class="demo-ruleForm" size="small">
        <el-row>
          <el-col :span="8">
            <el-form-item label="车牌号" prop="licencePlate">
              <el-input type="text" v-model="carForm.licencePlate" placeholder="请输入车牌号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车牌类型" prop="vehicleType">
              <el-select v-model="carForm.vehicleType" placeholder="请选择" style="width: 100%;">
                <el-option v-for="item in shipCompanyList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车牌品牌" prop="vehicleBrand">
              <el-select v-model="carForm.vehicleBrand" placeholder="请选择" style="width: 100%;">
                <el-option v-for="item in brandList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="保险截止日期" prop="insuranceDate">
              <el-date-picker v-model="carForm.insuranceDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
                placeholder="选择日期" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保险金额" prop="insuranceMoney">
              <el-input type="number" v-model="carForm.insuranceMoney" placeholder="请输入保险金额"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="车辆照片" prop="vehiclePhoto">
              <el-upload class="upload-demo" action="''" :multiple="false" :show-file-list="false"
                :http-request="uploadImg" :limit="6">
                <el-button size="mini" type="primary">上传车辆照片</el-button>
              </el-upload>
              <div class="img-list">
                <div class="img-item" v-for="(item, index) in vehiclePhotoList" :key="item">
                  <img :src="item" class="upload-img" />
                  <div class="del" @click="delImgClick(index, 'img')">×</div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="行驶证" prop="drivingLicense">
              <el-upload class="upload-demo" action="''" :multiple="false" :show-file-list="false"
                :http-request="uploadLicenseImg">
                <el-button size="mini" type="primary">上传行驶证</el-button>
              </el-upload>
              <div class="img-list">
                <img :src="carForm.drivingLicense" v-if="carForm.drivingLicense" class="upload-img" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAddCarVisible = false">取 消</el-button>
        <el-button type="primary" @click="sumbitCar('carForm')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加司机信息 弹窗-->
    <el-dialog title="司机信息" :visible.sync="dialogAddDriverVisible" width="70%">
      <span class="dialog-text">添加/编辑司机</span>
      <el-form :model="driveFrom" :rules="driveRules" ref="driveFrom" label-width="170px" class="demo-ruleForm"
        size="small">
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" prop="driverName">
              <el-input type="text" v-model="driveFrom.driverName" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="driverPhone">
              <el-input type="text" v-model="driveFrom.driverPhone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="idCard">
              <el-input type="text" v-model="driveFrom.idCard" placeholder="请输入身份证号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="身份证正面" prop="idCarPhoto">
              <el-upload class="upload-demo" action="''" :multiple="false" :show-file-list="false"
                :http-request="uploadDriverIdCarPhotoImg">
                <el-button size="mini" type="primary">上传身份证正面</el-button>
              </el-upload>
              <div class="img-list">
                <img :src="driveFrom.idCarPhoto" v-if="driveFrom.idCarPhoto" class="upload-img" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证反面" prop="idCarPhotoFan">
              <el-upload class="upload-demo" action="''" :multiple="false" :show-file-list="false"
                :http-request="uploadDriverIdCarPhotoFanImg">
                <el-button size="mini" type="primary">上传行身份证反面</el-button>
              </el-upload>
              <div class="img-list">
                <img :src="driveFrom.idCarPhotoFan" v-if="driveFrom.idCarPhotoFan" class="upload-img" />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="行驶证" prop="drivingLicence">
              <el-upload class="upload-demo" action="''" :multiple="false" :show-file-list="false"
                :http-request="uploadDriverIdLicenceFanImg">
                <el-button size="mini" type="primary">上传行驶证</el-button>
              </el-upload>
              <div class="img-list">
                <img :src="driveFrom.drivingLicence" v-if="driveFrom.drivingLicence" class="upload-img" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="驾驶证有效期" prop="validityDate">
              <el-date-picker v-model="driveFrom.validityDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
                placeholder="选择日期" style="width: 100%;">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAddDriverVisible = false">取 消</el-button>
        <el-button type="primary" @click="sumbitDriver('driveFrom')">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="绑定司机" :visible.sync="dialogBindVisible" width="70%">
      <div class="search">
        <el-input v-model="paramsDriver.driverName" style="width:250px;margin-right:20px"
          placeholder="请输入司机姓名"></el-input>
        <el-input v-model="paramsDriver.driverPhone" style="width:250px;margin-right:20px"
          placeholder="请输入电话"></el-input>
        <el-button size="small" type="primary" @click="searchDriverClick">查询</el-button>
      </div>
      <div class="table">
        <el-table :data="driverList" style="width: 100%">
          <el-table-column prop="driverName" label="司机姓名" />
          <el-table-column prop="driverPhone" label="司机手机" />
          <el-table-column prop="idCard" label="身份证号码" />
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="bindDriveClick(scope.row)">绑定</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="driverCount" background :page-size="paramsDriver.limit" layout="prev, pager, next"
          @current-change="pageTeamDriveChange" :total="driverCount">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog title="批量导入司机信息" :visible.sync="dialogUploadDriverisible">
      <el-upload class="upload-demo" drag ref="carDriverUpload" :action="uploadDriverFileUrl"
        :on-success="uploadCarDriverSuccess" :before-upload="beforeUpload">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xlsx文件</div>
        <div class="el-upload__tip" slot="tip" style="color:#409EFF;" @click="downloadDriver"><em>下载模板</em></div>
      </el-upload>
    </el-dialog>

    <el-dialog title="批量导入车辆信息" :visible.sync="dialogUploadVehicleDisible">
      <el-upload class="upload-demo" drag ref="carDriverUpload" :action="uploadDriverVehicleUrl"
        :on-success="uploadCarVehicleSuccess" :before-upload="beforeUpload">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xlsx文件</div>
        <div class="el-upload__tip" slot="tip" style="color:#409EFF;" @click="downloadVechile"><em>下载模板</em></div>
      </el-upload>
    </el-dialog>


  </div>
</template>

<script>
import Header from '../components/Header.vue'
import { uploadFile } from '../util/request';
export default {
  name: 'carTeams_list',
  components: { Header },
  data() {
    return {
      activeTab: 1,

      //车辆信息
      carCount: 0,
      carList: [],
      paramsCar: {
        page: 1,
        limit: 10,
        licencePlate: ''
      },

      //添加车辆信息弹窗
      dialogAddCarVisible: false,
      dialogAddCarTitle: "",

      carForm: {
        licencePlate: "",
        vehicleType: "",
        vehicleBrand: "",
        insuranceDate: undefined,
        insuranceMoney: "",
        vehiclePhoto: "",
        drivingLicense: ""
      },
      carRules: {
        licencePlate: { required: true, message: '请输入车牌号', trigger: 'blur' },
        vehicleType: { required: true, message: '请输入车辆类型', trigger: 'blur' },
        vehicleBrand: { required: true, message: '请输入车辆品牌', trigger: 'blur' },
        insuranceDate: { required: true, message: '请输入保险截止日期', trigger: 'blur' },
        insuranceMoney: { required: true, message: '请输入保险金额', trigger: 'blur' },
        vehiclePhoto: { required: true, message: '请输入车辆照片', trigger: 'blur' },
        drivingLicense: { required: true, message: '请上传行驶证', trigger: 'blur' },
      },
      shipCompanyList: ["卡车", "小卡车"],
      brandList: ["宝马", "奔驰", "奥迪"],
      //车辆照片
      vehiclePhotoList: [],


      //司机管理
      dialogAddDriverVisible: false,
      driverCount: 0,
      driverList: [],
      paramsDriver: {
        page: 1,
        limit: 10,
        driverName: "",
        driverPhone: ""
      },

      driveFrom: {
        driverName: '',
        driverPhone: '',
        idCard: '',
        idCarPhoto: '',
        idCarPhotoFan: '',
        drivingLicence: '',
        validityDate: '',
      },
      driveRules: {
        driverName: { required: true, message: '请输入司机姓名', trigger: 'blur' },
        driverPhone: { required: true, message: '请输入司机手机号', trigger: 'blur' },
        idCard: { required: true, message: '请输入身份证号码', trigger: 'blur' },
        idCarPhoto: { required: true, message: '请上传身份证正面', trigger: 'blur' },
        idCarPhotoFan: { required: true, message: '请上传身份证反面', trigger: 'blur' },
        drivingLicence: { required: true, message: '请上传行驶证照片', trigger: 'blur' },
        validityDate: { required: true, message: '请输入有效期不能为空', trigger: 'blur' },
      },


      //绑定司机
      dialogBindDriverVisible: false,
      bandCarId: "",

      dialogBindVisible: false,
      //导入司机
      dialogUploadDriverisible: false,
      uploadDriverFileUrl: "",
      //导入车辆
      dialogUploadVehicleDisible: false,
      uploadDriverVehicleUrl: "",


    }
  },
  mounted() {
    this.paramsCar.page = 1;
    this.carList = [];
    this.carCount = 0;
    this.getTeamVehicleList()
  },

  methods: {
    handleChangeActiveTab(item) {
      this.activeTab = item;
      if (this.activeTab == 1) {
        this.paramsCar.page = 1;
        this.carList = [];
        this.carCount = 0;
        this.getTeamVehicleList()
      } else if (this.activeTab == 2) {
        this.paramsDriver.page = 1;
        this.driverList = [];
        this.driverCount = 0;
        this.getTeamDriverList()
      }
    },
    pageTeamVehicleChange(e) {
      this.paramsCar.page = e;
      this.getTeamVehicleList()
    },
    searchClick() {
      this.paramsCar.page = 1;
      this.getTeamVehicleList()
    },
    async getTeamVehicleList() {
      let res = await this.$req({
        url: `/carTeamVehicle/query`,
        method: 'GET',
        data: { ...this.paramsCar, teamId: JSON.parse(localStorage.getItem("userinfo")).id }
      })
      if (res.code == 200) {
        this.carList = res.data
        this.carCount = res.count
      }
    },
    handleAddCar() {
      this.dialogAddCarVisible = true;
      this.dialogAddCarTitle = "添加车辆信息";
      this.carForm.id = '';

      this.carForm = {
        licencePlate: "",
        vehicleType: "",
        vehicleBrand: "",
        insuranceDate: undefined,
        insuranceMoney: "",
        vehiclePhoto: "",
        drivingLicense: ""
      }

    },
    editCarClick(data) {
      this.carForm = data;
      this.vehiclePhotoList = JSON.parse(data.vehiclePhoto);

      this.dialogAddCarVisible = true
    },
    delImgClick(index, type) {
      this.vehiclePhotoList.splice(index, 1)
    },
    async delCarClick(id) {
      let res = await this.$req({
        url: `/carTeamVehicle/${id}`,
        method: 'DELETE'
      })
      if (res.code == 200) {
        this.getTeamVehicleList()
        this.$message({ type: 'success', message: '删除成功！', duration: 1000 })
      }
    },
    async sumbitCar(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let url;
          console.log(this.carForm, "this.carForm")
          if (this.carForm.id) {
            url = `/carTeamVehicle/${this.carForm.id}`
          } else {
            url = '/carTeamVehicle'
          }
          let res = await this.$req({
            url,
            method: 'POST',
            data: {
              ...this.carForm,
              teamId: JSON.parse(localStorage.getItem("userinfo")).id
            }
          })
          if (res.code == 200) {
            this.getTeamVehicleList()
            this.dialogAddCarVisible = false
            if (!this.carForm.id) {
              this.$message({ type: 'success', message: '新增成功！', duration: 1000 })
            } else {
              this.$message({ type: 'success', message: '编辑成功！', duration: 1000 })
            }
          } else {
            this.$message({ type: 'error', message: res.msg, duration: 1000 })
          }
        }
      })
    },
    handleAddDriver() {
      this.dialogAddDriverVisible = true;
      this.driveFrom.id = '';

      this.driveFrom = {
        driverName: '',
        driverPhone: '',
        idCard: '',
        idCarPhoto: '',
        idCarPhotoFan: '',
        drivingLicence: '',
        validityDate: '',
      }
    },
    async getTeamDriverList() {
      let res = await this.$req({
        url: `/carTeamDriver/query`,
        method: 'GET',
        data: { ...this.paramsDriver, teamId: JSON.parse(localStorage.getItem("userinfo")).id }
      })
      if (res.code == 200) {
        this.driverList = res.data
        this.driverCount = res.count
      }
    },
    searchDriverClick() {
      this.paramsDriver.page = 1;
      this.getTeamDriverList()
    },
    handleExportDriver() {
      let params = this.joinGetParam({ ...this.paramsDriver, teamId: JSON.parse(localStorage.getItem("userinfo")).id });

      this.$downloadFile({
        url: "/carTeamDriver/exportTeamDriver",
        method: 'get',
        searchParams: params
      });

    },
    pageTeamDriveChange(e) {
      this.paramsDriver.page = e;
      this.getTeamDriverList()
    },
    editDriveClick(data) {
      this.driveFrom = data;
      this.dialogAddDriverVisible = true
    },
    async delDriveClick(id) {
      let res = await this.$req({
        url: `/carTeamDriver/${id}`,
        method: 'DELETE'
      })
      if (res.code == 200) {
        this.getTeamDriverList()
        this.$message({ type: 'success', message: '删除成功！', duration: 1000 })
      }
    },
    async sumbitDriver(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let url;
          if (this.driveFrom.id) {
            url = `/carTeamDriver/${this.driveFrom.id}`
          } else {
            url = '/carTeamDriver'
          }
          let res = await this.$req({
            url,
            method: 'POST',
            data: {
              ...this.driveFrom,
              teamId: JSON.parse(localStorage.getItem("userinfo")).id
            }
          })
          if (res.code == 200) {
            this.getTeamDriverList()
            this.dialogAddDriverVisible = false
            if (!this.driveFrom.id) {
              this.$message({ type: 'success', message: '新增成功！', duration: 1000 })
            } else {
              this.$message({ type: 'success', message: '编辑成功！', duration: 1000 })
            }
          } else {
            this.$message({ type: 'error', message: res.msg, duration: 1000 })
          }
        }
      })
    },

    bindClick(id) {
      this.bandCarId = id;
      this.paramsDriver.page = 1;
      this.getTeamDriverList()

      this.dialogBindVisible = true;
    },

    async bindDriveClick(data) {
      let url = "/carTeamVehicle/bindDriver/" + this.bandCarId + "/" + data.id;
      let res = await this.$req({
        url,
        method: 'GET'
      })

      if (res.code == 200) {
        this.bandCarId = "";
        this.dialogBindVisible = false;
        this.paramsDriver.page = 1;
        this.getTeamVehicleList()

        this.$message({ type: 'success', message: '绑定成功！', duration: 1000 })
      } else {
        this.$message({ type: 'error', message: res.msg, duration: 1000 })
      }
    },
    handleImportDriver(type) {
      if (type == 1) {
        this.dialogUploadVehicleDisible = true;
        this.uploadDriverVehicleUrl =  "http://47.109.34.125:7103/logistics_hongde/carTeamVehicle/importExcel/" + JSON.parse(localStorage.getItem("userinfo")).id;
      } else {
        this.dialogUploadDriverisible = true
        this.uploadDriverFileUrl = "http://47.109.34.125:7103/logistics_hongde/carTeamDriver/importExcel/" + JSON.parse(localStorage.getItem("userinfo")).id;
      }
    },

    beforeUpload(file) {
      const isExcel = /\.(xlsx|xls|csv)$/.test(file.name);
      if (!isExcel) {
        this.$message.error('只能上传.xlsx、.xls、.csv 文件!');
        return false;
      }
      return true;
    },
    uploadCarDriverSuccess(res) {
      if (res.code == 200) {
        this.dialogUploadDriverisible = false;
        this.paramsDriver.page = 1;
        this.getTeamDriverList()
        this.$message({ type: 'success', message: '导入成功！', duration: 1000 })
      } else {
        this.$message({ type: 'error', message: res.msg, duration: 1000 })
      }
    },


    uploadCarVehicleSuccess(res) {
      if (res.code == 200) {
        this.dialogUploadVehicleDisible = false;
        this.paramsCar.page = 1;
        this.getTeamVehicleList()
        this.$message({ type: 'success', message: '导入成功！', duration: 1000 })
      } else {
        this.$message({ type: 'error', message: res.msg, duration: 1000 })
      }
    },
    downloadVechile(){
        //下载车辆信息
        window.open("https://lei-application.oss-cn-nanjing.aliyuncs.com/halo6/car.xlsx")
    },
    downloadDriver(){
      window.open("https://lei-application.oss-cn-nanjing.aliyuncs.com/halo6/driver.xlsx")
    },


    async uploadLicenseImg({ file }) {
      let loading = this.$loading({ text: '上传中' });
      let res = await this.$req({
        url: `/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method: 'GET',
        api: '/mng'
      })
      if (res.code == 200) {
        let img_res = await this.$uploadImg(res.data, file)
        this.carForm.drivingLicense = img_res;
      }
      loading.close()
    },
    async uploadImg({ file }) {
      console.log('uploadFile', file)
      let loading = this.$loading({ text: '上传中' });
      let res = await this.$req({
        url: `/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method: 'GET',
        api: '/mng'
      })
      if (res.code == 200) {
        let img_res = await this.$uploadImg(res.data, file)
        this.vehiclePhotoList.push(img_res)
        this.carForm.vehiclePhoto = JSON.stringify(this.vehiclePhotoList)
      }
      loading.close()
    },
    //司机照片上传
    async uploadDriverIdCarPhotoImg({ file }) {
      console.log('uploadFile', file)
      let loading = this.$loading({ text: '上传中' });
      let res = await this.$req({
        url: `/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method: 'GET',
        api: '/mng'
      })
      if (res.code == 200) {
        let img_res = await this.$uploadImg(res.data, file)
        this.driveFrom.idCarPhoto = img_res;
      }
      loading.close()
    },
    async uploadDriverIdCarPhotoFanImg({ file }) {
      console.log('uploadFile', file)
      let loading = this.$loading({ text: '上传中' });
      let res = await this.$req({
        url: `/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method: 'GET',
        api: '/mng'
      })
      if (res.code == 200) {
        let img_res = await this.$uploadImg(res.data, file)
        this.driveFrom.idCarPhotoFan = img_res;
      }
      loading.close()
    },
    async uploadDriverIdLicenceFanImg({ file }) {
      console.log('uploadFile', file)
      let loading = this.$loading({ text: '上传中' });
      let res = await this.$req({
        url: `/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method: 'GET',
        api: '/mng'
      })
      if (res.code == 200) {
        let img_res = await this.$uploadImg(res.data, file)
        this.driveFrom.drivingLicence = img_res;
      }
      loading.close()
    },
  },

}
</script>

<style scoped lang="stylus">
.container
    background: #fff
    padding 30px

   
    .content
      display:block
      .top
        display flex
        justify-content space-evenly;
        align-items center
        font-size: 20px
        cursor: pointer
      .top-active
        color: #325AB4
        border-bottom: 2px solid #325AB4
      .content-body
        margin-top: 30px
        .search
            margin-bottom 20px
    /deep/ .el-dialog__body
      padding-top: 0px !important
      .dialog-text
        font-size: 12px
        margin-top: -20px
        
      .el-form
        margin-top: 20px !important
    
    .upload-img
      width: 150px
      height: 100px
    .img-list
      display:flex
      margin-top:10px
      flex-wrap wrap
      .img-item
        margin 0 20px 20px 0
        width 160px
        height 100px
        position relative
        .del
          cursor pointer
          font-size 20px
          font-weight 600
          position: absolute
          top 0
          right 0
</style>