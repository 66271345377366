<template>
<!-- 我要发单-未认证 -->
  <div class="component">
    <img src="@/assets/weirenzheng.png" alt="">
    <div class="text">通过派单方认证之后，才可进行发单操作</div>
    <div class="btn" @click="btnClick">立即认证</div>
  </div>
</template>

<script>
export default {
  name: 'NotCertified',
  data(){
    return{

    }
  },
  props:{

  },
  methods:{
    btnClick(){
      this.$emit('authenticationClick')
    },
  }
}
</script>

<style scoped lang="stylus">
.component
  padding-top: 80px
  display: flex
  flex-direction: column
  align-items: center
  img
    width 375px
    height 300px
    margin-bottom: 50px
  .text
    font-size: 18px
    line-height: 26px
    color: #585E60
    margin-bottom: 44px
  .btn
    width 320px
    height 36px
    line-height: 36px
    text-align: center
    background: #015DE7
    font-size: 14px
    color: #fff
    border-radius: 4px
    cursor pointer
</style>
