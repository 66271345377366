<template>
<!-- 派单方认证-审核未通过 -->
  <div class="component">
    <img src="@/assets/weitongguo.png" alt="">
    <div class="text">很抱歉，审核未通过</div>
    <div class="reason" v-if="info.authMsg">{{info.authMsg}}</div>
    <div class="btn" @click="aginClick">重新提交</div>
  </div>
</template>

<script>
export default {
  name: 'NotCertified',
  data(){
    return{

    }
  },
  props:{
    info:{type:Object,default:{}}
  },
  methods:{
    // 点击重新提交
    aginClick(){
      this.$emit('aginClick')
    }
  }
}
</script>

<style scoped lang="stylus">
.component
  padding-top: 66px
  display: flex
  flex-direction: column
  align-items: center
  img
    width 428px
    height 228px
  .text
    font-size: 16px
    line-height: 24px
    color: #325AB4
    margin-top: 48px
  .reason
    font-size: 12px
    line-height: 20px
    color: #7A8BA6
    margin-top: 20px
  .btn
    width: 96px;
    height: 32px;
    background-color: rgba(39, 137, 238, 1);
    font-size: 14px
    height: 32px
    text-align: center
    color: #fff
    border-radius: 4px
    line-height: 32px
    margin-top: 40px
    cursor pointer
</style>
