<template>
<!-- 派单方认证-提交信息 -->
  <div class="component">
    <div class="title">派单方认证</div>

    <div class="form">
      <div class="form-name">公司信息</div>
      <div class="form-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
          <el-form-item label="营业执照名称：" prop="businessLicenseName">
            <el-input v-model="ruleForm.businessLicenseName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="公司法人：" prop="legal">
            <el-input v-model="ruleForm.legal" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="法人身份证号：" prop="legalIdentityCard">
            <el-input v-model="ruleForm.legalIdentityCard" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="联系方式：" prop="contactPhone">
            <el-input v-model="ruleForm.contactPhone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="微信号：" prop="wechatAccount">
            <el-input v-model="ruleForm.wechatAccount" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="统一信用代码：" prop="socialUniformCreditCode">
            <el-input v-model="ruleForm.socialUniformCreditCode" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="上传营业执照（副本）：" prop="businessLicenseImage">
            <div class="upload">
              <img src="@/assets/upload.png" alt="" v-if="!ruleForm.businessLicenseImage">
              <img :src="ruleForm.businessLicenseImage" alt="" v-else>
              <el-upload
                class="upload-transparent"
                list-type="picture-card"
                action="''"
                :multiple="false"
                :show-file-list="false"
                :on-change="uploadChange"
                :http-request="uploadLicenseImage">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="上传法人身份证：" prop="legalIdentityCardImageFront">
            <div class="card-imgs">
              <div class="positive">
                <div class="upload">
                  <img src="@/assets/upload.png" alt="" v-if="!ruleForm.legalIdentityCardImageFront">
                  <img :src="ruleForm.legalIdentityCardImageFront" alt="" v-else>
                  <el-upload
                    class="upload-transparent"
                    list-type="picture-card"
                    action="''"
                    :multiple="false"
                    :on-change="idFrontChange"
                    :show-file-list="false"
                    :http-request="uploadCardImageFront">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
                <div class="positive-text">身份证正面</div>
              </div>
              <div class="positive">
                <div class="upload">
                  <img src="@/assets/upload.png" alt="" v-if="!ruleForm.legalIdentityCardImageBack">
                  <img :src="ruleForm.legalIdentityCardImageBack" alt="" v-else>
                  <el-upload
                    class="upload-transparent"
                    list-type="picture-card"
                    action="''"
                    :multiple="false"
                    :on-change="idBackChange"
                    :show-file-list="false"
                    :http-request="uploadCardImageBack">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
                <div class="positive-text">身份证正面</div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-btns">
        <div class="cancel" @click="submitCancel">取消</div>
        <div class="submit" @click="submitForm('ruleForm')">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotCertified',
  props:{
    Certified:{type:String}
  },
  data(){
    // 校验手机号
    var validatePhone = (rule, value, callback) => {
      let reg = /^1[1-9]\d{9}$/
      if (!value) {
        callback(new Error('请输入手机号码'));
      } else if (!reg.test(value)) {
        callback(new Error('手机号码格式错误'));
      } else {
        callback();
      }
    };
    // 校验身份证号码
    var validateCardNumber = (rule, value, callback) => {
      let reg = /(^$)|(^\d{15}$)|(^\d{17}(x|X|\d)$)/
      if (!value) {
        callback(new Error('请输入法人身份证号'));
      } else if (!reg.test(value)) {
        callback(new Error('身份证号格式错误'));
      } else {
        callback();
      }
    };
    // 社会统一信用代码验重
    var validateCreditCode = async (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入社会统一信用代码'));
        return
      }
      let res = await this.$req({
        url:`/merchant/checkAgainSocialUniformCreditCode/${value}`,
        method:'GET',
        data:{
          socialUniformCreditCode:value,
          id:JSON.parse(localStorage.getItem('userinfo')).id
        }
      })
      if(res.code==200){
        if(res.data){
          callback();
        }else{
          callback(new Error('您输入的社会统一信用代码已被注册过'));
        }
      }else{
        callback(new Error(res.msg));
      }
    };
    return{
      rules:{
        businessLicenseName: [{ required: true, message: '请输入营业执照名称', trigger: 'change' }],
        legal: [{ required: true, message: '请输入公司法人姓名', trigger: 'change' }],
        wechatAccount: [{ required: true, message: '请输入微信号', trigger: 'change' }],
        legalIdentityCard: [{ required: true, validator: validateCardNumber, trigger: 'change' }],
        contactPhone: [{ required: true, validator: validatePhone, trigger: 'change' }],
        socialUniformCreditCode: [{ required: true, validator: validateCreditCode, trigger: 'change' }],
        businessLicenseImage: [{ required: true, message: '请上传上传营业执照（副本）', trigger: 'change' }],
        legalIdentityCardImageFront: [{ required: true, message: '请上传身份证正面', trigger: 'blur' }],
        legalIdentityCardImageBack: [{ required: true, message: '请上传身份证反面', trigger: 'blur' }],
      },
      ruleForm:{
        "socialUniformCreditCode": '',
        "contactPhone": "",
        "businessLicenseImage": "",
        "headImage": "",
        "remark": "",
        "legalIdentityCardImageBack": "",
        "legalIdentityCardImageFront": "",
        "legal": "",
        "legalIdentityCard": "",
        "nickname": "",
        "businessLicenseName": "",
        "wechatAccount":""
      },
      bImage:'',
      bImageList:'',
      idFront:'',
      idFrontList:'',
      idBack:'',
      idBackList:'',
    }
  },
  mounted(){

  },
  methods:{
    uploadChange(file, fileList) {
      this.bImage = file;
      this.bImageList = fileList;
      if(fileList.length == 1) {
        let {businessLicenseImage,...data} = this.rules;
        this.rules = data;
      }
      this.$refs.ruleForm.clearValidate('businessLicenseImage');
    },
    idFrontChange(file, fileList) {
      this.bImage = file;
      this.bImageList = fileList;
      if(fileList.length == 1) {
        let {legalIdentityCardImageFront,...data} = this.rules;
        this.rules = data;
      }
      this.$refs.ruleForm.clearValidate('legalIdentityCardImageFront');
    },
    idBackChange(file, fileList) {
      this.bImage = file;
      this.bImageList = fileList;
      if(fileList.length == 1) {
        let {legalIdentityCardImageBack,...data} = this.rules;
        this.rules = data;
      }
      this.$refs.ruleForm.clearValidate('legalIdentityCardImageBack');
    },
    // 点击提交按钮
    submitForm(formName){
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let url = '/merchant/apply'
          if(this.Certified=='again'){
            url = `/merchant/${JSON.parse(localStorage.getItem('merchant')).id}/again`
          }
          let res = await this.$req({
            url,
            method:'POST',
            data:this.ruleForm
          })
          if(res.code==200){
            this.$message({
              type:'success',
              message:'提交成功！',
              duration:1000
            })
            this.$emit('submitSuccess')
          }else{
            this.$message({
              message:res.msg,
              duration:1000
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 点击取消按钮
    submitCancel(){
      this.$emit('submitCancel')
    },
    uploadLicenseImage({file}){
      this.upload(file,'businessLicenseImage')
    },
    uploadCardImageFront({file}){
      this.upload(file,'legalIdentityCardImageFront')
    },
    uploadCardImageBack({file}){
      this.upload(file,'legalIdentityCardImageBack')
    },
    // 上传图片到OSS 同时派发一个事件给父组件监听
    async upload (file,name) {
        let loading = this.$loading({text:'上传中'});
        let res = await this.$req({
            url:`/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
            method:'GET',
            api :'/mng'
        })
        if(res.code==200){
            let img_res = await this.$uploadImg(res.data,file)
            this.$set(this.ruleForm,name,img_res)
        }
        loading.close()
    },
  }
}
</script>

<style scoped lang="stylus">
.component
  color: #1A2537
  .title
    border-bottom: 1px solid #EEEEEE
    padding: 0 40px 20px
    font-size: 16px
    line-height: 24px
    width 840px
    transform: translateY(-20px)
  .form
    display: flex
    flex-direction: column
    .form-name
      width 100%
      text-align: left
      padding: 0px 40px 20px
    .form-content
      width 100%
      padding: 0 320px 0 80px
      /deep/ .el-form-item__label
        color: #7A8BA6
      .upload
        width: 98px;
        height: 98px;
        border-radius: 4px;
        // background-color: rgba(255, 255, 255, 1);
        // border: 1px dashed rgba(199, 209, 216, 1);
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        position relative
        &>img
          width 100%
          height 100%
        .upload-transparent
          width: 98px;
          height: 98px;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 100;
          /deep/ .el-upload--picture-card
              width: 100%;
              height: 100%;
      .card-imgs
        display: flex
        .positive 
          margin-right: 34px
          .positive-text
            font-size: 14px
            line-height: 22px
            color: #7A8BA6
            text-align: center
            width 100%
            margin-top: 10px
    .form-btns
      display: flex
      padding-left: 252px
      div
        width: 68px;
        height: 32px;
        border: 1px solid rgba(235, 240, 252, 1);    
        color: #7A8BA6
        font-size: 14px
        line-height: 22px
        line-height: 30px
        text-align: center
        cursor pointer
      .submit
        background-color: rgba(235, 240, 252, 1);
        border: 1px solid rgba(235, 240, 252, 1);
        margin-left: 10px
</style>
