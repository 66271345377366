<template>
  <div class="container">

  </div>
</template>

<script>
export default {
  name: 'index',
  data(){
    return{
      
    }
  },
  mounted(){
    console.log('index mounted')
    if(!localStorage.getItem('token')){
      this.$router.push('/login')
    }else{
      if(localStorage.getItem('refreshToken')){
        this.$tokenRefresh()
        this.$router.push('/send_bill')
      }else{
        this.$router.push('/login')
      }
      // this.$router.push({name: 'send_bill'})
    }
  },
  methods:{
    
  }
}
</script>
