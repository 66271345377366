<template>
    <el-dialog title="详情" :visible.sync="infoVisible" width="60%">
        <div class="content">
            <el-row :gutter="20">
                <el-col :span="waybillDetailData.waybillIncludeThirdPlace == 'Yes' ? 8 : 12"
                    style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
                    <el-row style="color: dodgerblue; font-weight: bold;">提箱地</el-row>
                    <el-row style="color: black; font-weight: bold; font-size: 18px;">{{
                        waybillDetailData.firstPlaceDetail || '' }}</el-row>
                    <el-row style="color: black; font-size: 14px;">{{ waybillDetailData.firstPlaceProvince }}
                        {{ waybillDetailData.firstPlaceCity }} {{ waybillDetailData.firstPlaceDistrict }}</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">提箱有效时间段:</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">{{
                        dateFormat2Str(Math.trunc(waybillDetailData.firstPlaceBeginTime / 100)) }} - {{
                            dateFormat2Str(Math.trunc(waybillDetailData.firstPlaceEndTime / 100)) }}</el-row>
                </el-col>

                <el-col :span="waybillDetailData.waybillIncludeThirdPlace == 'Yes' ? 8 : 12"
                    style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
                    <el-row style="color: darkorange; font-weight: bold;">送货/装货地</el-row>
                    <el-row style="color: black; font-weight: bold; font-size: 18px;">{{
                        waybillDetailData.secondPlaceDetail || '' }}</el-row>
                    <el-row style="color: black; font-size: 14px;">{{ waybillDetailData.secondPlaceProvince }}
                        {{ waybillDetailData.secondPlaceCity }} {{ waybillDetailData.secondPlaceDistrict }}</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">送货/装货货有效时间段:</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">{{
                        dateFormat2Str(Math.trunc(waybillDetailData.secondPlaceBeginTime / 100)) }} - {{
                            dateFormat2Str(Math.trunc(waybillDetailData.secondPlaceEndTime / 100)) }}</el-row>
                </el-col>

                <el-col v-if="waybillDetailData.waybillIncludeThirdPlace == 'Yes'" :span="8"
                    style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
                    <el-row style="color: forestgreen; font-weight: bold;">收箱地</el-row>
                    <el-row style="color: black; font-weight: bold; font-size: 18px;">{{
                        waybillDetailData.thirdPlaceDetail || '' }}</el-row>
                    <el-row style="color: black; font-size: 14px;">{{ waybillDetailData.thirdPlaceProvince }}
                        {{ waybillDetailData.thirdPlaceCity }} {{ waybillDetailData.thirdPlaceDistrict }}</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">到收箱地有效时间段:</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">{{
                        dateFormat2Str(Math.trunc(waybillDetailData.thirdPlaceBeginTime / 100)) }} - {{
                            dateFormat2Str(Math.trunc(waybillDetailData.thirdPlaceEndTime / 100)) }}</el-row>
                </el-col>
            </el-row>

            <el-row :gutter="2">
                <el-col :span="12">
                    <el-card class="box-card basicDiv">
                        <div slot="header" class="clearfix">
                            <span style="font-weight: bold; font-size: 16px;">接单司机</span>
                        </div>

                        <el-row :gutter="5">
                            <el-col :span="12">司机姓名：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.driverName || '' }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">司机电话：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.driverContactPhone || '' }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">车牌号：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.driverCarNumber ||
                                waybillOrderDetailData.teamCarNumber || '' }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">接单时间：</el-col>
                            <el-col :span="12">
                                {{ dayjs(waybillOrderDetailData.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') }}
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card class="box-card basicDiv">
                        <div slot="header" class="clearfix">
                            <span style="font-weight: bold; font-size: 16px;">基本信息</span>
                        </div>

                        <el-row :gutter="5">
                            <el-col :span="12">派单方电话：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.merchantContactPhone || '' }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">船公司：</el-col>
                            <el-col :span="12">{{ waybillDetailData.shipCompanyCode || '' }} {{
                                waybillDetailData.shipCompanyName || '' }}</el-col>
                        </el-row>

                        <!-- <el-row :gutter="5">
                            <el-col :span="12">提示：</el-col>
                            <el-col :span="12" class="text-lose">{{ waybillDetailData.shipRemark || '' }}</el-col>
                        </el-row> -->

                        <el-row :gutter="5">
                            <el-col :span="12">提（运）单号：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.waybillExtractIdentity || '' }}</el-col>
                        </el-row>
                    </el-card>

                    <el-card class="box-card deliveryDiv" style="margin-top: 5px;">
                        <div slot="header" class="clearfix">
                            <span style="font-weight: bold; font-size: 16px;">配送要求</span>
                        </div>

                        <el-row :gutter="5">
                            <el-col :span="12">车况要求：</el-col>
                            <el-col :span="12">{{ waybillDetailData.carConditionDemand || '' }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">配送要求：</el-col>
                            <el-col :span="12">{{ waybillDetailData.carDeliveryDemand || '' }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">备注：</el-col>
                            <el-col :span="12">{{ waybillDetailData.remark || '' }}</el-col>
                        </el-row>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card class="box-card basicDiv" v-if="waybillOrderDetailData.containerSealNums">
                        <div slot="header" class="clearfix"
                            style="display: flex;align-items: center;justify-content: space-between">
                            <span style="font-weight: bold; font-size: 16px;">箱号铅封</span>
                            <!-- <el-button type="primary" @click="edit(waybillOrderDetailData.id)"
                                style="padding: 8px">编辑铅封号</el-button> -->
                        </div>

                        <div v-for="(item, index) in JSON.parse(waybillOrderDetailData.containerSealNums)" :key="index">
                            <el-row :gutter="5">
                                <el-col :span="12">箱{{ index + 1 }}·箱号：</el-col>
                                <el-col :span="12">{{ item.identity || '' }}</el-col>
                            </el-row>

                            <el-row :gutter="5" v-if="item.sealIdentity">
                                <el-col :span="12">箱{{ index + 1 }}·铅封号：</el-col>
                                <el-col :span="12">{{ item.sealIdentity || '' }}</el-col>
                            </el-row>
                        </div>
                    </el-card>

                    <el-card class="box-card containerDiv">
                        <div slot="header" class="clearfix">
                            <span style="font-weight: bold; font-size: 16px;">集装箱信息</span>
                        </div>

                        <el-row :gutter="5">
                            <el-col :span="12">尺寸：</el-col>
                            <el-col :span="12">{{ waybillDetailData.containerSize }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">箱型：</el-col>
                            <el-col :span="12">{{ waybillDetailData.containerShape }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">空/重箱：</el-col>
                            <el-col :span="12" v-if="waybillDetailData.containerEmptyOrFull == 'Empty'">空箱</el-col>
                            <el-col :span="12" v-if="waybillDetailData.containerEmptyOrFull == 'Full'">重箱</el-col>
                        </el-row>

                        <el-row :gutter="5" v-if="waybillDetailData.containerWeight">
                            <el-col :span="12">箱重区间：</el-col>
                            <el-col :span="12">{{ waybillDetailData.containerWeight || '' }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">单箱货重:</el-col>
                            <el-col :span="12">{{ waybillDetailData.goodsWeightMin || '0' }}</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">配送形式：</el-col>
                            <el-col :span="12" v-if="waybillDetailData.deliveryMode == 'Single'">单背</el-col>
                            <el-col :span="12" v-if="waybillDetailData.deliveryMode == 'Double'">双背</el-col>
                            <el-col :span="12" v-if="waybillDetailData.deliveryMode == 'More'">多背</el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">集装箱数量：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.containerAmount || '' }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">箱门朝向：</el-col>
                            <el-col :span="12">
                                {{ waybillDetailData.orientationStr }}
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">无货回空</el-col>
                            <el-col :span="12">
                                {{ waybillOrderDetailData.outStock === 'Yes' ? "是" : "否" }}
                            </el-col>
                        </el-row>
                    </el-card>

                    <el-card class="box-card costDiv" style="margin-top: 5px;">
                        <div slot="header" class="clearfix"
                            style="display: flex;align-items: center;justify-content: space-between">
                            <span style="font-weight: bold; font-size: 16px;">费用信息</span>
                            <!-- <el-button type="primary" @click="placeFee" style="padding: 8px">补充修改垫付金额</el-button> -->
                        </div>

                        <el-row :gutter="5">
                            <el-col :span="12">每车运费：</el-col>
                            <el-col :span="12">{{ $math.multiply(waybillDetailData.deliveryOnceCost,
                                $math.subtract(1,service_fee_scale)) }}
                                元/辆</el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillDetailData.urgentState == 'Yes'">
                            <el-col :span="12">每车加急费运费：</el-col>
                            <el-col :span="12">{{ waybillDetailData.urgentDeliveryOnceCost * (1 -
                                service_fee_scale).toFixed(2) }}
                                元/辆</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">垫付费用：</el-col>
                            <el-col :span="12">{{ (((waybillOrderDetailData.firstPlaceFee ||
                                0) * 1) + ((waybillOrderDetailData.secondPlaceFee ||
                                    0) * 1) + ((waybillOrderDetailData.thirdPlaceFee || 0) * 1)) }} 元</el-col>
                        </el-row>

                        <!-- <el-row :gutter="5">
                            <el-col :span="12">服务费金额：</el-col>
                            <el-col :span="12">{{
                                (waybillDetailData.deliveryOnceCost + waybillDetailData.urgentDeliveryOnceCost) *
                                service_fee_scale }} 元</el-col>
                        </el-row> -->

                        <el-row :gutter="5">
                            <el-col :span="12">发票：</el-col>
                            <el-col :span="12" v-if="waybillDetailData.provideInvoice == 'Yes'">提供</el-col>
                            <el-col :span="12" v-if="waybillDetailData.provideInvoice == 'No'">不提供</el-col>
                        </el-row>
                    </el-card>

                    <el-card class="box-card costDiv" style="margin-top: 5px;">
                        <div slot="header" class="clearfix">
                            <span style="font-weight: bold; font-size: 16px;">节点信息</span>
                        </div>

                        <el-row :gutter="5">
                            <el-col :span="12">接单时间：</el-col>
                            <el-col :span="12">{{ dayjs(waybillOrderDetailData.createTime * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.undoTime">
                            <el-col :span="12">撤单时间：</el-col>
                            <el-col :span="12">{{ dateFormat2Str(Math.trunc(waybillOrderDetailData.undoTime / 100))
                                }}</el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.firstPlaceConfirmTime">
                            <el-col :span="12">提箱时间：</el-col>
                            <el-col :span="12">{{ dateFormat2Str(Math.trunc(waybillOrderDetailData.firstPlaceConfirmTime
                                / 100)) }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">提箱垫付费：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.firstPlaceFee || 0 }}元</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">提箱照片：</el-col>
                            <el-col :span="12" v-if="waybillOrderDetailData.firstPlaceImage">
                                <div v-for="(img, index) in JSON.parse(waybillOrderDetailData.firstPlaceImage)"
                                    :key="index">
                                    <el-image class="point-img" :src="img" alt=""
                                        :preview-src-list="JSON.parse(waybillOrderDetailData.firstPlaceImage)"></el-image>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.transferApplyTime">
                            <el-col :span="12">申请转单时间：</el-col>
                            <el-col :span="12">{{ dateFormat2Str(Math.trunc(waybillOrderDetailData.transferApplyTime /
                                100)) }}</el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.transferApplyTime">
                            <el-col :span="12">申请转单时间：</el-col>
                            <el-col :span="12">{{ dateFormat2Str(Math.trunc(waybillOrderDetailData.transferApplyTime /
                                100)) }}</el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.transferAcceptTime">
                            <el-col :span="12" v-if="waybillOrderDetailData.transferState == 'Accept'">转单成功：</el-col>
                            <el-col :span="12" v-if="waybillOrderDetailData.transferState == 'Reject'">转单失败：</el-col>
                            <el-col :span="12">{{ dateFormat2Str(Math.trunc(waybillOrderDetailData.transferAcceptTime /
                                100)) }}</el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.secondPlaceArriveTime">
                            <el-col :span="12">到达装卸地时间：</el-col>
                            <el-col :span="12">{{ dateFormat2Str(Math.trunc(waybillOrderDetailData.secondPlaceArriveTime
                                / 100)) }}</el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.secondPlaceConfirmTime">
                            <el-col :span="12">送货/装货时间：</el-col>
                            <el-col :span="12">{{
                                dateFormat2Str(Math.trunc(waybillOrderDetailData.secondPlaceConfirmTime / 100))
                                }}</el-col>
                        </el-row>


                        <el-row :gutter="5">
                            <el-col :span="12">送货/装货垫付费：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.secondPlaceFee || 0 }}元</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">送货/装货照片：</el-col>
                            <el-col :span="12" v-if="waybillOrderDetailData.secondPlaceImage">
                                <div v-for="(img, index) in JSON.parse(waybillOrderDetailData.secondPlaceImage)"
                                    :key="index">
                                    <el-image class="point-img" :src="img" alt=""
                                        :preview-src-list="JSON.parse(waybillOrderDetailData.secondPlaceImage)"></el-image>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5" v-if="waybillOrderDetailData.thirdPlaceConfirmTime">
                            <el-col :span="12">到达收箱地时间：</el-col>
                            <el-col :span="12">{{ dateFormat2Str(Math.trunc(waybillOrderDetailData.thirdPlaceConfirmTime
                                / 100)) }}</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">收箱垫付费：</el-col>
                            <el-col :span="12">{{ waybillOrderDetailData.thirdPlaceFee || 0 }}元</el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">收箱货照片：</el-col>
                            <el-col :span="12" v-if="waybillOrderDetailData.thirdPlaceImage">
                                <div v-for="(img, index) in JSON.parse(waybillOrderDetailData.thirdPlaceImage)"
                                    :key="index">
                                    <el-image class="point-img" :src="img" alt=""
                                        :preview-src-list="JSON.parse(waybillOrderDetailData.thirdPlaceImage)"></el-image>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">磅单照片：</el-col>
                            <el-col :span="12" v-if="waybillOrderDetailData.weighImage">
                                <div v-for="(img, index) in JSON.parse(waybillOrderDetailData.weighImage)" :key="index">
                                    <el-image class="point-img" :src="img" alt=""
                                        :preview-src-list="JSON.parse(waybillOrderDetailData.weighImage)"></el-image>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="5">
                            <el-col :span="12">单据照片：</el-col>
                            <el-col :span="12" v-if="waybillOrderDetailData.billsImage">
                                <div v-for="(img, index) in JSON.parse(waybillOrderDetailData.billsImage)" :key="index">
                                    <el-image class="point-img" :src="img" alt=""
                                        :preview-src-list="JSON.parse(waybillOrderDetailData.billsImage)"></el-image>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="5">
                            <el-col :span="12">返回提箱地时间：</el-col>
                            <el-col :span="12">{{
                                dateFormat2Str(Math.trunc(waybillOrderDetailData.containerFinishTime / 100))
                                }}</el-col>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </div>
    </el-dialog>
</template>
<script>
import dayjs from 'dayjs'

export default {
    name: 'OrderTakDetail',
    data() {
        return {
            dayjs,
            infoVisible: false,
            waybillDetailData: {},
            waybillOrderDetailData: {},
            service_fee_scale: 0,
        }
    },
    methods: {
        showInfo(data) {
            console.log(data)

            this.waybillOrderDetailData = data;

            this.infoVisible = true;

            this.$req({
                url: `/waybillPc/waybill/detail/` + data.waybillId,
                method: 'get',
            }).then(res => {
                console.log(res, "ress");
                this.waybillDetailData = res.data;
            })

            this.$req({
                url: '/config/cache/value/service_fee_scale',
                api: '/mng',
                method: 'get',
            }).then(res => {
                this.service_fee_scale = res.data;
            })

            // this.$req({
            //     url: `/waybill/`+soucseId,
            //     method:'get',
            // }).then(res=>{
            //     console.log(res,"ress");
            // })
        }
    }
}
</script>