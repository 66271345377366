<template>
  <!-- 页面统一头部 -->
  <div class="header-container">
    <img class="logo" src="@/assets/logo1.png" alt="">

    <div class="center" v-if="show">
      <div class="nav" :class="{ 'nav-active': (route_name == 'send_bill' || route_name == 'index') }"
        @click="navClick('/send_bill')" v-if="isMenuRoure('sendOrder')">
        <div>我要发单</div>
        <div class="line"></div>
      </div>
      <div class="nav" :class="{ 'nav-active': route_name == 'order_list' }" @click="navClick('/order_list')"
        v-if="isMenuRoure('sendOrder')">
        <div>订单管理</div>
        <div class="line"></div>
      </div>
      <div class="nav" :class="{ 'nav-active': route_name == 'order_tak_list' }" @click="navClick('/order_tak_list')"
        v-if="isMenuRoure('takOrder')">
        <div>接单大厅</div>
        <div class="line"></div>
      </div>
      <div class="nav" :class="{ 'nav-active': route_name == 'order_tak_manage_list' }"
        @click="navClick('/order_tak_manage_list')" v-if="isMenuRoure('takOrder')">
        <div>接单管理</div>
        <div class="line"></div>
      </div>

      <div class="nav" :class="{ 'nav-active': route_name == 'my_evaluate' }" @click="navClick('/my_evaluate')">
        <div>我的评价</div>
        <div class="line"></div>
      </div>
    </div>

    <div class="user" @click="pull_show = !pull_show">
      <img class="avatar" v-if="merchant && merchant.headImage" :src="merchant.headImage" alt="">
      <img class="avatar" v-else src="@/assets/avatar.png" alt="">
      <div class="name">{{ merchant && merchant.nickname || '暂未设置' }}</div>
      <div class="pull"></div>

      <!-- 下拉列表 -->
      <div class="pull-navs" v-if="pull_show" @click.stop>
        <div v-if="merchant" class="pull-nav" @click="navClick('/userinfo')">
          <div>个人信息</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div v-if="merchant" class="pull-nav" @click="navClick('/authentication')">
          <div>我的认证</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div v-if="merchant" class="pull-nav" @click="navClick('/carTeams_vehicle_list')">
          <div>车辆管理</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div v-if="merchant" class="pull-nav" @click="navClick('/carTeams_list')">
          <div>协议车队</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div v-if="merchant" class="pull-nav" @click="navClick('/address_list')">
          <div>地址管理</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div v-if="merchant" class="pull-nav" @click="navClick('/change_phone')">
          <div>修改手机</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div class="pull-nav" @click="navClick('/change_password')">
          <div>修改密码</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div v-if="merchant" class="pull-nav" @click="navClick('/complaint')">
          <div>投诉</div>
          <img src="@/assets/right.png" alt="">
        </div>
        <div class="pull-nav" @click="logoutClick">
          <div>退出登录</div>
          <img src="@/assets/right.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      userinfo: {},//用户详情
      pull_show: false,
      route_name: '',
      merchant: {},
    }
  },
  props: {
    show: { type: Boolean, default: true }
  },
  computed: {
    isMenuRoure(item) {
      return function (item) {

        let category = this.userinfo.category;
        if (!category) {
          return false;
        }

        //sendOrder,takOrder
        if (category === 'mng_user_category_hd_merchant' && (item === 'sendOrder')) {
          //鸿德集装箱 - 商户（派单方）
          return true;
        } else if (category === 'mng_user_category_hd_motorcade') {
          //鸿德集装箱-车队
          let power = this.userinfo.power;
          if (power.indexOf(item) >= 0) {
            return true
          }
        }
        return false;
      }
    }
  },
  mounted() {
    this.userinfo = localStorage.getItem('userinfo') ? JSON.parse(localStorage.getItem('userinfo')) : {}
    this.merchant = localStorage.getItem('token') ? (localStorage.getItem('merchant') ? JSON.parse(localStorage.getItem('merchant')) : {}) : {}
    this.route_name = this.$route.name


    console.log(this.userinfo, "userinfo")

    if (localStorage.getItem('refreshToken')) {
      //定时查看当前token是否过期
      let now_time = new Date().getTime()
      let token_time = localStorage.getItem('token_refreshtime') * 1000 || 0;
      this.timer = setInterval(() => {
        now_time = new Date().getTime()
        if ((token_time - now_time) < 30000) {
          console.log('token濒死')
          //  token濒死
          this.$tokenRefresh()
        }
      }, 360000)
    }

    this.getMerchant();

  },
  
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    navClick(path) {
      if( path==='/order_list' || path==='/order_tak_list' || path==='/order_tak_manage_list'){
        console.log(this.merchant.authState,this.merchant.authState != 'Success',"!this.merchant || !this.merchant.authState === 'Success'")
        if(this.merchant.authState != 'Success'){
          this.$message({ type: 'error', message: '身份审核中，或者已拒绝', duration: 1000 })
            return false;
        }
      }
      this.$router.push(path)
    },
    // 点击退出登录
    logoutClick() {
      localStorage.removeItem('token')
      localStorage.removeItem('merchant')
      localStorage.removeItem('userinfo')
      localStorage.removeItem('refreshToken')

      this.$router.push('/login')
    },
    // 根据用户id获取派单方信息
    async getMerchant() {

      console.log("获取身份")
      let res = await this.$req({
        url: `/merchant/detailByUser/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method: 'GET'
      })
      if (res.code == 200) {
        console.log(res.data,"data")
        this.merchant = res.data || ''
        localStorage.setItem('merchant', JSON.stringify(res.data))
        console.log('this.merchant_inf22222o', this.merchant)
        this.submit_show = false
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.header-container
  height: 70px
  width: 100vw
  background: #015DE7
  position: fixed
  top:0
  left: 0
  z-index:2
  display: flex
  align-items: center
  justify-content: space-between
  color: #fff
  padding: 0 40px 0 12px

  .logo
    width 150px
    height 50px

  .center
    position: absolute
    top:50%
    left: 50%
    transform: translate(-50%,-50%)
    display: flex
    justify-content: space-between
    height: 100%
    width: 756px
    .nav
      height: 100%
      line-height: 70px
      font-size: 18px
      position relative
      cursor pointer
      div
        opacity 0.68
      .line
        width: 36px
        height: 3px
        position: absolute
        left: 50%
        bottom: 6px
        transform: translateX(-50%)
        background: #fff
        opacity 0
    .nav-active
      div
        opacity 1
      .line
        opacity 1

  .user
    display: flex
    align-items: center
    position relative
    .avatar
      width 36px
      height 36px
      border-radius: 50%
      margin-right: 12px
    .name
      font-size: 14px
      opacity: 0.8
      margin-right: 8px
      cursor pointer
    .pull
      border: solid 5px #fff
      border-left: solid 4px transparent
      border-right: solid 4px transparent
      border-bottom: solid 5px transparent
      margin-top: 5px
    .pull-navs
      box-shadow: 0px 0px 24px 0px rgba(12, 12, 12, 0.12);
      width 200px
      position: absolute
      top 55px
      right: -16px
      background: #fff
      padding: 12px 0
      .pull-nav
        height: 40px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 12px
        color: #1F2329
        font-size: 14px
        align-items: center
        cursor pointer
        img
          width 16px
          height 16px
</style>
