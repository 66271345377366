<template>
<!-- 派单方认证-审核中 -->
  <div class="component">
    <img src="@/assets/daitongguo.png" alt="">
    <div>派单方认证消息审核中，请耐心等待...</div>
  </div>
</template>

<script>
export default {
  name: 'NotCertified',
  data(){
    return{

    }
  },
  props:{

  },
  methods:{

  }
}
</script>

<style scoped lang="stylus">
.component
  padding-top: 98px
  display: flex
  flex-direction: column
  align-items: center
  img
    width 374px
    height 226px
    margin-bottom: 40px
  div
    font-size: 16px
    line-height: 24px
    color: #325AB4

</style>
