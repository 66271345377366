<template>
    <el-dialog title="详情" :visible.sync="infoVisible" width="60%">
        <div class="addressDiv">
            <el-row :gutter="20">
                <el-col :span="detailData.hasDestination ? 8 : 12"
                    style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
                    <el-row style="color: dodgerblue; font-weight: bold;">提箱地</el-row>
                    <el-row style="color: black; font-weight: bold; font-size: 18px;">{{ detailData.firstPlaceDetail ||
                        '' }}</el-row>
                    <el-row style="color: black; font-size: 14px;">{{ `${detailData.firstPlaceProvince}
                        ${detailData.firstPlaceCity}
                        ${detailData.firstPlaceDistrict}` }}</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">提箱有效时间段:</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">{{
                        dateFormat2Str(Math.trunc(detailData.firstPlaceBeginTime
                            /
                            100)) }} - {{ dateFormat2Str(Math.trunc(detailData.firstPlaceEndTime / 100)) }}</el-row>
                </el-col>

                <el-col :span="detailData.hasDestination ? 8 : 12"
                    style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
                    <el-row style="color: darkorange; font-weight: bold;">送货/装货地</el-row>
                    <el-row style="color: black; font-weight: bold; font-size: 18px;">{{ detailData.secondPlaceDetail ||
                        '' }}</el-row>
                    <el-row style="color: black; font-size: 14px;">{{ `${detailData.secondPlaceProvince}
                        ${detailData.secondPlaceCity} ${detailData.secondPlaceDistrict}` }}</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">送货/装货有效时间段:</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">{{
                        dateFormat2Str(Math.trunc(detailData.secondPlaceBeginTime /
                            100)) }} - {{ dateFormat2Str(Math.trunc(detailData.secondPlaceEndTime / 100)) }}</el-row>
                </el-col>

                <el-col v-if="detailData.hasDestination" :span="8"
                    style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
                    <el-row style="color: forestgreen; font-weight: bold;">收箱地</el-row>
                    <el-row style="color: black; font-weight: bold; font-size: 18px;">{{ detailData.thirdPlaceDetail ||
                        '' }}</el-row>
                    <el-row style="color: black; font-size: 14px;">{{ `${detailData.thirdPlaceProvince}
                        ${detailData.thirdPlaceCity}
                        ${detailData.thirdPlaceDistrict}` }}</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">到收箱地有效时间段:</el-row>
                    <el-row style="color: dimgrey; font-size: 12px;">{{
                        dateFormat2Str(Math.trunc(detailData.thirdPlaceBeginTime
                            /
                            100)) }} - {{ dateFormat2Str(Math.trunc(detailData.thirdPlaceEndTime / 100)) }}</el-row>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="2">
            <el-col :span="12" style="margin-top:20px;">
                <el-card class="box-card basicDiv">
                    <div slot="header" class="clearfix">
                        <span style="font-weight: bold; font-size: 16px;">基本信息</span>
                    </div>

                    <el-row :gutter="5">
                        <el-col :span="12">派单方：</el-col>
                        <el-col :span="12">{{ detailData.businessLicenseName || '' }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">联系方式：</el-col>
                        <el-col :span="12">{{ detailData.contactPhone || '' }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">船公司：</el-col>
                        <el-col :span="12">{{ detailData.shipCompanyCode || '' }} {{ detailData.shipCompanyName || ''
                            }}</el-col>
                    </el-row>

                    <!-- <el-row :gutter="5">
                        <el-col :span="12">提示：</el-col>
                        <el-col :span="12" class="text-lose">{{ detailData.shipRemark || '' }}</el-col>
                    </el-row> -->

                    <el-row :gutter="5">
                        <el-col :span="12">提（运）单号：</el-col>
                        <el-col :span="12">{{ detailData.extractIdentity || '' }}</el-col>
                    </el-row>
                    <el-row :gutter="5">
                        <el-col :span="12">类型：</el-col>
                        <el-col :span="12">{{ detailData.importAndExport == 'Include' ? '进出口运输' :
                            detailData.importAndExport ==
                                'None'
                                ? '无进出口运输' : '' }}，{{ detailData.goodsLoadingAndUnloading == 'Include' ? '有卸/装货要求' :
                                detailData.goodsLoadingAndUnloading == 'None' ? '无卸/装货要求' : '' }}</el-col>
                    </el-row>
                    <el-row :gutter="5" v-if="detailData.importAndExport == 'Include'">
                        <el-col :span="12">进出口类别：</el-col>
                        <el-col :span="12">{{ detailData.importAndExportCategory }}</el-col>
                    </el-row>
                </el-card>

                <el-card class="box-card deliveryDiv" style="margin-top: 5px;">
                    <div slot="header" class="clearfix">
                        <span style="font-weight: bold; font-size: 16px;">配送要求</span>
                    </div>

                    <el-row :gutter="5">
                        <el-col :span="12">车队要求：</el-col>
                        <el-col :span="12" v-if="detailData.waybillCarTeams">
                            <div v-for="item in detailData.waybillCarTeams" :key='item.id'>{{ item.carTeamName }}，
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="5">
                        <el-col :span="12">车辆要求：</el-col>
                        <el-col :span="12" v-if="detailData.waybillCars">
                            <div v-for="item in detailData.waybillCars" :key='item.id'>{{ item.carNumber }}， </div>
                        </el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">车况要求：</el-col>
                        <el-col :span="12">{{ detailData.carConditionDemand || '' }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">配送要求：</el-col>
                        <el-col :span="12">{{ detailData.carDeliveryDemand || '' }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">备注：</el-col>
                        <el-col :span="12">{{ detailData.remark || '' }}</el-col>
                    </el-row>
                </el-card>
                <el-card class="box-card costDiv" style="margin-top: 5px;">
                    <div>附件</div>
                    <div>
                        <a style="margin-right: 10px;" :href="item.path" :download="item.name" target="_blank"
                            v-for="(item, index) in detailData.billsFiles" :key="index">{{ item.name }}</a>
                    </div>
                </el-card>
            </el-col>

            <el-col :span="12" style="margin-top:20px">
                <el-card class="box-card containerDiv">
                    <div slot="header" class="clearfix">
                        <span style="font-weight: bold; font-size: 16px;">集装箱信息</span>
                    </div>

                    <el-row :gutter="5">
                        <el-col :span="12">尺寸：</el-col>
                        <el-col :span="12">{{ detailData.containerSize }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">箱型：</el-col>
                        <el-col :span="12">{{ detailData.containerShape }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">空/重箱：</el-col>
                        <el-col :span="12">{{ detailData.containerEmptyOrFull == 'Full' ? '重箱' : '空箱' }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">单箱货重:</el-col>
                        <el-col :span="12">{{ detailData.goodsWeightMin || '0' }}吨</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">配送形式：</el-col>
                        <el-col :span="12">{{ detailData.deliveryMode == 'Double' ? '双背' : detailData.deliveryMode ==
                            'Single'
                            ? '单背' : 'More' }}</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">总箱量：</el-col>
                        <el-col :span="12">
                            {{ detailData.orderTakNumber || 0 }}
                        </el-col>
                    </el-row>
                    <el-row :gutter="5">
                        <el-col :span="12">箱门朝向：</el-col>
                        <el-col :span="12">
                            {{ detailData.orientationStr }}
                        </el-col>
                    </el-row>
                </el-card>

                <el-card class="box-card costDiv" style="margin-top: 5px;">
                    <div slot="header" class="clearfix">
                        <span style="font-weight: bold; font-size: 16px;">费用信息</span>
                    </div>

                    <el-row :gutter="5">
                        <el-col :span="12">每车运费：</el-col>
                        <el-col :span="12">{{ (detailData.deliveryOnceCost || 0) }} 元/辆</el-col>
                    </el-row>

                    <el-row :gutter="5">
                        <el-col :span="12">运费总额：</el-col>
                        <el-col :span="12">{{ (detailData.deliveryEstimateTotalCost || 0) }} 元</el-col>
                    </el-row>

                    <!-- <el-row :gutter="5">
                        <el-col :span="12">已抢单服务费：</el-col>
                        <el-col :span="12">{{
                            (detailData.orderValidFreightServiceFeeCost + detailData.orderValidUrgentServiceFeeCost ||
                                0) }} 元</el-col>
                    </el-row> -->
                    <el-row :gutter="5">
                        <el-col :span="12">发票：</el-col>
                        <el-col :span="12">{{ detailData.provideInvoice == 'Yes' ? '提供' : '不提供' }}</el-col>
                    </el-row>
                </el-card>

                <el-card class="box-card costDiv" style="margin-top: 5px;">
                    <div>单据图片</div>
                    <div>
                        <el-image v-for="(item, index) in detailData.billsImage" :key="index"
                            style="width: 100px; height: 100px" :src="item.path"></el-image>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-dialog>
</template>
<script>
export default {
    name: 'OrderDetail',
    data() {
        return {
            infoVisible: false,
            detailData: {}
        }
    },
    methods: {
        showInfo(data) {
            this.infoVisible = true

            this.detailData = data
            this.detailData.serviceFeeChargeCost = data.serviceFeeChargeCost || 0
            this.detailData.serviceFeeTotalCost = data.serviceFeeTotalCost || 0

            this.detailData.bills = JSON.parse(this.detailData.bills);
            this.detailData.billsImage = this.detailData.bills.imgs;
            this.detailData.billsFiles = this.detailData.bills.files;
        }
    }
}
</script>