<template>
<!-- 个人信息 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="title">个人信息</div>

    <!-- 页面内容 -->
    <div class="content">
      <div>
          <div class="item">
            <div class="label">头像</div>
            <div class="img">
              <img :src="avatar" v-if="avatar" alt="">
              <img src="@/assets/avatar.png" v-else alt="">
              
              <div v-if="edit">
                更换头像
              </div>
            </div>
          </div>
          <div class="item">
            <div class="label">昵称</div>
            <div class="text" v-if="!edit">{{nickname||'暂未设置'}}</div>
            <input v-else type="text" v-model="nickname">
          </div>
          <div class="btn" v-if="!edit" @click="edit=true">修改</div>
          <div class="btn" v-else @click="saveClick">保存</div>
      </div>
    </div>
   
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'userinfo',
  components:{Header},
  data(){
    return{
      edit:false,
      info:{},
      nickname:'',
      avatar:''
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
        vm.getInfo()
    })
  },
  methods:{
    // 点击保存
    async saveClick(){
      let res_nickname = await this.$req({
        url:`/merchant/${this.merchant.id}/basic`,
        method:'post',
        data:{
          nickname:this.nickname,
          headImage:this.avatar
        }
      })

      let res_image = await this.$req({
        url:'/user/image',
        api:'/mng',
        method:'POST',
        data:{
          image:this.avatar
        }
      })
      if(res_nickname.code==200&&res_image.code==200){
        this.$message({
          type:'success',
          message:'提交成功！'
          , duration:1000
        })
        this.edit = false
        this.getInfo()
      }else{
        if(res_nickname.code!=200){
          this.$message({
            message:res_nickname.msg
            , duration:1000
          })
        }
        if(res_image.code!=200){
          this.$message({
            message:res_image.msg
            , duration:1000
          })
        }
      }
      
    },
    async upload ({file}) {
        let loading = this.$loading({text:'上传中'});
        let res = await this.$req({
            url:`/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
            method:'GET',
            api:'/mng'
        })
        if(res.code==200){
            this.avatar = await this.$uploadImg(res.data,file)
            console.log('this.avatar',this.avatar)
        }
        loading.close()
    },
    //上传中
    onProgress(event, file, fileList){
      console.log('上传中',event, file, fileList)
    },
    async getInfo(){
      let res = await this.$req({
        url:'/merchant/detailBySelf'
      })
      if(res.code==200){
        this.avatar = res.data.headImage
        this.nickname = res.data.nickname
        this.merchant = res.data
        localStorage.setItem('merchant',JSON.stringify(res.data))
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.container
  &>.content
    padding: 30px 0 176px
.item
  width 320px
  margin-bottom: 20px
  .label
    font-size: 14px;
    color: #7A8089;
    line-height: 22px;
    margin-bottom: 8px
  .img
    width: 80px;
    height: 80px;
    border-radius: 4px;
    position relative
    overflow: hidden
    position relative
    img
      width 100%
      height 100%
    &>div
      position: absolute
      width: 80px;
      height: 30px;
      background: rgba(0, 0, 0, .4)
      line-height: 30px
      text-align: center
      color: #fff
      font-size: 12px
      z-index 1
      bottom: 0
      left: 0
      .upload-transparent
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        /deep/ .el-upload--picture-card
          width: 100%;
          height: 100%;
  .text
    width: 320px;
    height: 36px;
    background: #F4F5F9;
    border-radius: 4px;
    line-height: 36px
    padding: 0 12px
  input
    width: 320px;
    height: 36px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D0D3D6;
    padding: 0 12px
    outline: none
.btn
  width: 80px;
  height: 32px;
  background: #015DE7;
  border-radius: 4px;
  color: #fff
  line-height: 32px
  text-align: center
  font-size: 14px
  cursor pointer
</style>
