import Vue from 'vue'
import VueRouter from 'vue-router'
import {tokenRefresh} from '../util/request'


import login from '../views/login/login.vue'
import forget from '../views/login/forget.vue'
import register from '../views/login/register.vue'
import registerOrderTak from '../views/login/registerOrderTak.vue'

import order_list from '../views/order_management/order_list.vue'
import order_detail from '../views/order_management/order_detail.vue'

import waybill_list from '../views/order_management/waybill_list.vue'
import driver_evaluation from '../views/order_management/driver_evaluation.vue'
import waybill_detail from '../views/order_management/waybill_detail.vue'

import send_bill from '../views/send_bill/send_bill.vue'

import authentication from '../views/user/authentication.vue'
import change_password from '../views/user/change_password.vue'
import change_phone from '../views/user/change_phone.vue'
import complaint from '../views/user/complaint.vue'
import userinfo from '../views/user/userinfo.vue'

import my_evaluate from '../views/my_evaluate.vue'
import index from '../views/index.vue'
import address_list from '../views/address_list.vue'
import carTeams_list from '../views/carTeams_list.vue'

//车辆管理
import carTeams_vehicle_list from '../views/carTeams_vehicle_list.vue'
//
import order_tak_list from '../views/order_tak/order_list.vue'
import order_tak_manage_list from "../views/order_tak_manage/order_tak_list.vue"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'send_bill',
    component: send_bill,
    meta:{name:"我要发单"}
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta:{name:"登录"}
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta:{name:"注册账号"}
  },
  {
    path: '/registerOrderTak',
    name: 'registerOrderTak',
    component: registerOrderTak,
    meta:{name:"注册账号(接单方)"}
  },
  {
    path: '/forget',
    name: 'forget',
    component: forget,
    meta:{name:"忘记密码"}
  },
  {
    path: '/order_list',
    name: 'order_list',
    component: order_list,
    meta:{name:"订单管理"}
  },
  {
    path: '/order_detail',
    name: 'order_detail',
    component: order_detail,
    meta:{name:"订单详情"}
  },
  
  {
    path: '/waybill_list',
    name: 'waybill_list',
    component: waybill_list,
    meta:{name:"运单列表"}
  },
  {
    path: '/waybill_detail',
    name: 'waybill_detail',
    component: waybill_detail,
    meta:{name:"运单详情"}
  },
  {
    path: '/driver_evaluation',
    name: 'driver_evaluation',
    component: driver_evaluation,
    meta:{name:"查看司机评价"}
  },
  {
    path: '/send_bill',
    name: 'send_bill',
    component: send_bill,
    meta:{name:"我要发单"}
  },
  {
    path: '/authentication',
    name: 'forauthenticationget',
    component: authentication,
    meta:{name:"我的认证"}
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: change_password,
    meta:{name:"修改密码"}
  },{
    path: '/change_phone',
    name: 'change_phone',
    component: change_phone,
    meta:{name:"修改手机"}
  },
  {
    path: '/complaint',
    name: 'complaint',
    component: complaint,
    meta:{name:"投诉"}
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: userinfo,
    meta:{name:"个人信息"}
  },
  {
    path: '/my_evaluate',
    name: 'my_evaluate',
    component: my_evaluate,
    meta:{name:"我的评价"}
  },
  {
    path: '/address_list',
    name: 'address_list',
    component: address_list,
    meta:{name:"地址管理"}
  },
  {
    path: '/carTeams_list',
    name: 'carTeams_list',
    component: carTeams_list,
    meta:{name:"协议车队"}
  },
  {
    path: '/carTeams_vehicle_list',
    name: 'carTeams_vehicle_list',
    component: carTeams_vehicle_list,
    meta:{name:"车辆管理"}
  },
  {
    path: '/order_tak_list',
    name: 'order_tak_list',
    component: order_tak_list,
    meta:{name:"接单大厅"}
  },
  {
    path: '/order_tak_manage_list',
    name: 'order_tak_manage_list',
    component: order_tak_manage_list,
    meta:{name:"接单管理"}
  },
  

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  // console.log('路由发生变化')
  if (to.meta.title) {
    document.title = to.meta.name
  }

  next()
})

export default router
