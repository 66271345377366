<template>
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="content">
        <div class="title">协议车队</div>
        <div class="search">
            <!-- <el-input style="width:250px;margin-right:20px" size="small" v-model="params.searchKey" placeholder="请输入关键字"></el-input>
            <el-button size="small" type="primary" @click="searchClick">查询</el-button> -->
            <el-button size="small" type="primary" @click="carTeams_show = true,getcarTeams()">新增</el-button>
        </div>
        <div class="table">
            <el-table :data="list" style="width: 100%">
                <el-table-column prop="name" label="车队名称" min-width="180" />
                <el-table-column prop="leader" label="负责人" width="180" />
                <el-table-column prop="leaderPhone" label="电话" width="120" />
                <el-table-column prop="carLicenseAmount" label="车辆数量" width="120" />
                <el-table-column prop="remark" label="备注" min-width="180" />
                <el-table-column prop="" label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="delClick(scope.row.merchantCarTeamId)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-if="count"
                background
                :page-size="params.limit"
                layout="prev, pager, next"
                @current-change="pageChange"
                :total="count">
            </el-pagination>
        </div>
    </div>

    <!-- 添加指定车队 -->
    <div class="mask" v-if="carTeams_show">
      <div class="pop">
        <div class="title">
          <div>添加指定车队</div>
          <img class="close" src="@/assets/close.png" alt="" @click="carTeams_show=false">
        </div>
        <div class="search">
          <input v-model="carTeams_params.name" type="text" placeholder="搜索车队名称">
          <div class="btn" @click="getcarTeams">搜索</div>
        </div>
        <div class="list-container">
          <div class="list">
            <div class="item" v-for="(item,index) in carTeams_list" :key="index">
              <div>{{item.name}}</div>
              <div class="blue" v-if="!item.checked" @click="addClick(item.id)">添加</div>
              <div class="grey" v-else>已添加</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue'
export default {
  name: 'carTeams_list',
  components:{Header},
  data(){
    return{
      list:[],
      params:{
        page:1,
        limit:10,
        searchKey:''
      },
      count:0,
      show:false,
      carTeamId:'',
      carTeams_list:[],
      carTeams_params:{
        name:'',
      },
      carTeams_count:0,
      carTeams_show:false
    }
  },
  mounted(){
    this.getList()
  },
  watch:{
    show(to){
        if(!to){
            this.carTeamId = ''
        }
    }
  },
  methods:{
    async delClick(id){
        let res = await this.$req({
            url:`/merchantCarTeam/${id}`,
            method:'DELETE'
        })
        if(res.code==200){
            this.getList()
            this.$message({ type:'success', message:'删除成功！', duration:1000 })
        }
    },
    async addClick(id){
        let res = await this.$req({
            url:'/merchantCarTeam',
            method:'POST',
            data:{
                "carTeamId": id,
                "merchantId": JSON.parse(localStorage.getItem('merchant')).id
            }
        })
        if(res.code==200){
            this.getList()
            this.carTeams_show = false
            this.$message({ type:'success', message:'新增成功！', duration:1000 })
        }
    },

    // 列表
    async getList(){
      let res = await this.$req({
        url:`/merchantCarTeam/${JSON.parse(localStorage.getItem('merchant')).id}/queryByMerchant`,
        method:'GET',
        data:this.params
      })
      if(res.code==200){
        this.list = res.data
        this.count = res.count
      }
    },
    pageChange(e){
        this.params.page = e
        this.getList()
    },
    searchClick(){
        this.params.page = 1
        this.getList()
    },
    async getcarTeams(){
      let res = await this.$req({
        url:`/carTeam/queryAll`,
        method:'GET',
        data:this.carTeams_params
      })
      if(res.code==200){
        this.carTeams_list = res.data
        this.carTeams_count = res.count
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.container
    background: #fff
    padding 30px
    .content
        display block
        .title
            font-size 20px
            font-weight 600
            padding 20px 0
        .search
            display flex
            margin-bottom 20px
        .table
            display flex
            flex-direction: column
            align-items: center
            .el-pagination
                margin 20px 0 0 0
.mask
    width 100vw
    height 100vh
    background: rgba(0, 0, 0, .6);
    position: fixed
    top 0
    left: 0
    z-index 2
    display: flex
    align-items: center
    justify-content: center
    .pop
      background: #fff
      width 680px
      border-radius: 4px;
      .title
        display: flex
        justify-content: space-between
        height 77px
        align-items: center
        padding: 0 32px
        img
            width 16px
            height 16px
            transform: translate(16px,-16px)
    .search
        display: flex
        padding: 0 32px
        input
          width: 230px; 
          height: 36px;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(199, 209, 216, 1);
          padding: 0 12px
          margin-right: 10px
        .btn
          width: 70px;
          height: 36px;
          border-radius: 4px;
          background-color: rgba(1, 93, 231, 1);
          font-size: 14px
          line-height: 36px
          text-align: center
          color: #fff
          cursor pointer
      .list-container
        padding: 20px 32px
        display: flex
        flex-direction: column
        align-items: center
        height:500px
        overflow-y auto
        .list
            width 100%
          .item
            display: flex
            align-items: center
            justify-content: space-between
            width: 570px;
            height: 42px;
            background-color: rgba(246, 247, 249, 1);
            padding: 0 14px
            margin-bottom: 8px
            font-size: 14px
            color: #1A2537
            .blue
              color: #015DE7
              cursor pointer
            .grey
              color: #646A73
              cursor pointer
        .el-pagination
          margin-top: 24px

</style>