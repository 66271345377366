<template>
  <!-- 我的认证 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="content-top">
      <div class="title">我的认证</div>
      <div @click="changeIdentityHandle" class="button">切换身份</div>
    </div>



    <!-- 页面内容 -->
    <div class="content">
      <div>
        <div class="text">
          <div class="label">营业执照名称</div>
          <div>{{ merchant_info.businessLicenseName }}</div>
        </div>
        <div class="text">
          <div class="label">法人身份证号</div>
          <div>{{ merchant_info.legalIdentityCard }}</div>
        </div>
        <div class="text">
          <div class="label">公司法人</div>
          <div>{{ merchant_info.legal }}</div>
        </div>
        <div class="text">
          <div class="label">联系方式</div>
          <div>{{ merchant_info.contactPhone }}</div>
        </div>
        <div class="text">
          <div class="label">营业执照</div>
          <div class="imgs">
            <img :src="merchant_info.businessLicenseImage" alt="">
          </div>
        </div>
        <div class="text">
          <div class="label">法人身份证</div>
          <div class="imgs">
            <img :src="merchant_info.legalIdentityCardImageFront" alt="">
            <img :src="merchant_info.legalIdentityCardImageBack" alt="">
          </div>
        </div>
      </div>
    </div>


      <ChangeIdentity ref="changeIdentityRef"></ChangeIdentity>

  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import ChangeIdentity from '../../components/ChangeIdentity.vue';

export default {
  name: 'authentication',
  components: { Header,ChangeIdentity },
  data() {
    return {
      merchant_info: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getMerchant()
    })
  },
  methods: {
    // 根据用户id获取派单方信息
    async getMerchant() {
      let res = await this.$req({
        // url:`/merchant/detailByUser/${localStorage.getItem('userinfo')?JSON.parse(localStorage.getItem('userinfo')).id:''}`,
        url: '/merchant/detailBySelf',
        method: 'GET'
      })
      if (res.code == 200) {
        this.merchant_info = res.data || ''
        localStorage.setItem('merchant', JSON.stringify(res.data))
      }
    },
    changeIdentityHandle(){
      this.$refs.changeIdentityRef.showInfo();
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  .content-top
    width:100%
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    height: 60px
    line-height: 60px
    .title
      font-size: 28px;
    .button
      margin-top: 10px
      height: 40px
      line-height: 40px
      padding:2px 10px
      background: #1E90FF;
      border-radius: 4px;
      color: #fff
      cursor pointer

  &>.content
    padding: 10px 0 169px
    &>div
      width 373px
      .text
        width 100%
        display: flex
        font-size: 14px;
        color: #1F2329;
        line-height: 22px;
        margin-bottom: 14px
        .label
          color: #6F757E
          text-align: right
          width 98px
          margin-right: 20px
        .imgs
          display: flex
          img
            width: 80px;
            height: 80px;
            background: #9B9B9B;
            border-radius: 4px;
            margin-right: 10px
</style>
