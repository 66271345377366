<template>
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="content">
        <div class="title">地址管理</div>
        <div class="search">
            <el-input style="width:250px;margin-right:20px" size="small" v-model="params.searchKey" placeholder="请输入关键字"></el-input>
            <el-button size="small" type="primary" @click="searchClick">查询</el-button>
            <el-button size="small" type="primary" @click="addBtnClick()">新增</el-button>
        </div>
        <div class="table">
            <el-table :data="address_list" style="width: 100%">
                <el-table-column prop="province" label="省市" width="180">
                <template slot-scope="scope">
                    {{scope.row.province}}/{{scope.row.city}}
                </template>
                </el-table-column>
                <el-table-column prop="area" label="区" width="180" />
                <el-table-column prop="detailAddress" label="详细地址" min-width="120" />
                <el-table-column prop="" label="操作" width="160">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="editClick(scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="delClick(scope.row.id)">删除</el-button>
                </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-if="count"
                background
                :page-size="params.limit"
                layout="prev, pager, next"
                @current-change="pageChange"
                :total="count">
            </el-pagination>
        </div>
    </div>

    <div class="mask" v-if="show">
      <div class="pop" style="width:750px">
        <div class="title">
          <div>新增地址</div>
          <img class="close" src="@/assets/close.png" alt="" @click="show=false">
        </div>
        <div class="form">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="80px" class="demo-ruleForm" size="small">
                <el-form-item label="省市" prop="province_city">
                    <el-cascader
                     style="width:300px"
                    v-model="province_city"
                    :options="provinceAndCityData"
                    :props="{label:'label',value:'label'}"></el-cascader>
                </el-form-item>
                <el-form-item label="所在区" prop="area">
                    <el-select v-model="district" placeholder="请选择" style="width:300px">
                        <el-option
                        v-for="item in districtData"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="detailAddress">
                    <el-input style="width:300px" v-model="ruleForm.detailAddress" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="title" style="justify-content: flex-end">
            <el-button size="mini" @click="show=false">取消</el-button>
            <el-button size="mini" type="primary" @click="addClick('ruleForm')">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, TextToCode } from "element-china-area-data";
import Header from '../components/Header.vue'
export default {
  name: 'address_list',
  components:{Header},
  data(){
    return{
      address_list:[],
      params:{
        page:1,
        limit:10,
        searchKey:''
      },
      count:0,
      show:false,
      ruleForm:{
        province:'',
        city:'',
        area:'',
        detailAddress:'',
      },
      province_city:[],
      district:'',
      provinceAndCityData:[],
      districtData:[],
      rules:{
        province:{ required: true, message: '请选择省市', trigger: 'change' },
        area:{ required: true, message: '请选择所在区', trigger: 'change' },
        detailAddress:{ required: true, message: '请输入详细地址', trigger: 'blur' },
      },
      edit_data:{},
      form_type:'add'
    }
  },
  mounted(){
    this.provinceAndCityData = provinceAndCityData
    this.getAddress()
  },
  watch:{
    province_city(to){ //提箱选择省市
        this.districtData = []
        this.district = ''
        if(to.length){
            this.$set(this.ruleForm,'province',to[0])
            this.$set(this.ruleForm,'city',to[1])
            let districtData = TextToCode[to[0]][to[1]]
            for(let key in districtData){
                if(key!='code'&&key!='全部'){
                    this.districtData.push({"label":key})
                }
            }
        }
    },
    district(to){ //提箱选择区
        if(to){
            this.$set(this.ruleForm,'area',to)
        }
    },
    show(to){
        if(!to){
            this.ruleForm = {
                province:'',
                city:'',
                area:'',
                detailAddress:'',
            }
            this.province_city = []
            this.district = ''
            this.edit_data = {}
        }
    }
  },
  methods:{
    async delClick(id){
        let res = await this.$req({
            url:`/address/${id}`,
            api:'/mng',
            method:'DELETE'
        })
        if(res.code==200){
            this.getAddress()
            this.$message({ type:'success', message:'删除成功！', duration:1000 })
        }
    },
    editClick(data){
        this.edit_data = data
        this.province_city = [data.province,data.city]
        setTimeout(()=>{this.district= data.area},300)
        this.$set(this.ruleForm,'detailAddress',data.detailAddress)
        this.show = true
        this.form_type = 'edit'
    },
    addBtnClick() {
        this.form_type = 'add';
        this.show = true;
    },
    async addClick(formName){
        this.$refs[formName].validate(async (valid) => {
            if (valid) {
                let url
                console.log(this.edit_data)
                if(this.form_type == 'add'){
                    url = '/address'
                }else{
                    url = `/address/${this.edit_data.id}`
                }
                let res = await this.$req({
                    url,
                    api:'/mng',
                    method:'POST',
                    data:{
                        ...this.ruleForm,
                        userId:JSON.parse(localStorage.getItem("userinfo")).id,
                        contactName:'联系人姓名',
                        contactPhone:'1851011xxxx',
                        addressName:this.ruleForm.detailAddress,
                        defultAddress:false
                    }
                })
                if(res.code==200){
                    this.getAddress()
                    this.show = false
                    if(this.edit_data=={}){
                        this.$message({ type:'success', message:'新增成功！', duration:1000 })
                    }else{
                        this.$message({ type:'success', message:'编辑成功！', duration:1000 })
                    }
                }
            }
        })
        
    },

    // 地址列表
    async getAddress(type){
      this.address_type = type
      let res = await this.$req({
        url:`/address/query`,
        api:'/mng',
        method:'GET',
        data:{...this.params, userId:JSON.parse(localStorage.getItem("userinfo")).id}
      })
      if(res.code==200){
        this.address_list = res.data
        this.count = res.count
      }
    },
    pageChange(e){
        this.params.page = e
        this.getAddress()
    },
    searchClick(){
        this.params.page = 1
        this.getAddress()
    },
  }
}
</script>

<style scoped lang="stylus">
.container
    background: #fff
    padding 30px
    .content
        display block
        .title
            font-size 20px
            font-weight 600
            padding 20px 0
        .search
            display flex
            margin-bottom 20px
        .table
            display flex
            flex-direction: column
            align-items: center
            .el-pagination
                margin 20px 0 0 0
.mask
    width 100vw
    height 100vh
    background: rgba(0, 0, 0, .6);
    position: fixed
    top 0
    left: 0
    z-index 2
    display: flex
    align-items: center
    justify-content: center
    .pop
      background: #fff
      width 634px
      border-radius: 4px;
      .title
        display: flex
        justify-content: space-between
        height 77px
        align-items: center
        padding: 0 32px
        img
            width 16px
            height 16px
            transform: translate(16px,-16px)
    .form
        padding 0 30px

</style>