<template>
<!-- 修改手机 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="title">修改手机</div>

    <!-- 页面内容 -->
    <div class="content">
      <div class="form-content">
          <div class="form">
              <div class="input">
                  <input v-model="params.newPhone" type="text" placeholder="请输入新手机">
              </div>
              <div class="input">
                  <input v-model="params.captcha" type="text" placeholder="请输入验证码">
                  <div class="code pointer" @click="getCode" v-if="!time">获取验证码</div>
                  <div class="code pointer grey" @click="getCode" v-else>{{time}}s后重新获取</div>
              </div>
              <div class="btn" @click="saveClick">保存</div>
          </div>
      </div>
    </div>
   
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'change_phone',
  components:{Header},
  data(){
    return{
      params:{
        "oldPhone": '',
        "newPhone": '',
        "captcha": ''
      },
      time:0
    }
  },
  methods:{
    // 获取手机验证码
    async getCode(){
      if(!this.params.newPhone){
        this.$message({message: '请输入手机号', duration:1000});
        return
      }
      
      let res = await this.$req({
        url:`/common/smsChangeBindPhone/${this.params.newPhone}`,
        api:'/mng',
        method:'POST'
      })
      if(res.code==200){
        this.time = 60
        this.timer = setInterval(() => {
          if(this.time > 0){
            this.time --
          }else{
            clearInterval(this.timer)
          }
        }, 1000);
        this.$message({
          message: '发送成功',
          type: 'success'
          , duration:1000
        });
      }
    },
    // 点击保存
    async saveClick(){
      let reg = /^1[1-9]\d{9}$/
      if(!this.params.newPhone){
        this.$message({
          message:'请输入手机号码'
          , duration:1000
        })
        return
      }else if(this.params.newPhone.length != 11 || this.params.newPhone[0] != 1){
        this.$message({
          message:'手机号码格式错误'
          , duration:1000
        })
        return
      }else if(!this.params.captcha){
        this.$message({
          message:'请输入手机验证码'
          , duration:1000
        })
        return
      }
      this.params.oldPhone = JSON.parse(localStorage.getItem('userinfo')).phone
      let res = await this.$req({
        url:'/user/bindPhone/'+this.params.newPhone,
        method:'POST',
        api:'/mng',
        data:this.params
      })
      if(res.code==200){
        this.$message({
          type:'success',
          message:'修改成功！'
          , duration:1000
        })
        // this.$router.replace('/userinfo')
        localStorage.removeItem('token')
        this.$router.replace('/login')
      }else{
        this.$message({
          message:res.msg
          , duration:1000
        })
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.container
  &>.content
    padding: 30px 0 232px
.form
  .input
    border-bottom: 1px solid #E6E8F2
    height 48px
    margin-bottom: 20px
    position relative
    input
      font-size: 16px
      width 100%
      border: none
      outline: none
      height: 100%
    .code
      font-size: 16px;
      position: absolute
      right: 0
      top 50%
      transform: translateY(-50%)
      color: #015DE7
      cursor pointer
      z-index 9
    .grey
      color: rgba(16, 29, 55, .3);
    
    img
      width 16px
      height 16px
  .btn
    height: 44px;
    border-radius: 2px;
    background-color: rgba(1, 93, 231, 1);
    line-height: 44px
    text-align: center
    font-size: 16px
    color: #fff
    margin-top: 32px
</style>
