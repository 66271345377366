import Vue from 'vue'

/**
 * 时间格式调整
 * @param {String} data YYYYMMDDhhmmss
 * @return {String} YYYY.MM.DD hh:mm
 */
Vue.filter('timereplace', (data) => {
	if(!data){
		return ''
	}
	let time  = data + "";
	if(time.length == 8){
		return time.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
	}else if(time.length == 14){
		return time.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3 $4:$5');
	}
});
Vue.prototype.$timereplace = function(data){
	if(!data){
		return ''
	}
	let time  = data + "";
	if(time.length == 8){
		return time.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
	}else if(time.length == 14){
		return time.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
	}
}
Vue.prototype.$dateChangeNum = function(data){
	if(!data){
		return ''
	}
	let time  = data.slice(0,16).replace(/-/gi, '').replace(/:/gi, '').replace(/ /gi, '')
	if(time.length == 12) {
		time = time + '00';
	}
	if(time.length > 14) {
		time = time.substring(0,14);
	}
	return time
}

/**
 * @param {Object} timestamp 时间戳
 * 服务端时间戳转日期
 */
 Vue.filter('datestamp', (timestamp) => {
	if(!timestamp){
		return ''
	}
	let d = new Date(timestamp * 1000); //根据时间戳生成的时间对象
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	if (month < 10) {
		month = "0" + month
	}
	let date = d.getDate();
	if (date < 10) {
		date = "0" + date
	}
	let dateTime = year + "-" + month + "-" + date
	return dateTime;
});
/**
 * @param {Object} timestamp 时间戳
 * 服务端时间戳转日期
 */
 Vue.filter('timestamp',(timestamp) => {
	if(!timestamp){
		return ''
	}
	let d = new Date(timestamp * 1000); //根据时间戳生成的时间对象
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	if (month < 10) {
		month = "0" + month
	}
	let date = d.getDate();
	if (date < 10) {
		date = "0" + date
	}
	let hours = d.getHours();
	if (hours < 10) {
		hours = "0" + hours
	}
	let minutes = d.getMinutes();
	if (minutes < 10) {
		minutes = "0" + minutes
	}
	let seconds = d.getSeconds();
	if (seconds < 10) {
		seconds = "0" + seconds
	}
	let dateTime = year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds
	return dateTime;
})

Vue.prototype.nowtime = function(){
	let d = new Date(); //根据时间戳生成的时间对象
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	if (month < 10) {
		month = "0" + month
	}
	let date = d.getDate();
	if (date < 10) {
		date = "0" + date
	}
	let hours = d.getHours();
	if (hours < 10) {
		hours = "0" + hours
	}
	let minutes = d.getMinutes();
	if (minutes < 10) {
		minutes = "0" + minutes
	}
	let seconds = d.getSeconds();
	if (seconds < 10) {
		seconds = "0" + seconds
	}
	let dateTime = year +''+ month +''+ date +''+ hours +''+ minutes +''+ seconds
	return dateTime;
}
Vue.prototype.nowtimeStr = function(){
	let d = new Date(); //根据时间戳生成的时间对象
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	if (month < 10) {
		month = "0" + month
	}
	let date = d.getDate();
	if (date < 10) {
		date = "0" + date
	}
	let hours = d.getHours();
	if (hours < 10) {
		hours = "0" + hours
	}
	let minutes = d.getMinutes();
	if (minutes < 10) {
		minutes = "0" + minutes
	}
	let seconds = d.getSeconds();
	if (seconds < 10) {
		seconds = "0" + seconds
	}
	let dateTime = year +'-'+ month +'-'+ date +' '+ hours +':'+ minutes
	return dateTime;
}

