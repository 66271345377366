<template>
  <!-- 我要发单-已认证 -->
    <div class="component">
      <div class="form-content">
        <!-- <div class="tips" v-if="tips">{{tips}}</div> -->
        <div class="tips" >集装箱发单</div>
  
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="170px" class="demo-ruleForm" size="small">
          <div class="item">
            <div class="name">基本信息</div>
            <div class="item-content">
              <el-form-item label="允许接单的截止时间" prop="acceptEndTime">
                <el-date-picker
                v-model="ruleForm.acceptEndTime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyyMMddHHmmss"
                type="datetime"
                placeholder="选择日期">
              </el-date-picker>
              </el-form-item>
              <el-form-item label="下单方联系电话" prop="merchantContactPhone">
                <el-input type="text" :disabled="type=='again'" v-model="ruleForm.merchantContactPhone" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="提单号" prop="extractIdentity">
                <el-input :disabled="type=='again'" v-model="ruleForm.extractIdentity" @input="extractIdentityInput" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="船公司" prop="shipCompanyId">
                <el-select :disabled="type=='again'" v-model="ruleForm.shipCompanyId" placeholder="请选择">
                  <el-option
                    v-for="item in shipCompany_list"
                    :key="item.id"
                    :label="`${item.code} ${item.name}`"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="content-text" v-if="shipCompany_remark">
                <img src="@/assets/tips.png" alt="">
                <div>{{shipCompany_remark}}</div>
              </div>
              <el-button size="mini" type="primary" style="height:32px;margin-top:31px" v-if="carTeam_count" @click="lookCarTeam">查看协议车队</el-button>
              <!-- <div class="car-team" v-if="carTeam_count" @click="lookCarTeam">查看协议车队</div> -->
            </div>
          </div>
  
          <div class="item">
            <div class="name">货物信息</div>
            <div class="item-content">
              <el-form-item label="尺寸" prop="containerSize">
                <el-select :disabled="type=='again'" v-model="ruleForm.containerSize" placeholder="请选择">
                  <el-option
                    v-for="item in size_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="箱型要求" prop="containerShape">
                <el-select :disabled="type=='again'" v-model="ruleForm.containerShape" placeholder="请选择">
                  <el-option
                    v-for="item in shape_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="箱子数量" prop="containerTotalAmount">
                <el-input :disabled="type=='again'" type="number" v-model="ruleForm.containerTotalAmount" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="空/重要求" prop="containerEmptyOrFull">
                <template>
                  <el-radio :disabled="type=='again'" size="small" v-model="ruleForm.containerEmptyOrFull" label="Empty">空箱</el-radio>
                  <el-radio :disabled="type=='again'" size="small" v-model="ruleForm.containerEmptyOrFull" label="Full">重箱</el-radio>
                </template>
              </el-form-item>
              <el-form-item label="（单箱）货净重" prop="goodsWeightMin">
                <div class="section">
                  <el-input type="number" :disabled="type=='again'" v-model="ruleForm.goodsWeightMin" placeholder="请输入"></el-input>
                  &nbsp;&nbsp;吨
                </div>
              </el-form-item>
              <!-- <div class="el-form-item"></div> -->
              <el-form-item label="配送形式" prop="deliveryMode">
                <template>
                  <el-radio size="small" :disabled="type=='again'" v-model="ruleForm.deliveryMode" label="Single">单背</el-radio>
                  <el-radio size="small" :disabled="type=='again'" v-model="ruleForm.deliveryMode" label="Double">双背</el-radio>
                  <el-radio size="small" :disabled="type=='again'" v-model="ruleForm.deliveryMode" label="More">多背</el-radio>
                </template>
              </el-form-item>
              <el-form-item label="单车箱量" prop="deliveryOnceAmount" v-if="ruleForm.deliveryMode=='More'">
                <el-input type="number" :disabled="type=='again'" v-model="ruleForm.deliveryOnceAmount" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="单价" prop="deliveryOnceCost">
                <el-input type="number" v-model="ruleForm.deliveryOnceCost" placeholder="元/辆"></el-input>
              </el-form-item>
              <div class="el-form-item">
                <div class="label">预计总运费金额</div>
                <div class="default">{{Math.round(deliveryEstimateTotalCost*100)/100}}元</div>
              </div>
              <div class="el-form-item">
                <div class="label">剩余授信额度</div>
                <div class="default">{{quotaUnusedComputed}}元</div>
              </div>
              <el-form-item label="类型">
                <template>
                  <el-checkbox size="small" :disabled="type=='again'" v-model="ruleForm.importAndExport">进/出口运输</el-checkbox>
                  <el-checkbox size="small" :disabled="type=='again'" v-model="ruleForm.goodsLoadingAndUnloading">无卸/装货要求</el-checkbox>
                </template>
              </el-form-item>
              <el-form-item label="进/出口类别" prop="importAndExportCategory" v-if="ruleForm.importAndExport">
                <el-select v-model="ruleForm.importAndExportCategory" placeholder="请选择">
                  <el-option
                  :disabled="type=='again'"
                    v-for="item in importAndExportCategory"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发票" prop="provideInvoice">
                <template>
                  <el-radio size="small" :disabled="type=='again'" v-model="ruleForm.provideInvoice" label="Yes">提供</el-radio>
                  <el-radio size="small" :disabled="type=='again'" v-model="ruleForm.provideInvoice" label="No">不提供</el-radio>
                </template>
              </el-form-item>
              <el-form-item label="支付方式" prop="payMethod">
                <el-select :disabled="type=='again'" v-model="ruleForm.payMethod" placeholder="请选择">
                  <el-option
                    v-for="item in payment_method"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="箱门朝向" prop="orientation">
                <el-select :disabled="type=='again'" v-model="ruleForm.orientation" placeholder="请选择">
                  <el-option
                    v-for="item in orientation_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
  
          <div class="item">
            <div class="name">配送信息</div>
            <div class="item-content">
              <el-form-item label="作业站点" prop="workSite">
                <el-select :disabled="type=='again'" v-model="ruleForm.workSite" placeholder="请选择">
                  <el-option
                    v-for="item in work_site"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="作业区域" prop="workDistrict">
                <el-select :disabled="type=='again'" v-model="ruleForm.workDistrict" placeholder="请选择">
                  <el-option
                    v-for="item in work_district"
                    :key="item.id"
                    :label="item.name"
                    :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="el-form-item"></div>
              <el-form-item label="是否需要垫付费用" prop="driverAdvanceFee">
                <template>
                  <el-checkbox :disabled="type=='again'" size="small" v-model="ruleForm.driverAdvanceFee">需要</el-checkbox>
                </template>
              </el-form-item>
              <el-form-item label="是否需要拍照上传" prop="driverImageInfo">
                <template>
                  <el-checkbox :disabled="type=='again'" size="small" v-model="ruleForm.driverImageInfo">需要</el-checkbox>
                </template>
              </el-form-item>
              <el-form-item label="是否需要过磅" prop="driverWeighInfo">
                <template>
                  <el-checkbox :disabled="type=='again'" size="small" v-model="ruleForm.driverWeighInfo">需要</el-checkbox>
                </template>
              </el-form-item>
              <el-form-item label="是否需要加急" prop="urgentState">
                <template>
                  <el-checkbox size="small" v-model="ruleForm.urgentState">需要</el-checkbox>
                </template>
              </el-form-item>
              <el-form-item label="加急费" prop="urgentDeliveryOnceCost" v-if="ruleForm.urgentState">
                <el-input type="number" v-model="ruleForm.urgentDeliveryOnceCost" placeholder="元/辆"></el-input>
              </el-form-item>
              <div class="el-form-item" v-else></div>
              <div class="el-form-item" ></div>
  
              <el-form-item label="拥有目的地" prop="includeThirdPlace">
                <el-radio-group v-model="ruleForm.includeThirdPlace" size="small">
                  <el-radio-button :disabled="type=='again'" label="Yes">是</el-radio-button>
                  <el-radio-button :disabled="type=='again'" label="No">否</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <div class="el-form-item"></div>
              <div class="el-form-item"></div>
              <div class="destination">
                <!-- <div class="car-team" style="margin-right:10px" @click="collectAddress('cradle')">选择收藏的地址</div> -->
                <el-button v-if="type!='again'" size="mini" type="primary" style="height:32px;margin-top:31px;margin-right:10px" @click="collectAddress('cradle')">选择收藏地址</el-button>
                <el-form-item label="提箱地" prop="firstPlaceProvince">
                  <el-cascader
                  :disabled="type=='again'"
                  v-model="cradle_province_city"
                  :options="provinceAndCityData"
                  :props="{label:'label',value:'label'}"></el-cascader>
                </el-form-item>
                <el-form-item label="所在区" prop="firstPlaceDistrict">
                  <el-select :disabled="type=='again'" v-model="cradle_district" placeholder="请选择">
                    <el-option
                      v-for="item in cradle_districtData"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="提箱地·详细地址" prop="firstPlaceDetail" style="margin-right:10px;width:215px">
                  <el-input :disabled="type=='again'" v-model="ruleForm.firstPlaceDetail" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item style="margin-right:25px;width:100px">
                  <div style="margin-top:30px">
                    <el-checkbox size="small" v-model="cradle_collect" :disabled="!(ruleForm.firstPlaceProvince&&ruleForm.firstPlaceCity&&ruleForm.firstPlaceDistrict&&ruleForm.firstPlaceDetail) || type=='again'">收藏此地址</el-checkbox>
                  </div>
                </el-form-item>
              </div>
              <div class="destination">
                <el-form-item label="提箱开始时间与截止时间" prop="firstPlaceBeginTime" style="width:405px">
                  <div class="section">
                    <el-date-picker
                      v-model="ruleForm.firstPlaceBeginTime"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyyMMddHHmm"
                      type="datetime"
                      placeholder="选择日期">
                    </el-date-picker>
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                    <el-date-picker
                      v-model="ruleForm.firstPlaceEndTime"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyyMMddHHmm"
                      type="datetime"
                      placeholder="选择日期">
                    </el-date-picker>
                  </div>
                </el-form-item>
              </div>
              <div class="destination">
                <!-- <div class="car-team" style="margin-right:10px" @click="collectAddress('assemble')">选择收藏的地址</div> -->
                <el-button size="mini" v-if="type!='again'" type="primary" style="height:32px;margin-top:31px;margin-right:10px" @click="collectAddress('assemble')">选择收藏地址</el-button>
                <el-form-item label="送货/装货地" prop="secondPlaceProvince">
                  <el-cascader
                  :disabled="type=='again'"
                  v-model="assemble_province_city"
                  :options="provinceAndCityData"
                  :props="{label:'label',value:'label'}"></el-cascader>
                </el-form-item>
                <el-form-item label="所在区" prop="secondPlaceDistrict">
                  <el-select :disabled="type=='again'" v-model="assemble_district" placeholder="请选择">
                    <el-option
                      v-for="item in assemble_districtData"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="送货/装货地·详细地址" prop="secondPlaceDetail" style="margin-right:10px;width:215px">
                  <el-input :disabled="type=='again'" v-model="ruleForm.secondPlaceDetail" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item style="margin-right:25px;width:100px">
                  <div style="margin-top:30px">
                    <el-checkbox size="small" v-model="assemble_collect" :disabled="!(ruleForm.secondPlaceProvince&&ruleForm.secondPlaceCity&&ruleForm.secondPlaceDistrict&&ruleForm.secondPlaceDetail) || type=='again'">收藏此地址</el-checkbox>
                  </div>
                </el-form-item>
              </div>
              <div class="destination">
                <el-form-item label="送货/装货开始时间与截止时间" prop="secondPlaceBeginTime" style="width:405px">
                  <div class="section">
                    <el-date-picker
                      v-model="ruleForm.secondPlaceBeginTime"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyyMMddHHmm"
                      type="datetime"
                      placeholder="选择日期">
                    </el-date-picker>
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                    <el-date-picker
                      v-model="ruleForm.secondPlaceEndTime"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyyMMddHHmm"
                      type="datetime"
                      placeholder="选择日期">
                    </el-date-picker>
                  </div>
                </el-form-item>
              </div>
              <div class="destination" v-if="ruleForm.includeThirdPlace=='Yes'">
                <el-button size="mini" v-if="type!='again'" type="primary" style="height:32px;margin-top:31px;margin-right:10px" @click="collectAddress('purpose')">选择收藏地址</el-button>
                <el-form-item label="收箱地" prop="thirdPlaceProvince">
                  <el-cascader
                  :disabled="type=='again'"
                  v-model="purpose_province_city"
                  :options="provinceAndCityData"
                  :props="{label:'label',value:'label'}"></el-cascader>
                </el-form-item>
                <el-form-item label="所在区" prop="thirdPlaceDistrict">
                  <el-select :disabled="type=='again'" v-model="purpose_district" placeholder="请选择">
                    <el-option
                      v-for="item in purpose_districtData"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="收箱地·详细地址" prop="thirdPlaceDetail" style="margin-right:10px;width:215px">
                  <el-input :disabled="type=='again'" v-model="ruleForm.thirdPlaceDetail" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item style="margin-right:25px;width:100px">
                  <div style="margin-top:30px">
                    <el-checkbox size="small" v-model="purpose_collect" :disabled="!(ruleForm.thirdPlaceProvince&&ruleForm.thirdPlaceCity&&ruleForm.thirdPlaceDistrict&&ruleForm.thirdPlaceDetail || type == 'again')">收藏此地址</el-checkbox>
                  </div>
                </el-form-item>
              </div>
              <div class="destination" v-if="ruleForm.includeThirdPlace=='Yes'">
                <el-form-item label="收箱开始时间与截止时间" style="width:405px">
                  <div class="section">
                    <el-date-picker
                      v-model="ruleForm.thirdPlaceBeginTime"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyyMMddHHmm"
                      type="datetime"
                      placeholder="选择日期">
                    </el-date-picker>
                    &nbsp;&nbsp;-&nbsp;&nbsp;
                    <el-date-picker
                      v-model="ruleForm.thirdPlaceEndTime"
                      format="yyyy-MM-dd HH:mm"
                      value-format="yyyyMMddHHmm"
                      type="datetime"
                      placeholder="选择日期">
                    </el-date-picker>
                  </div>
                </el-form-item>
              </div>
              
              <div style="width:100%">
                <el-form-item label="指定车队" prop="specifyCarTeams">
                  <el-radio-group v-model="ruleForm.specifyCarTeams" size="small">
                    <el-radio-button label="Yes">是</el-radio-button>
                    <el-radio-button label="No">否</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <div class="cars" v-if="ruleForm.specifyCarTeams=='Yes'">
                  <div class="car" v-for="(item,index) in waybillCarTeams" :key="index">
                    <div>{{item.name}}</div>
                    <div class="del" @click="carTeamsDelClick(index)">移除</div>
                  </div>
                  <div class="car-add" @click="carTeamsAdd">
                    <img src="@/assets/add.png" alt="">
                    <div>添加车队</div>
                  </div>
                </div>
              </div>
              <div style="width:100%">
                <el-form-item label="指定车辆" prop="specifyCars">
                  <el-radio-group v-model="ruleForm.specifyCars" size="small">
                    <el-radio-button :disabled="type=='again'" label="Yes">是</el-radio-button>
                    <el-radio-button :disabled="type=='again'" label="No">否</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <div class="cars" v-if="ruleForm.specifyCars=='Yes'">
                  <div class="car" v-for="(item,index) in waybillCars" :key="index">
                    <div>{{item}}</div>
                    <div class="del" @click="carDelClick(index)">移除</div>
                  </div>
                  <div class="car-add" @click="carsAdd">
                    <img src="@/assets/add.png" alt="">
                    <div>添加车辆</div>
                  </div>
                </div>
              </div>
              <div class="el-form-item">
                <div class="label">车况要求</div>
                <textarea v-model="ruleForm.carConditionDemand" name="" id="" cols="30" rows="10"></textarea>
              </div>
              <div class="el-form-item">
                <div class="label">配送要求</div>
                <textarea v-model="ruleForm.carDeliveryDemand" name="" id="" cols="30" rows="10"></textarea>
              </div>
            </div>
          </div>
  
          <div class="item">
            <div class="name">其他信息</div>
            <div class="item-content">
              <div class="remark">
                <div class="label">备注</div>
                <textarea v-model="ruleForm.remark" name="" id="" cols="30" rows="10"></textarea>
              </div>
            </div>
            <div class="item-content" style="margin-top:20px">
              <div class="remark">
                <el-upload
                  :disabled="type=='again'"
                  class="upload-demo"
                  action="''"
                  :multiple="false"
                  :show-file-list="false"
                  :http-request="uploadFile">
                  <el-button size="mini" type="primary">上传单据附件</el-button>
                </el-upload>
                <div class="file-list">
                  <div class="file-item" v-for="(item,index) in bills.files" :key="index">
                    <a target="_blank" :href="item.path" class="name">{{item.name}}</a>
                    <div class="del" @click="delClick(index,'file')">×</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-content" style="margin-top:20px">
              <div class="remark">
                <el-upload
                :disabled="type=='again'"
                  class="upload-demo"
                  action="''"
                  :multiple="false"
                  :show-file-list="false"
                  :http-request="uploadImg">
                  <el-button size="mini" type="primary">上传单据图片</el-button>
                </el-upload>
                <div class="img-list">
                  <div class="img-item" v-for="(item,index) in bills.imgs" :key="index">
                    <img :src="item.path" />
                    <div class="del" @click="delClick(index,'img')">×</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="bottom">
            <div class="submit" @click="submitForm('ruleForm')">提交</div>
          </div>
  
        </el-form>
      </div>
  
      <!-- 车辆列表 -->
      <div class="mask" v-if="car_show">
        <div class="pop" style="width:750px">
          <div class="title">
            <div>添加指定车辆</div>
            <img class="close" src="@/assets/close.png" alt="" @click="car_show=false">
          </div>
          <div class="search">
            <input v-model="car_params.carNumber" type="text" placeholder="搜索关键字">
            <div class="btn" @click="carSearch">搜索</div>
            <div class="btn" style="background:#fff;color:#666;border:1px solid #999;margin-left:8px" @click="carReset">重置</div>
          </div>
          <div class="list-container">
            <el-table :data="car_list" style="width: 100%">
              <el-table-column prop="carNumber" label="车牌号" min-width="180" />
              <el-table-column prop="" label="操作" width="80">
                <template slot-scope="scope">
                  <el-button v-if="!scope.row.checked" size="mini" type="primary" @click="carSelect(scope.row)">选择</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              v-if="car_count"
              background
              :page-size="car_params.limit"
              layout="prev, pager, next"
              @current-change="carPageChange"
              :total="car_count">
            </el-pagination>
          </div>
        </div>
      </div>
  
      <!-- 添加指定车队 -->
      <div class="mask" v-if="carTeams_show">
        <div class="pop">
          <div class="title">
            <div>添加指定车队</div>
            <img class="close" src="@/assets/close.png" alt="" @click="carTeams_show=false">
          </div>
          <div class="search">
            <input v-model="carTeams_params.name" type="text" placeholder="搜索车队名称">
            <div class="btn" @click="searchClick">搜索</div>
            <div class="btn" style="background:#fff;color:#666;border:1px solid #999;margin-left:8px" @click="resetClick">重置</div>
          </div>
          <div class="list-container">
            <div class="list">
              <div class="item" v-for="(item,index) in carTeams_list" :key="index">
                <div>{{item.name}}</div>
                <div class="blue" v-if="!item.checked" @click="addCarTeamsClick(item.id,item.name,index)">添加</div>
                <div class="grey" v-else>已添加</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Address from '@/util/distpicker.js'
  import { provinceAndCityData, TextToCode } from "element-china-area-data";
  export default {
    name: 'NotCertified',
    data(){
      var costCheck = (rule, value, callback)=>{
        if(value<=0){
          callback(new Error('单价必须大于0'));
        }else{
          callback();
        }
      };
      var isDateTime = (rule, value, callback)=>{
        console.log('isDateTime')
        let reg = /[1-9]\d{3}\-(((0[13578]|1[02])\-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)\-(0[1-9]|[12][0-9]|30))|(02\-(0[1-9]|[1][0-9]|2[0-9]))) ([0-1][0-9]|2[0-3]):([0-5][0-9])/;
          if (!reg.test(value)) {
              callback(new Error('请输入正确格式时间'));
          }
          let arr = value.split('-');
          let year = parseInt(arr[0]);
          let month = parseInt(arr[1]);
          let day = parseInt(arr[2]);
          if (month != 2 || day < 29) {
              callback(); // 不必校验闰年，肯定是合法时间了
          }
          console.log(year)
          // 能被400整除，或者能被4整除但不能被100整除的都是闰年，其余的年份均为平年
          if (year % 400 == 0 || (year % 4 == 0 && year % 100 != 0)) {
              console.log("====")
          } else {
              // 平年的2月只有28号
              if (month == 2 && day > 28) {
                  callback(new Error('平年的2月只有28号'));
              }
          }
          callback();
      };
      return{
        ruleForm:{
          "thirdPlaceDetail": "",
          "carConditionDemand": "",
          "thirdPlaceBeginTime": '',
          "containerTotalAmount": '',
          "containerSize": "",
          "deliveryEstimateTotalCost": '',
          "includeThirdPlace": "No",
          "carDeliveryDemand": "",
          "thirdPlaceEndTime": '',
          "specifyCarTeams": 'Yes',
          "specifyCars":"Yes",
          "extractIdentity": '',
          "containerEmptyOrFull": 'Empty',
          "merchantId": '',
          "remark": "",
          "acceptEndTime": '',
          "waybillCarTeams": [],
          "waybillCars": [],
          "deliveryMode": 'Single',
          "deliveryOnceAmount":'1',
          "provideInvoice": 'Yes',
          "merchantContactPhone": "",
          "userId": '',
          "deliveryOnceCost": '',
          "containerShape": "",
          "shipCompanyId": null,
          "goodsWeightMin":"0",
          "importAndExport":"",
          "goodsLoadingAndUnloading":"",
          "importAndExportCategory":"",
          "payMethod":"45",
          "workSite":"",
          "workDistrict":"",
          "driverAdvanceFee":"",
          "driverImageInfo":"",
          "driverWeighInfo":"",
          "urgentState":"",
          "urgentDeliveryOnceCost":"",
          "orientation":"",
  
          "firstPlaceBeginTime": '',
          "firstPlaceEndTime": '',
          "firstPlaceProvince": "",
          "firstPlaceCity": "",
          "firstPlaceDistrict": "",
          "firstPlaceDetail": "",
  
          "secondPlaceBeginTime": '',
          "secondPlaceEndTime": '',
          "secondPlaceProvince": "",
          "secondPlaceCity": "",
          "secondPlaceDistrict": "",
          "secondPlaceDetail": "",
  
          "thirdPlaceBeginTime": '',
          "thirdPlaceEndTime": '',
          "thirdPlaceProvince": "",
          "thirdPlaceCity": "",
          "thirdPlaceDistrict": "",
          "thirdPlaceDetail": "",
  
          "bills":"",
        },
        rules:{
          acceptEndTime:{ required: true, message: '请选择允许接单的截止时间', trigger: 'change' },
          merchantContactPhone:{ required: true, message: '请输入下单方联系电话', trigger: 'blur' },
          extractIdentity:{ required: true, message: '请输入提单号', trigger: 'blur' },
          // shipCompanyId:{ required: true, message: '请选择船公司', trigger: 'change' },
          containerSize:{ required: true, message: '请选择集装箱尺寸', trigger: 'change' },
          // orientation:{ required: true, message: '请选择货物朝向', trigger: 'change' },
          containerShape:{ required: true, message: '请选择集装箱箱型', trigger: 'change' },
          containerTotalAmount:[
            { required: true, message: '请输入集装箱数量', trigger: 'blur' },
            { pattern: /^(?:[1-9]\d*)$/, message: '集装箱数量必须大于0', trigger: 'blur' }
          ],
          containerEmptyOrFull:{ required: true, message: '请选择空/重要求', trigger: 'change' },
          deliveryMode:{ required: true, message: '请选择配送形式', trigger: 'blur' },
          deliveryOnceCost:[
            { required: true, message: '请输入单价', trigger: 'blur' },
            { pattern: /^[0-9]+(.[0-9]{1,2})?$/, message: '单价必须大于0，且最多输入两位小数', trigger: 'blur' },
            { validator: costCheck, trigger: 'blur' },
          ],
          provideInvoice:{ required: true, message: '请选择是否提供发票', trigger: 'blur' },
          includeThirdPlace:{ required: true, message: '请选择是否拥有目的地', trigger: 'change' },
  
          firstPlaceProvince:{ required: true, message: '请选择提箱地省市', trigger: 'change' },
          firstPlaceDistrict:{ required: true, message: '请选择提箱地所在区', trigger: 'change' },
          firstPlaceDetail:{ required: true, message: '请输入提箱地·详细地址', trigger: 'change' },
          firstPlaceBeginTime:{ required: true, message: '请输入提箱开始时间', trigger: 'blur' },
          firstPlaceEndTime:{ required: true, message: '请输入提箱截止时间', trigger: 'blur' },
          
          secondPlaceProvince:{ required: true, message: '请选择送货/装货地省市', trigger: 'change' },
          secondPlaceDistrict:{ required: true, message: '请选择送货/装货地所在区', trigger: 'change' },
          secondPlaceDetail:{ required: true, message: '请输入送货/装货地·详细地址', trigger: 'change' },
          secondPlaceBeginTime:{ required: true, message: '请输入送货/装货开始时间', trigger: 'blur' },
          secondPlaceEndTime:{ required: true, message: '请输入送货/装货截止时间', trigger: 'blur' },
  
          specifyCarTeams:{ required: true, message: '请选择是否指定车队', trigger: 'blur' },
          specifyCars:{ required: true, message: '请选择是否指定车辆', trigger: 'blur' },
  
  
          goodsWeightMin:{ required: true, message: '请输入最小货净重', trigger: 'blur' },
        },
        waybillCarTeams:[],
        waybillCars:[],
        radio:'',
        shipCompany_list:[], //全部船公司
        shipCompany_remark:'', //选择船公司后显示的内容
        shipCompany:{},//选择的船公司信息
        carTeam_count:0,//船公司的协议车队数量
        size_list:[], //全部尺寸列表
        shape_list:[], //全部箱型列表
        orientation_list:[],//货物朝向
        Address:[], //地址列表
        cradle_time:['',''], //提箱/货有效时间段
        assemble_time:['',''], //卸/装箱/货有效时间段
        purpose_time:['',''], //送箱/货有效时间段
        tips:'',//提示文字
        extractIdentity:'',
        importAndExportCategory:[],
        payment_method:[],
        work_site:[],
        work_district:[],
        provinceAndCityData:[],
        
        cradle_districtData:[],
        cradle_province_city:[],
        cradle_district:'',
        cradle_collect:false,//收藏提箱地址
  
        assemble_districtData:[],
        assemble_province_city:[],
        assemble_district:'',
        assemble_collect:false,
  
        purpose_districtData:[],
        purpose_province_city:[],
        purpose_district:'',
        purpose_collect:false,
  
        bills:{
          imgs:[],
          files:[]
        },
        carTeams_params:{ //车队列表参数
          page:1,
          limit:999,
          name:''
        },
        carTeams_count:0,
        carTeams_list:[], //车队
        carTeams_show:false,//选择车队弹窗
        car_show:false,
        car_list:[],
        car_count:0,
        car_params:{
          limit:6,
          page:1,
          searchKey:null,
          carNumber:null
        }
      }
    },
    props:{
      carTeams_select:{type:Array,default:() => []},
      cars_select:{type:Array,default:() => []},
      merchantId:{type:String,default:() => ''},
      quotaUnused:{type:Number,default:() => 0},
      edit_id:{type:String,default:() => ''},
      type:{type:String,default:() => ''},
      
    },
    async mounted(){
      await this.getShipCompany()
      await this.getAllSize()
      await this.getImportAndExportCategory()
      await this.getPaymentMethod()
      await this.getWorkSite()
      await this.getAllShape()
      await this.getTips()
      await this.getAllOrientation();
      
      this.$set(this.ruleForm,'merchantId',this.merchantId)
  
      this.Address = Address
      this.provinceAndCityData = provinceAndCityData
  
      // 修改订单
      if(this.edit_id){
        console.log('修改订单')
        await this.getOrderDetail()
      }else{
        // console.log('用户信息',JSON.parse(localStorage.getItem('userinfo')).phone)
        this.ruleForm.merchantContactPhone = JSON.parse(localStorage.getItem('userinfo')).phone || ''
  
        let nowtime_str = this.nowtime();
        this.$set(this.ruleForm,'firstPlaceBeginTime',nowtime_str)
        this.$set(this.ruleForm,'firstPlaceEndTime',nowtime_str)
  
        this.$set(this.ruleForm,'secondPlaceBeginTime',nowtime_str)
        this.$set(this.ruleForm,'secondPlaceEndTime',nowtime_str)
  
        this.$set(this.ruleForm,'thirdPlaceBeginTime',nowtime_str)
        this.$set(this.ruleForm,'thirdPlaceEndTime',nowtime_str)
  
        this.$set(this.ruleForm,'workSite','work_location_qd')
  
        this.cradle_province_city = ["山东省","青岛市"]
        setTimeout(()=>{ this.cradle_district = '黄岛区' },300)
        this.assemble_province_city = ["山东省","青岛市"]
        setTimeout(()=>{ this.assemble_district = '黄岛区' },300)
        this.purpose_province_city = ["山东省","青岛市"]
        
        console.log('this.ruleForm.firstPlaceBeginTime',this.ruleForm.firstPlaceBeginTime);
      }
    },
    watch:{
      'ruleForm.shipCompanyId':{
        handler:function(to){
          console.log('船公司变化',to)
          if(!to) {
            return
          }
          // 获取协议车队
          this.getCarTeam(to)
          for(let item of this.shipCompany_list){
            if(to==item.id){
              this.shipCompany_remark = item.remark
              this.shipCompany = item
              return
            }
          }
          if(!to){
            this.shipCompany_remark = ''
            this.carTeam_count = 0
          }
        },
        deep:true
      },
      'ruleForm.firstPlaceBeginTime':{
        handler:function(to){
          console.log('firstPlaceBeginTime',to)
          
        },
        deep:true
      },
      'ruleForm.includeThirdPlace':{
        handler:function(to){
          if(to=='Yes'){
            this.$set(this.rules,'thirdPlaceProvince',{ required: true, message: '请选择收箱省市', trigger: 'blur' })
            this.$set(this.rules,'thirdPlaceDistrict',{ required: true, message: '请选择收箱所在区', trigger: 'blur' })
            this.$set(this.rules,'thirdPlaceDetail',{ required: true, message: '请输入目的地·详细地址', trigger: 'blur' })
            this.$set(this.rules,'thirdPlaceBeginTime',[
              { required: true, message: '请输入送箱/货开始时间', trigger: 'blur' },
            ])
            this.$set(this.rules,'thirdPlaceEndTime',[
              { required: true, message: '请输入送箱/货结束时间', trigger: 'blur' },
            ])
          }else{
            this.$set(this.rules,'thirdPlaceProvince',null)
            this.$set(this.rules,'thirdPlaceDistrict',null)
            this.$set(this.rules,'thirdPlaceDetail',null)
            this.$set(this.rules,'thirdPlaceBeginTime',null)
            this.$set(this.rules,'thirdPlaceEndTime',null)
          }
        },
        deep:true
      },
  
      'ruleForm.deliveryMode':{ //配送形式
        handler:function(to){
          if(to=='More'){
            if(this.ruleForm.deliveryOnceAmount == 0) {
              this.$set(this.ruleForm,'deliveryOnceAmount','')
            }
            this.$set(this.rules,'deliveryOnceAmount',{ required: true, message: '请输入单车箱量', trigger: 'blur' })
          }else if(to=='Double'){
            this.$set(this.ruleForm,'deliveryOnceAmount',2)
            this.$set(this.rules,'deliveryOnceAmount',null)
          }else if(to=='Single'){
            this.$set(this.ruleForm,'deliveryOnceAmount',1)
            this.$set(this.rules,'deliveryOnceAmount',null)
          }
        },
        deep:true
      },
      'ruleForm.importAndExport':{ //进/出口
        handler:function(to){
          if(to){
            this.$set(this.rules,'importAndExportCategory',{ required: true, message: '请选择进/出口类别', trigger: 'blur' })
          }else{
            this.$set(this.rules,'importAndExportCategory',null)
          }
        },
        deep:true
      },
      'ruleForm.urgentState':{ //加急费
        handler:function(to){
          if(to){
            this.$set(this.rules,'urgentDeliveryOnceCost',{ required: true, message: '请输入加急费', trigger: 'blur' })
          }else{
            this.$set(this.rules,'urgentDeliveryOnceCost',null)
          }
        },
        deep:true
      },
      'ruleForm.workSite':{//作业站点
        handler:async function(to){
          if(to){
            let res = await this.$req({
              url:`/dictionary/cache/lowerTree/${to}`,
              method:'GET',
              api:'/mng'
            })
            if(res.code==200){
              this.work_district = res.data
            }
          }
        },
        deep:true
      },
      cradle_province_city(to){ //提箱选择省市
        this.cradle_districtData = []
        this.cradle_district = ''
        this.cradle_collect = false
        if(to.length){
          this.$set(this.ruleForm,'firstPlaceProvince',to[0])
          this.$set(this.ruleForm,'firstPlaceCity',to[1])
          let cradle_districtData = TextToCode[to[0]][to[1]]
          for(let key in cradle_districtData){
            if(key!='code'&&key!='全部'){
              this.cradle_districtData.push({"label":key})
            }
          }
        }
      },
      cradle_district(to){ //提箱选择区
      this.cradle_collect = false
        if(to){
          this.$set(this.ruleForm,'firstPlaceDistrict',to)
        }
      },
      async cradle_collect(to){ //收藏提箱地址
        if(to){
          let address = await this.$req({
            url:'/address/query',
            api:'/mng',
            method:'GET',
            data:{page:1,limit:999}
          });
          var can_add_address = true;
          if(address.code == 200) {
            let addresses = address.data;
            for(var i = 0; i < addresses.length; i++) {
              var a = addresses[i];
              if(a.area == this.ruleForm.firstPlaceDistrict && a.city == this.ruleForm.firstPlaceCity
              && a.province == this.ruleForm.firstPlaceProvince && a.detailAddress == this.ruleForm.firstPlaceDetail) {
                console.log('地址重复，不用收藏');
                can_add_address = false;
                return false;
              }
            }
          }
          if(can_add_address) {
            let res = await this.$req({
              url:`/address`,
              api:'/mng',
              method:'POST',
              data:{
                province:this.ruleForm.firstPlaceProvince,
                city:this.ruleForm.firstPlaceCity,
                area:this.ruleForm.firstPlaceDistrict,
                detailAddress:this.ruleForm.firstPlaceDetail,
                userId:JSON.parse(localStorage.getItem("userinfo")).id,
                contactName:'联系人姓名',
                contactPhone:'1851011xxxx',
                addressName:this.ruleForm.firstPlaceDetail,
                defultAddress:false
              }
            })
            if(res.code==200){
              this.tips = res.data
            }
          }
        }
      },
      
      assemble_province_city(to){ //提箱选择省市
        this.assemble_districtData = []
        this.assemble_district = ''
        this.assemble_collect = false
        if(to.length){
          this.$set(this.ruleForm,'secondPlaceProvince',to[0])
          this.$set(this.ruleForm,'secondPlaceCity',to[1])
          let assemble_districtData = TextToCode[to[0]][to[1]]
          for(let key in assemble_districtData){
            if(key!='code'&&key!='全部'){
              this.assemble_districtData.push({"label":key})
            }
          }
        }
      },
      assemble_district(to){ //提箱选择区
      this.assemble_collect = false
        if(to){
          this.$set(this.ruleForm,'secondPlaceDistrict',to)
        }
      },
      async assemble_collect(to){ //收藏提箱地址
        if(to){
          let address = await this.$req({
            url:'/address/query',
            api:'/mng',
            method:'GET',
            data:{page:1,limit:999}
          });
          var can_add_address = true;
          if(address.code == 200) {
            let addresses = address.data;
            for(var i = 0; i < addresses.length; i++) {
              var a = addresses[i];
              if(a.area == this.ruleForm.secondPlaceDistrict && a.city == this.ruleForm.secondPlaceCity
              && a.province == this.ruleForm.secondPlaceProvince && a.detailAddress == this.ruleForm.secondPlaceDetail) {
                console.log('地址重复，不用收藏');
                can_add_address = false;
                return false;
              }
            }
          }
          if(can_add_address) {
            let res = await this.$req({
              url:`/address`,
              api:'/mng',
              method:'POST',
              data:{
                province:this.ruleForm.secondPlaceProvince,
                city:this.ruleForm.secondPlaceCity,
                area:this.ruleForm.secondPlaceDistrict,
                detailAddress:this.ruleForm.secondPlaceDetail,
                userId:JSON.parse(localStorage.getItem("userinfo")).id,
                contactName:'联系人姓名',
                contactPhone:'1851011xxxx',
                defultAddress:false
              }
            })
            if(res.code==200){
              this.tips = res.data
            }
          }
        }
      },
  
      purpose_province_city(to){ //收箱选择省市
        this.purpose_districtData = []
        this.purpose_district = ''
        this.purpose_collect = false
        if(to.length){
          this.$set(this.ruleForm,'thirdPlaceProvince',to[0])
          this.$set(this.ruleForm,'thirdPlaceCity',to[1])
          let purpose_districtData = TextToCode[to[0]][to[1]]
          for(let key in purpose_districtData){
            if(key!='code'&&key!='全部'){
              this.purpose_districtData.push({"label":key})
            }
          }
        }
      },
      purpose_district(to){ //收箱选择区
      this.purpose_collect = false
        if(to){
          this.$set(this.ruleForm,'thirdPlaceDistrict',to)
        }
      },
      async purpose_collect(to){ //收藏收箱地址
        if(to){
          let address = await this.$req({
            url:'/address/query',
            api:'/mng',
            method:'GET',
            data:{page:1,limit:999}
          });
          var can_add_address = true;
          if(address.code == 200) {
            let addresses = address.data;
            for(var i = 0; i < addresses.length; i++) {
              var a = addresses[i];
              if(a.area == this.ruleForm.thirdPlaceDistrict && a.city == this.ruleForm.thirdPlaceCity
              && a.province == this.ruleForm.thirdPlaceProvince && a.detailAddress == this.ruleForm.thirdPlaceDetail) {
                console.log('地址重复，不用收藏');
                can_add_address = false;
                return false;
              }
            }
          }
          if(can_add_address) {
            let res = await this.$req({
              url:`/address`,
              api:'/mng',
              method:'POST',
              data:{
                province:this.ruleForm.thirdPlaceProvince,
                city:this.ruleForm.thirdPlaceCity,
                area:this.ruleForm.thirdPlaceDistrict,
                detailAddress:this.ruleForm.thirdPlaceDetail,
                userId:JSON.parse(localStorage.getItem("userinfo")).id,
                contactName:'联系人姓名',
                contactPhone:'1851011xxxx',
                defultAddress:false
              }
            })
            if(res.code==200){
              this.tips = res.data
            }
          }
        }
      }
    },
    computed:{
      deliveryEstimateTotalCost(){
        if(this.ruleForm.deliveryMode=='Double'){// 双背
          if(this.ruleForm.containerTotalAmount%2 == 1){
            return (this.ruleForm.containerTotalAmount*1+1)/2*this.ruleForm.deliveryOnceCost
          }else{
            return this.ruleForm.containerTotalAmount/2*this.ruleForm.deliveryOnceCost
          }
        }else if(this.ruleForm.deliveryMode=='Single'){// 单背
          return this.ruleForm.deliveryOnceCost*this.ruleForm.containerTotalAmount
        }else if(this.ruleForm.deliveryMode=='More'){// 多背
          if(this.ruleForm.deliveryOnceAmount){
            if(this.ruleForm.containerTotalAmount%this.ruleForm.deliveryOnceAmount){
              return (parseInt(this.ruleForm.containerTotalAmount/this.ruleForm.deliveryOnceAmount)+1)*this.ruleForm.deliveryOnceCost
            }else{
              return this.ruleForm.containerTotalAmount/this.ruleForm.deliveryOnceAmount*this.ruleForm.deliveryOnceCost
            }
          }else{
            return 0
          }
        }
         
      },
      quotaUnusedComputed() {
        var order_cost = 0;
        var total_cost = 0;
        var total_amount = this.deliveryEstimateTotalCost;
            console.log('total_amount',total_amount);
        if(this.ruleForm.urgentState) {
          order_cost = this.ruleForm.urgentDeliveryOnceCost;
          total_cost = order_cost * this.deliveryOrderCount();
        }
        if(this.editnum_data) {
          if(this.editnum_data.urgentState) {
            total_cost -= this.editnum_data.urgentDeliveryOnceCost * Math.ceil(this.editnum_data.containerCirculateAmount/this.editnum_data.deliveryOnceAmount);
          }
          total_amount -= this.editnum_data.deliveryCirculateCost;
        }
        console.log('total_amount',total_amount);
        return this.quotaUnused?Math.round((this.quotaUnused-total_amount-total_cost)*100)/100:0;
      }
    },
    methods:{
      deliveryOrderCount() {
        // 已抢订单数
        var order_count = 0;
        // 已抢箱数
        var box_count = this.ruleForm.containerTotalAmount;
        if(this.ruleForm.deliveryMode == 'Double') {
          if(box_count%2 == 1){
            order_count = Math.floor(box_count / 2) + 1;
          } else {
            order_count = box_count / 2;
          }
        }else if(this.ruleForm.deliveryMode=='Single'){// 单背
          order_count = box_count;
        }else if(this.ruleForm.deliveryMode=='More'){// 多背
          if(this.ruleForm.deliveryOnceAmount) {
            if(box_count % this.ruleForm.deliveryOnceAmount) {
              order_count = parseInt(box_count / this.ruleForm.deliveryOnceAmount) + 1;
            } else {
              order_count = box_count / this.ruleForm.deliveryOnceAmount;
            }
          }
        }
        return order_count;
      },
      deliveryCirculateAmount() {
        // 已抢订单数
        var order_count = 0;
        // 已抢箱数
        var box_count = this.editnum_data.containerTotalAmount - this.editnum_data.containerCirculateAmount;
        if(this.editnum_data.deliveryMode == 'Double') {
          if(box_count%2 == 1){
            order_count = Math.floor(box_count / 2) + 1;
          } else {
            order_count = box_count / 2;
          }
        }else if(this.editnum_data.deliveryMode=='Single'){// 单背
          order_count = box_count;
        }else if(this.editnum_data.deliveryMode=='More'){// 多背
          if(this.editnum_data.deliveryOnceAmount) {
            if(box_count % this.editnum_data.deliveryOnceAmount) {
              order_count = parseInt(box_count / this.editnum_data.deliveryOnceAmount) + 1;
            } else {
              order_count = box_count / this.editnum_data.deliveryOnceAmount;
            }
          }
        }
        // 修改后已抢箱数
        var now_box_count = 0;
        if(this.ruleForm.deliveryMode == 'Double') {
          now_box_count = order_count * 2;
        }else if(this.ruleForm.deliveryMode=='Single'){// 单背
          now_box_count = order_count;
        }else if(this.ruleForm.deliveryMode=='More'){// 多背
          now_box_count = order_count * this.ruleForm.deliveryOnceAmount;
        }
  
        return this.ruleForm.containerTotalAmount - now_box_count;
      },
      deliveryCirculateCost(){
        // 已抢订单数
        var order_count = 0;
        if(this.editnum_data) {
          // 已抢箱数
          var box_count = this.editnum_data.containerTotalAmount - this.editnum_data.containerCirculateAmount;
          if(this.editnum_data.deliveryMode == 'Double') {
            if(box_count%2 == 1){
              order_count = Math.floor(box_count / 2) + 1;
            } else {
              order_count = box_count / 2;
            }
          }else if(this.editnum_data.deliveryMode=='Single'){// 单背
            order_count = box_count;
          }else if(this.editnum_data.deliveryMode=='More'){// 多背
            if(this.editnum_data.deliveryOnceAmount) {
              if(box_count % this.editnum_data.deliveryOnceAmount) {
                order_count = parseInt(box_count / this.editnum_data.deliveryOnceAmount) + 1;
              } else {
                order_count = box_count / this.editnum_data.deliveryOnceAmount;
              }
            }
          }
        }
  
        // 已抢订单占用修改后的金额
        var order_cost = order_count * this.ruleForm.deliveryOnceCost;
  
        return this.deliveryEstimateTotalCost - order_cost;
      },
      async uploadImg ({file}) {
        console.log('uploadFile',file)
        let loading = this.$loading({text:'上传中'});
        let res = await this.$req({
            url:`/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
            method:'GET',
            api :'/mng'
        })
        if(res.code==200){
            let img_res = await this.$uploadImg(res.data,file)
            this.bills.imgs.push({path:img_res, name:file.name})
        }
        loading.close()
      },
      async uploadFile ({file}) {
        console.log('uploadFile',file)
        let loading = this.$loading({text:'上传中'});
        let res = await this.$req({
            url:`/common/aliOssSign/${JSON.parse(localStorage.getItem('userinfo')).id}`,
            method:'GET',
            api :'/mng'
        })
        if(res.code==200){
            let img_res = await this.$uploadImg(res.data,file)
            this.bills.files.push({path:img_res, name:file.name})
        }
        loading.close()
      },
      delClick(index,flag){
        if(this.type == 'again') {
          return false;
        }
        if(flag=='file'){
          this.bills.files.splice(index,1)
        }else{
          this.bills.imgs.splice(index,1)
        }
      },
      // 点击选择收藏地址
      collectAddress(type){
        this.$emit('collectAddress',type)
      },
      // 查看协议车队
      lookCarTeam(){
        this.$emit('agreementCarTeam',this.shipCompany.id)
      },
      DateTim(rule, value, callback){
        console.log('isDateTime')
        let reg = /[1-9]\d{3}\-(((0[13578]|1[02])\-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)\-(0[1-9]|[12][0-9]|30))|(02\-(0[1-9]|[1][0-9]|2[0-9]))) ([0-1][0-9]|2[0-3]):([0-5][0-9])/;
          if (!reg.test(value)) {
              callback(new Error('请输入正确格式时间'));
          }
          let arr = value.split('-');
          let year = parseInt(arr[0]);
          let month = parseInt(arr[1]);
          let day = parseInt(arr[2]);
          if (month != 2 || day < 29) {
              callback(); // 不必校验闰年，肯定是合法时间了
          }
          console.log(year)
          // 能被400整除，或者能被4整除但不能被100整除的都是闰年，其余的年份均为平年
          if (year % 400 == 0 || (year % 4 == 0 && year % 100 != 0)) {
              console.log("====")
          } else {
              // 平年的2月只有28号
              if (month == 2 && day > 28) {
                  callback(new Error('平年的2月只有28号'));
              }
          }
          callback();
      },
      extractIdentityInput(to){
        console.log('extractIdentityInput',to)
        let arr = []
        for(let item of to.split('')){
          arr.push(item.toUpperCase())
        }
        this.ruleForm.extractIdentity = arr.toString().replace(/,/gi, '')
      },
      // 获取提示文字
      async getTips(){
        let res = await this.$req({
          url:`/about/cache/value/logistics_hd_send_page_top_tip`,
          api:'/mng'
        })
        if(res.code==200){
          this.tips = res.data
        }
      },
      // 获取订单详情
      async getOrderDetail(){
        let res = await this.$req({
          url:`/waybill/${this.edit_id}`,
          method:'GET'
        })
        if(res.code==200){
          for(let key in this.ruleForm){
            for(let name in res.data){
              if(key==name){
                if(Number.isInteger(res.data[key])){
                  this.$set(this.ruleForm,key,res.data[key]+'')
                }else{
                  this.$set(this.ruleForm,key,res.data[key])
                }
              }
            }
          }
          // 三个地址时间
          // this.$timereplace()
          // this.$set(this.ruleForm,'firstPlaceBeginTime',this.$timereplace(res.data.firstPlaceBeginTime))
          // this.$set(this.ruleForm,'firstPlaceEndTime',this.$timereplace(res.data.firstPlaceEndTime))
  
          // this.$set(this.ruleForm,'secondPlaceBeginTime',this.$timereplace(res.data.secondPlaceBeginTime))
          // this.$set(this.ruleForm,'secondPlaceEndTime',this.$timereplace(res.data.secondPlaceEndTime))
  
          // if(this.ruleForm.thirdPlaceBeginTime && this.ruleForm.thirdPlaceEndTime){
          //   this.$set(this.ruleForm,'thirdPlaceBeginTime',this.$timereplace(res.data.thirdPlaceBeginTime))
          //   this.$set(this.ruleForm,'thirdPlaceEndTime',this.$timereplace(res.data.thirdPlaceEndTime))
          // }
  
          // 三个地址地区
          this.cradle_province_city = [this.ruleForm.firstPlaceProvince, this.ruleForm.firstPlaceCity]
          setTimeout(()=>{ this.cradle_district = this.ruleForm.firstPlaceDistrict },300)
          this.assemble_province_city = [this.ruleForm.secondPlaceProvince, this.ruleForm.secondPlaceCity]
          setTimeout(()=>{ this.assemble_district = this.ruleForm.secondPlaceDistrict },300)
  
          if(this.ruleForm.thirdPlaceProvince&&this.ruleForm.thirdPlaceCity&&this.ruleForm.thirdPlaceDistrict){
            this.purpose_province_city = [this.ruleForm.thirdPlaceProvince, this.ruleForm.thirdPlaceCity]
            setTimeout(()=>{ this.purpose_district = this.ruleForm.thirdPlaceDistrict },300)
          }
          
  
          // 车队
          if(this.ruleForm.specifyCarTeams=='Yes'){
            await this.getTeamDetail(res.data.id)
          }else{
            this.carTeams_select = []
          }
          // 车辆
          if(this.ruleForm.specifyCars=='Yes'){
            await this.getCarDetail(res.data.id)
          }else{
            this.cars_select = []
          }
  
          this.$set(this.ruleForm,'goodsLoadingAndUnloading',this.ruleForm.goodsLoadingAndUnloading=='None'?true:false)
          this.$set(this.ruleForm,'importAndExport',this.ruleForm.importAndExport=='Include'?true:false)
          this.$set(this.ruleForm,'driverAdvanceFee',this.ruleForm.driverAdvanceFee=='Yes'?true:false)
          this.$set(this.ruleForm,'driverImageInfo',this.ruleForm.driverImageInfo=='Yes'?true:false)
          this.$set(this.ruleForm,'driverWeighInfo',this.ruleForm.driverWeighInfo=='Yes'?true:false)
          this.$set(this.ruleForm,'urgentState',this.ruleForm.urgentState=='Yes'?true:false)
          if(this.type=='again') {
            this.$set(this.ruleForm,'containerTotalAmount',res.data.containerCirculateAmount)
            this.$set(this.ruleForm,'sourceId',res.data.id)
          }
          this.bills = JSON.parse(this.ruleForm.bills);
  
          this.editnum_data = res.data
        }
      },
      // 获取订单的车队列表
      async getTeamDetail(id){
        let res = await this.$req({
          url:`/waybillCarTeams/queryAllJoinCarTeam`,
          method:'GET',
          data:{waybillId:id}
        })
        if(res.code==200){
          this.waybillCarTeams = []
          for(let item of res.data){
            this.waybillCarTeams.push({name:item.carTeamName, id:item.carTeamId})
          }
        }
      },
      // 获取订单的车队列表
      async getCarDetail(id){
        let res = await this.$req({
          url:`/waybillCars/queryAll`,
          method:'GET',
          data:{waybillId:id}
        })
        if(res.code==200){
          this.waybillCars = []
          for(let item of res.data){
            this.waybillCars.push(item.carNumber)
          }
        }
      },
      
      // 点击提交按钮
      submitForm(formName){
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            // 二次派单时，接单时间必须大于当前时间
            let nowtime_str = this.nowtimeStr()
            if(this.type=='again'){
              if(this.$dateChangeNum(nowtime_str) >= this.ruleForm.acceptEndTime){
                this.$message({ message:'接单截至时间不能小于当前时间',duration:1000 })
                return
              }
            }
            if(this.$dateChangeNum(nowtime_str) >= this.$dateChangeNum(this.ruleForm.firstPlaceEndTime)){
              this.$message({ message:'提箱截止时间不能小于当前时间',duration:1000 })
              return
            }
            if(this.$dateChangeNum(nowtime_str) >= this.$dateChangeNum(this.ruleForm.secondPlaceEndTime)){
              this.$message({ message:'送货/装货截止时间不能小于当前时间',duration:1000 })
              return
            }
  
            // “接单截止时间”不能大于“提货/箱截止时间”
            if(this.ruleForm.acceptEndTime >= this.$dateChangeNum(this.ruleForm.firstPlaceEndTime)){
              this.$message({ message:'接单截止时间不能大于提货/箱截止时间',duration:1000 })
              return
            }
            // 第一目的地截止时间应该小于第二目的地
            if(this.$dateChangeNum(this.ruleForm.firstPlaceEndTime) > this.$dateChangeNum(this.ruleForm.secondPlaceEndTime)){
              this.$message({ message:'提箱截止时间不能大于送货/装货截止时间',duration:1000 })
              return
            }
  
            // 是否选择指定车队
            if(this.ruleForm.specifyCarTeams=='Yes'){
              if(!this.waybillCarTeams.length){
                this.$message({ message:'请选择指定车队',duration:1000 })
                return
              }else{
                let carTeamIds = []
                for(let item of this.waybillCarTeams){
                  carTeamIds.push({carTeamId:item.id})
                }
                this.ruleForm.waybillCarTeams = carTeamIds
              }
            }else{
              this.ruleForm.waybillCarTeams = []
            }
  
            if(this.ruleForm.specifyCars=='Yes'){
              if(!this.waybillCars.length){
                this.$message({ message:'请选择指定车辆',duration:1000 })
                return
              }else{
                let carNumbers = []
                for(let item of this.waybillCars){
                  carNumbers.push({carNumber:item})
                }
                this.ruleForm.waybillCars = carNumbers
              }
              
            }else{
              this.ruleForm.waybillCars = []
            }
            
  
            if(this.quotaUnused < this.ruleForm.deliveryEstimateTotalCost){
              this.$emit('quotaInsufficient')
              return
            }
  
            // 是否拥有目的地
            if(this.ruleForm.includeThirdPlace=='No'){
              this.ruleForm.thirdPlaceProvince = ''
              this.ruleForm.thirdPlaceCity = ''
              this.ruleForm.thirdPlaceDistrict = ''
              this.ruleForm.thirdPlaceDetail = ''
              this.ruleForm.thirdPlaceBeginTime = ''
              this.ruleForm.thirdPlaceEndTime = ''
            }else{
              if(this.$dateChangeNum(nowtime_str) >= this.$dateChangeNum(this.ruleForm.thirdPlaceEndTime)){
                this.$message({ message:'送箱截止时间不能小于当前时间',duration:1000 })
                return
              }
              if(this.$dateChangeNum(this.ruleForm.secondPlaceEndTime) > this.$dateChangeNum(this.ruleForm.thirdPlaceEndTime)){
                this.$message({ message:'送货/装货截止时间不能大于送箱截止时间',duration:1000 })
                return
              }
            }
            this.ruleForm.userId = JSON.parse(localStorage.getItem('userinfo')).id
  
            let url = '/waybill'
            let message = '提交成功！'
            
            let data = {
              ...this.ruleForm,
              deliveryEstimateTotalCost:this.deliveryEstimateTotalCost,
              firstPlaceBeginTime:this.$dateChangeNum(this.ruleForm.firstPlaceBeginTime),
              firstPlaceEndTime:this.$dateChangeNum(this.ruleForm.firstPlaceEndTime),
              secondPlaceBeginTime:this.$dateChangeNum(this.ruleForm.secondPlaceBeginTime),
              secondPlaceEndTime:this.$dateChangeNum(this.ruleForm.secondPlaceEndTime),
              thirdPlaceBeginTime:this.$dateChangeNum(this.ruleForm.thirdPlaceBeginTime),
              thirdPlaceEndTime:this.$dateChangeNum(this.ruleForm.thirdPlaceEndTime),
  
              goodsLoadingAndUnloading:this.ruleForm.goodsLoadingAndUnloading?'None':'Include',
              importAndExport:this.ruleForm.importAndExport?'Include':'None',
              driverAdvanceFee:this.ruleForm.driverAdvanceFee?'Yes':'No',
              driverImageInfo:this.ruleForm.driverImageInfo?'Yes':'No',
              driverWeighInfo:this.ruleForm.driverWeighInfo?'Yes':'No',
              urgentState:this.ruleForm.urgentState?'Yes':'No',
              urgentDeliveryOnceCost:this.ruleForm.urgentState?this.ruleForm.urgentDeliveryOnceCost:0,
              bills:JSON.stringify(this.bills),
            }
            if(!data.shipCompanyId) {
              data.shipCompanyId = null
            }
            if(this.edit_id&&!this.type){ //type=='again' 二次派单
              url = `/waybill/${this.edit_id}`
              message = '修改成功！'
              var deliveryCirculateCost = this.deliveryCirculateCost();
              var deliveryCirculateAmount = this.deliveryCirculateAmount();
              if(deliveryCirculateAmount < 0) {
                this.$message({
                  message:'修改的箱量不能小于已抢箱量',
                  duration:1000
                })
                return false;
              }
              if(deliveryCirculateCost < 0) {
                this.$message({
                  message:'已有部分运单被抢，请修改箱子数量或单价，使“预计总运费金额”不小于 '+ (this.deliveryEstimateTotalCost - deliveryCirculateCost),
                  duration:1000
                })
                return false;
              }
              data.deliveryCirculateCost = deliveryCirculateCost;
              data.containerCirculateAmount = deliveryCirculateAmount;
            }
            let res = await this.$req({
              url,
              method:'POST',
              data
            })
            if(res.code==200){
              this.$message({
                type:'success',
                message,
                duration:1000
              })
              this.$emit('submitSuccess')
              if(this.edit_id){
                this.$router.replace('/order_list')
              }else{
                this.$router.push('/order_list')
              }
            }else{
              this.$message({
                message:res.msg,
                duration:1000
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
  
      async carsAdd(){
        let res = await this.$req({
          url:`/driver/query`,
          method:'GET',
          data:this.car_params
        })
        if(res.code==200){
          for(let item of this.waybillCars){
            for(let opt of res.data){
              if(item==opt.carNumber){
                opt.checked = true
              }
            }
          }
          this.car_list = res.data
          this.car_count = res.count
          this.car_show = true
        }
      },
      async carSearch(){
        this.car_params.page = 1
        await this.carsAdd()
      },
      async carReset(){
        this.car_params.page = 1
        this.car_params.searchKey = null
        this.car_params.carNumber = null
        await this.carsAdd()
      },
      async carPageChange(e){
        this.car_params.page = e
        this.carsAdd()
      },
      // 选择车辆
      carSelect(data){
        this.waybillCars.push(data.carNumber)
        this.car_show = false
      },
      // 点击删除车辆
      carDelClick(index){
        if(this.type != 'again') {
          this.waybillCars.splice(index,1);
          // this.$emit('carDelClick',index)
        }
      },
  
      // 点击添加车队
      addCarTeamsClick(id,name,index){
        console.log('addCarTeamsClick',id,name);
        this.waybillCarTeams.push({id,name})
        this.$set(this.carTeams_list[index],'checked',true)
      },
      
      // 点击搜索
      searchClick(){
        console.log(this.carTeams_params);
        if(this.carTeams_params.name!==''){
          this.carTeams_list = []
          this.carTeams_list_all.forEach((item)=>{
            if(item.name.indexOf(this.carTeams_params.name)!=-1){
              this.carTeams_list.push(item)
            }
          })
        }else{
          this.resetClick()
        }
      },
      resetClick(){
        this.$set(this.carTeams_params,'name','')
        this.carTeams_list = JSON.parse(JSON.stringify(this.carTeams_list_all))
      },
      // 点击移除车队
      carTeamsDelClick(index){
        this.waybillCarTeams.splice(index,1);
        // this.$emit('carTeamsDelClick',index)
      },
      // 点击添加车队
      carTeamsAdd(){
        var shipCompanyId = 0;
        if(this.shipCompany.id){
          // this.$message({ message:'请先选择船公司',duration:1000 })
          shipCompanyId = this.shipCompany.id;
        }
        this.carTeams_params = { //车队列表参数
          page:1,
          limit:6,
          name:''
        }
        this.shipCompanyId = shipCompanyId
        this.getCarTeams()
        this.carTeams_show = true
      },
      // 获取车队列表
      async getCarTeams(){
        let merchant_list = []
        this.carTeams_list_all = []
        if(this.shipCompanyId > 0) {
          let shipCompany_res = await this.$req({
            url:`/shipCompanyCarTeam/${this.shipCompanyId}/queryByShipCompany`,
            method:'GET',
            data:{page:1,limit:999}
          })
          if(shipCompany_res.code==200){
            for(var i = 0; i < shipCompany_res.data.length; i++) {
              this.carTeams_list_all.push(shipCompany_res.data[i])
            }
          }
        }
  
        let merchant_res = await this.$req({
          url:`/merchantCarTeam/${JSON.parse(localStorage.getItem('merchant')).id}/queryByMerchant`,
          method:'GET',
          data:{page:1,limit:999}
        })
        merchant_list = merchant_res.data
        if(merchant_list.length > 0 && this.carTeams_list_all.length > 0) {
          var new_list = [];
          merchant_list.forEach((opt)=>{
            this.carTeams_list_all.forEach((all_opt) => {
              if(opt.id == all_opt.id) {
                new_list.push(opt);
              }
            })
          })
          this.carTeams_list_all = new_list;
        } else if(merchant_list.length > 0 && this.carTeams_list_all.length == 0) {
          this.carTeams_list_all = merchant_list;
        }
        if(this.carTeams_list_all.length == 0 && merchant_list.length == 0 ) {
          merchant_res = await this.$req({
            // url:`/merchantCarTeam/${JSON.parse(localStorage.getItem('merchant')).id}/queryByMerchant`,
            url:'/carTeam/queryAll',
            method:'GET',
            data:{page:1,limit:999}
          })
          merchant_list = merchant_res.data
          merchant_list.forEach((opt)=>{
            var can_add = true;
            this.carTeams_list_all.forEach((all_opt) => {
              if(opt.id == all_opt.id) {
                can_add = false;
              }
            })
            if(can_add) {
              this.carTeams_list_all.push(opt)
            }
          })
        }
  
        this.carTeams_list = JSON.parse(JSON.stringify(this.carTeams_list_all))
        this.carTeams_count = this.carTeams_list.length
        if(this.waybillCarTeams.length){
          for(let item of this.waybillCarTeams){
            for(let option of this.carTeams_list){
              if(item.id==option.id){
                this.$set(option,'checked',true)
              }
            }
          }
        }
      },
      // 获取全部全部箱型
      async getAllShape(){
        let res = await this.$req({
          url:`/dictionary/cache/lowerTree/logistics_hd_container_shape`,
          method:'GET',
          api:'/mng'
        })
        if(res.code==200){
          this.shape_list = res.data
        }
      },
      // 获取全部全部尺寸
      async getAllSize(){
        let res = await this.$req({
          url:`/dictionary/cache/lowerTree/logistics_hd_container_size`,
          method:'GET',
          api:'/mng'
        })
        if(res.code==200){
          this.size_list = res.data
        }
      },
      //获取货物朝向
      async getAllOrientation(){
        let res = await this.$req({
          url:`/dictionary/cache/lowerTree/logistics_hd_orientation`,
          method:'GET',
          api:'/mng'
        })
        if(res.code==200){
          this.orientation_list = res.data
        }
      },
      // 获取	进/出口类别
      async getImportAndExportCategory(){
        let res = await this.$req({
          url:`/dictionary/cache/lowerTree/logistics_hd_i&e_category`,
          method:'GET',
          api:'/mng'
        })
        if(res.code==200){
          this.importAndExportCategory = res.data
        }
      },
      // 获取支付方式
      async getPaymentMethod(){
        let res = await this.$req({
          url:`/dictionary/cache/lowerTree/logistics_hd_pay_method`,
          method:'GET',
          api:'/mng'
        })
        if(res.code==200){
          this.payment_method = res.data
        }
      },
      // 获取作业站点
      async getWorkSite(){
        let res = await this.$req({
          url:`/dictionary/cache/lowerTree/logistics_hd_work_location`,
          method:'GET',
          api:'/mng'
        })
        if(res.code==200){
          this.work_site = res.data
        }
      },
      
      // 获取全部船公司
      async getShipCompany(){
        this.shipCompany_list = [{'name':'','code':'','id':''}];
        let res = await this.$req({
          url:'/shipCompany/queryAll?sortField=%5Ecode',
          method:'GET',
        })
        if(res.code==200){
          for(var i = 0; i < res.data.length; i++) {
            this.shipCompany_list.push(res.data[i]);
          }
        }
      },
      // 获取船公司协议车队列表
      async getCarTeam(shipCompanyId){
        let res = await this.$req({
          url:`/shipCompanyCarTeam/${shipCompanyId}/queryByShipCompany`,
          method:'GET',
        })
        if(res.code==200){
          this.carTeam_count = res.count
        }
      }
    }
  }
  </script>
  
  <style scoped lang="stylus">
  .component
    .form-content
      width 100%
      padding-left: 60px
      transform: translateY(-20px)
    .tips
      height: 36px;
      border-radius: 4px;
      padding: 8px 12px
      background-color: rgba(254, 234, 210, 1);
      font-size: 12px
      color: #FF8800
      line-height: 20px
      margin-right: 60px
    /deep/ .el-form
      .item
        margin-top: 20px
        color: #1F2329
        margin-bottom: 52px
        &>.name
          font-size: 16px
          line-height: 24px
          margin-bottom: 24px
          font-weight: 600
        .item-content
          display: flex
          flex-wrap: wrap
          font-size: 14px
          .el-form-item
            margin-right: 60px
            margin-bottom: 20px
            width 320px
            .el-form-item__label
              text-align: left
              color: #1F2329
              font-size: 14px
              line-height: 22px
              margin-bottom: 8px
              width 100% !important
            .el-form-item__content
              margin-left: 0 !important
              .el-date-editor,.el-select,.el-cascader
                width 100%
                .el-range-separator
                  width 25px
              .section
                display:flex
                width:100%
            .el-radio-button
              width 100px
              margin-right: 10px
              border: 1px solid #D0D3D6
              border-radius: 4px
              overflow: hidden
              .el-radio-button__inner
                width 100%
                border: none
            .is-active
              // background: #015DE7
              border: 1px solid #015DE7
              .el-radio-button__inner
                background: #015DE7
            .label
              color: #1F2329
              font-size: 14px
              line-height: 22px
              margin-bottom: 8px
              width 100%
            .default
              width: 100%;
              height: 32px;
              border-radius: 4px;
              background-color: rgba(244, 245, 249, 1);
              font-size: 14px
              padding: 5px 14px
            textarea
              width: 100%;
              height: 108px;
              border-radius: 4px;
              background-color: rgba(255, 255, 255, 1);
              border: 1px solid rgba(208, 211, 214, 1);
              padding: 8px
          .destination
            width: 100%
            display: flex
            .el-form-item
              width: 150px
              margin-right:25px
          .content-text
            color: #585E60
            font-size: 12px
            display: flex
            align-items: center
            height: 34px;
            border-radius: 4px;
            background-color: rgba(255, 255, 229, 1);
            border: 1px solid rgba(255, 204, 127, 1);
            margin-top: 30px
            width 584px
            padding: 0 8px
            margin-right:12px
            img
              width 14px
              height 14px
              margin-right: 10px
          .car-team
            width: 104px;
            height: 32px;
            background: #015DE7;
            border-radius: 4px;
            font-size: 14px
            line-height: 32px
            text-align: center
            color: #fff
            cursor pointer
            margin-top:30px
            font-size:13px
          .cars
            width calc(100% - 60px)
            padding: 12px 12px 0
            border-radius: 4px;
            background-color: rgba(248, 249, 250, 1);
            display: flex
            flex-wrap: wrap
            font-size: 14px 
            margin-bottom: 20px
            .car
              width: 296px;
              height: 36px;
              border-radius: 4px;
              background-color: rgba(255, 255, 255, 1);
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
              margin: 0 84px 12px 0
              display: flex
              align-items: center
              justify-content: space-between
              padding-left: 12px
              .del
                height 14px
                color: #F54A45
                padding: 0 14px
                border-left: 1px solid #D0D3D6
                line-height: 14px
                cursor pointer
            .car:nth-child(3n)
              margin-right: 0
            .car-add
              display: flex
              align-items: center
              justify-content: center
              color #015DE7
              width: 122px;
              height: 36px;
              border-radius: 4px;
              background-color: rgba(255, 255, 255, 1);
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
              margin-bottom: 12px
              cursor pointer
              img
                width 12px
                height 12px
                margin-right: 6px
          .remark
            width calc(100% - 60px)
            .label
              color: #1F2329
              font-size: 14px
              line-height: 22px
              margin-bottom: 8px
              width 100%
            textarea
              width: 100%;
              height: 108px;
              border-radius: 4px;
              background-color: rgba(255, 255, 255, 1);
              border: 1px solid rgba(208, 211, 214, 1);
              padding: 8px
            .file-list
              width:700px
              margin-top:10px
              .file-item
                display:flex
                justify-content: space-between
                .name
                  width 600px
                  overflow hidden
                  white-space nowrap
                  text-overflow ellipsis
                .del
                  cursor pointer
                  font-size 20px
                  font-weight 600
            .img-list
              display:flex
              margin-top:10px
              flex-wrap wrap
              .img-item
                margin 0 20px 20px 0
                width 100px
                height 100px
                position relative
                img
                  width 100%
                  height 100%
                .del
                  cursor pointer
                  font-size 20px
                  font-weight 600
                  position: absolute
                  top 0
                  right 0
      .bottom
        display: flex
        justify-content: flex-end
        padding-right: 60px
        margin-top: 52px
        .submit
          width: 84px;
          height: 36px;
          background: #015DE7;
          border-radius: 4px;
          font-size: 14px
          line-height: 36px
          text-align: center
          color: #fff
          cursor pointer
    .mask
      width 100vw
      height 100vh
      background: rgba(0, 0, 0, .6)
      position: fixed
      top 0
      left: 0
      z-index 2
      display: flex
      align-items: center
      justify-content: center
      .pop
        background: #fff
        width 634px
        border-radius: 4px;
        .title
          display: flex
          justify-content: space-between
          height 77px
          align-items: center
          padding: 0 32px
          img
            width 16px
            height 16px
            transform: translate(16px,-16px)
        .search
          display: flex
          padding: 0 32px
          input
            width: 230px; 
            height: 36px;
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 1)
            border: 1px solid rgba(199, 209, 216, 1)
            padding: 0 12px
            margin-right: 10px
          .btn
            width: 70px;
            height: 36px;
            border-radius: 4px;
            background-color: rgba(1, 93, 231, 1)
            font-size: 14px
            line-height: 36px
            text-align: center
            color: #fff
            cursor pointer
        .list-container
          padding: 20px 32px
          display: flex
          flex-direction: column
          align-items: center
          .list
            .item
              display: flex
              align-items: center
              justify-content: space-between
              width: 570px;
              height: 42px;
              background-color: rgba(246, 247, 249, 1)
              padding: 0 14px
              margin-bottom: 8px
              font-size: 14px
              color: #1A2537
              .blue
                color: #015DE7
                cursor pointer
              .grey
                color: #646A73
                cursor pointer
          .el-pagination
            margin-top: 24px
  
  </style>
  