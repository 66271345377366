<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted(){
    console.log('app.vue mounted')
    //判断vuex中是否有token,如果没有跳转到登录,有值进入首页
    if(!localStorage.getItem('token')){
      this.$router.push('/login')
    }else{
      if(localStorage.getItem('refreshToken')){
        this.$tokenRefresh()
        this.$router.push('/')
      }else{
        this.$router.push('/login')
      }
      // this.$router.push({name: 'send_bill'})
    }

    if(!localStorage.getItem('userinfo')){
      this.$router.push('/login')
    }

    this.getmoreConfig()
  },
  methods:{
    //获取上传ass路径
    async getmoreConfig(){
      let res = await this.$req({
        url:`/config/cache/value/ali_oss_outlink`,
        api:'/mng'
      })
      if(res.code==200){
        localStorage.setItem('oss_url',res.data)
      }
    }
  }
}
</script>

<style lang="stylus">

#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #1F2329
  margin: 0
  padding: 0

*
  box-sizing: border-box
  margin: 0
  padding: 0
  font-family: PingFangSC-Medium, PingFang SC;


// 公共样式
.pointer
  cursor pointer
.container
  background: #F7F9FB
  min-height: 100vh
  padding-top: 70px
  width: 100vw
  display: flex
  flex-direction: column
  align-items: center
  &>.title
    padding: 40px 0 20px
    font-size: 28px
    line-height: 44px
    color: #1F2329
    width 1200px
  &>.content
    width: 1200px
    margin-top: 20px
    border-radius: 4px
    background: #fff
    padding: 40px 0
    display: flex
    justify-content: center
    .form-content
      width 360px

</style>
