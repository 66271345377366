import { render, staticRenderFns } from "./driver_evaluation.vue?vue&type=template&id=b22675ca&scoped=true"
import script from "./driver_evaluation.vue?vue&type=script&lang=js"
export * from "./driver_evaluation.vue?vue&type=script&lang=js"
import style0 from "./driver_evaluation.vue?vue&type=style&index=0&id=b22675ca&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b22675ca",
  null
  
)

export default component.exports