<template>
    <!-- 注册账号 -->
    <div class="container">

        <!-- 头部 -->
        <div class="head">
            <img class="logo" src="@/assets/logo1.png" alt="">
        </div>

        <!-- 背景 -->
        <img class="bg" src="@/assets/bg.png" alt="">

        <!-- 表单 -->
        <div class="form">
            <div class="title">密码登录</div>
            <input type="text" v-model="params.account" placeholder="请输入手机号">
            <input type="password" v-model="params.password" placeholder="请输入密码">
            <div class="btn" @click="loginClick">登录</div>
            <div class="form-bottom">
                <div @click="registerClick">注册账号</div>
                <div @click="forgetClick">忘记密码</div>
            </div>
        </div>

        <el-dialog title="选择注册的账号类型" :visible.sync="registerDialogVisible" width="30%" @close="registerDialogVisible=false">
            <div class="dialog-content">
                <div @click="registerClickV2">
                    派单方
                </div>
                <div @click="registerOrderTakClick">
                    接单方
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'login',
    data() {
        return {
            params: {
                "terminal": "WEB",
                "account": '',
                "password": '',
                "readRoles": false,
                appletFlag: "hongde",
            },
            // phone:'18613372598'
            registerDialogVisible: false
        }
    },
    mounted() {
        localStorage.removeItem('token_refreshtime')
    },
    methods: {
        // 点击忘记密码
        forgetClick() {
            this.$router.push('/forget')
        },
        registerClick(){
            this.registerDialogVisible=true;
        },
        // 点击注册账号
        registerClickV2() {
            this.$router.push('/register')
        },
        //注册接单方
        registerOrderTakClick(){
            this.$router.push('/registerOrderTak')
        },
        //获取配置的背景图
        async getConfig() {
            let res = await this.$req({
                url: `/config/cache/value/ali_oss_outlink`,
                api: '/mng'
            })
            if (res.code == 200) {

            }
        },
        // 点击登录按钮
        async loginClick() {
            if (!this.params.account) {
                this.$message({ message: '请输入手机号', duration: 1000 });
                return
            } else if (!this.params.password) {
                this.$message({ message: '请输入密码', duration: 1000 });
                return
            }
            let res = await this.$req({
                url: '/user/login/phoneAndPassword',
                method: 'POST',
                data: this.params,
                api: '/mng'
            })
            if (res.code == 200) {
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('tokenV2', res.data.token)
                localStorage.setItem('userinfo', JSON.stringify(res.data))

                let token_data = decodeURIComponent(escape(window.atob(res.data.token.replace(/-/g, "+").replace(/_/g, "/").split('.')[1])))
                let token_info = JSON.parse(token_data)
                // console.log('获取token里包含的信息token_info',token_info)
                // console.log('获取token里包含的信息token_info',JSON.parse(token_info.User))
                localStorage.setItem('token_refreshtime', token_info.exp)
                localStorage.setItem('refreshToken', JSON.parse(token_info.User).refreshToken)
                this.$message({
                    type: 'success',
                    message: '登录成功!'
                    , duration: 500
                })
                await this.getmerchant()
                this.$router.push('send_bill')
            } else {
                this.$message({
                    type: 'warning',
                    message: res.msg
                    , duration: 1000
                })
            }
        },
        async getmerchant() {
            let res = await this.$req({
                url: '/merchant/detailBySelf'
            })
            if (res.code == 200) {
                localStorage.setItem('merchant', JSON.stringify(res.data))
                if (!res.data || !res.data.headImage || !res.data.nickname) {
                    this.$router.push('/userinfo')
                }
            }
        },
    }
}
</script>

<style scoped lang="stylus">
.container
    height: 100vh
    width: 100vw
    overflow: hidden
    position relative
    padding-top: 0
    // 头部
    .head
        width 100%
        height: 70px;
        background: #015DE7;
        padding: 15px 18px
        position relative
        z-index 2
        .logo
            width 150px
            height 50px
    // 背景
    .bg
        width 100%
        height: 100%
        position: absolute
        top 0
        left: 0
    .form
        position: absolute
        top 50%
        right: 120px
        transform: translateY(-50%)
        background: #fff
        border-radius: 4px
        padding: 28px 40px 
        .title
            font-size: 20px;
            color: #1F2329;
            line-height: 28px;
            margin-bottom: 24px
        input
            border none
            outline: none
            border-bottom: 1px solid #E6E8F2
            height 48px
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px
            width 100%
        .btn
            height: 44px;
            background: #015DE7;
            border-radius: 2px;
            font-size: 16px
            line-height: 24px
            color: #fff
            line-height: 44px
            text-align: center
            margin-top: 12px
            cursor pointer
        .form-bottom
            display: flex
            justify-content: space-between
            margin-top: 34px
            font-size: 12px;
            color: #101D37;
            line-height: 20px;
            div
                cursor pointer

    .dialog-content
        display: flex;
        flex-direction: row;
        justify-content: space-around

        div
            height: 30px
            cursor: pointer;
            
            &:hover
                color: #015DE7
    

</style>
