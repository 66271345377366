import axios from 'axios';
import { Message } from 'element-ui';
// import store from '../store'

// 统一请求路径前缀
// let base = '/logistics_hongde';

// 接口环境地址
// let API_ROOT = store.state.api

//本地环境
// let APP_BASE="http://localhost:9001";
//测试环境
// let APP_BASE="http://47.109.34.125:7103";
// 正式环境
let APP_BASE="https://api.port56.net";

// 超时设定
axios.defaults.timeout = 15000;
// 跨域访问携带cookie
// axios.defaults.withCredentials = true

axios.interceptors.request.use(config => {
  if (localStorage.getItem('token')) {
    config.headers['Authorization'] = localStorage.getItem('token')
  }
  // if (localStorage.getItem('tokenV2')) {
  //   config.headers['Authorization'] = localStorage.getItem('tokenV2')
  // }
  config.headers['Content-Type'] = "application/json;charset=UTF-8"
  return config;
}, err => {
  Message({ message: '请求超时', type: 'error' });
  return Promise.resolve(err);
});

// http response 拦截器
axios.interceptors.response.use(response => {
  console.log('response',response)
  const data = response.data;
  // 根据返回的code值来做不同的处理(和后端约定)
  if (response.status == 200) {
    return data;
  } else if (response.status == 500) {
    Message({ message: '系统正在升级', type: 'error' });
  } else {
    Message({ message: data.msg, type: 'warning' });
  }
}, (err) => {
  // 返回状态码不为200时候的错误处理
  Message({ message: '连接错误，请重试', type: 'error' });
  return Promise.resolve(err);
});

// 刷新token
export const tokenRefresh = () => {
  let api = ''
  if (process.env.NODE_ENV === "development") {
    // 开发环境
    // api = '/mng'
    api="http://localhost:9001"
  } else if (process.env.NODE_ENV === "production") {
    // 线上环境
    api = APP_BASE+'/mng'
    // api="http://47.109.34.125:7103/mng"
  }
  axios({
    method: "POST",
    url: `${api}/user/refresh/token`,
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    params: {
      refreshToken: localStorage.getItem('refreshToken')
    }
  }).then(res => {
    if (res.code == 200) {
      localStorage.setItem('token', res.data)
      let token_data = decodeURIComponent(escape(window.atob(res.data.replace(/-/g, "+").replace(/_/g, "/").split('.')[1])))
      let token_info = JSON.parse(token_data)
      localStorage.setItem('token_refreshtime', token_info.exp)
      localStorage.setItem('refreshToken', JSON.parse(token_info.User).refreshToken)
    } else {
      localStorage.removeItem('token')
      window.location.href = window.location.href.split('#')[0] + '#/login'
    }
  })
}

// 封装请求
export const Request = ({ method, url, data, api }) => {
  method = method || 'get'
  let format = (method.toLocaleLowerCase() === 'post' || method.toLocaleLowerCase() === 'put') ? 'data' : 'params';
  if (process.env.NODE_ENV === "development") {
    // 开发环境
    api = api || '/logistics_hongde'
  } else if (process.env.NODE_ENV === "production") {
    // 线上环境
    if (api == '/mng') {
      // api = 'https://api.port56.net/mng'
      //  api="http://47.109.34.125:7103/mng"
      api = APP_BASE+'/mng'
    } else if (api == '/content') {
      // api = 'https://api.port56.net/'
        // api="http://47.109.34.125:7103/"
        api = APP_BASE;
    } else {
      // api = 'https://api.port56.net/logistics_hongde'
      // api="http://47.109.34.125:7103/logistics_hongde"
      api = APP_BASE+'/logistics_hongde'
    }
    // api="http://47.109.34.125:7103"
    // api = APP_BASE
  }

  console.log(api,"api")

  return new Promise((resolve, reject) => {
    axios({
      method,
      // url:`${API_ROOT}${api || base}${url}`,
      url: `${api}${url}`,
      [format]: data || {},
      header: {
        'Authentication': localStorage.getItem('token'),
        "Content-Type": "application/json"
      },
    }).then(res => {
      let href = window.location.href.split('#')[0]
      
      if (res.code == 403) {
        localStorage.removeItem('token')
        tokenRefresh()
      } else if (res.code == 402) {
        localStorage.removeItem('token')
        
        window.location.href = href + '#/login'
      } else if (res.code == 411) {
        localStorage.removeItem('token')
        window.location.href = href + '#/login'
      } else if (res.code == 500) {
        // window.location.href = href+'#/login'
      }
      resolve(res);
    }).catch(() => {
      reject();
    })
  })
};

export const uploadFile = ({ method, url, data, api }) => {
  method = method || 'get'
  let format = (method.toLocaleLowerCase() === 'post' || method.toLocaleLowerCase() === 'put') ? 'data' : 'params';
  if (process.env.NODE_ENV === "development") {
    // 开发环境
    api = api || '/logistics_hongde'
  } else if (process.env.NODE_ENV === "production") {
    // 线上环境
    if (api == '/mng') {
      api = 'https://api.port56.net'
    } else if (api == '/content') {
      api = 'https://api.port56.net'
    } else {
      api = 'https://api.port56.net'
    }
    // api="http://47.109.34.125:7103";
    api='https://api.port56.net'
  }

  console.log(url,"url")
  return new Promise((resolve, reject) => {
    axios({
      method,
      // url:`${API_ROOT}${api || base}${url}`,
      url: `${api}${url}`,
      [format]: data || {},
      header: {
        'Authentication': localStorage.getItem('token'),
        "Content-Type": "multipart/form-data"
      },
    }).then(res => {
      // console.log('resolve',res)
      let href = window.location.href.split('#')[0]
      // console.log('href',href)
      if (res.code == 403) {
        localStorage.removeItem('token')
        tokenRefresh()
      } else if (res.code == 402) {
        localStorage.removeItem('token')
        window.location.href = href + '#/login'
      } else if (res.code == 411) {
        localStorage.removeItem('token')
        window.location.href = href + '#/login'
      } else if (res.code == 500) {
        // window.location.href = href+'#/login'
      }
      resolve(res);
    }).catch(() => {
      reject();
    })
  })
};


export const DownloadFile = ({ url, method, searchParams, api }) => {
  method = method || 'get'
  let format = (method.toLocaleLowerCase() === 'post' || method.toLocaleLowerCase() === 'put') ? 'data' : 'params';
  if (process.env.NODE_ENV === "development") {
    // 开发环境
    api = api || '/logistics_hongde'
  } else if (process.env.NODE_ENV === "production") {
    // 线上环境
    if (api == '/mng') {
      api = 'https://api.port56.net'
    } else if (api == '/content') {
      api = 'https://api.port56.net'
    } else {
      api = 'https://api.port56.net'
    }
  }

  var requestUrl = api + url;
  window.open(requestUrl + searchParams, '_blank')
};

//封装上传图片
export const uploadImg = (sing, file) => {
  // console.log('uploadImg',sing, file)
  let index = file.name.lastIndexOf(".")
  let suffix = file.name.substr(index + 1)
  let userId = JSON.parse(localStorage.getItem('userinfo')).id
  let key = userId + "/" + Date.now() + "." + suffix

  const formData = new FormData()
  formData.append('key', key)
  formData.append('OSSAccessKeyId', sing.accessId)
  formData.append('policy', sing.policy)
  formData.append('signature', sing.signature)
  formData.append('success_action_status', '200')
  formData.append('file', file) // 一定在最后

  let url = ''
  if (process.env.NODE_ENV === "development") {
    // 开发环境
    // url = '/api_img'
    url = localStorage.getItem('oss_url');
  } else if (process.env.NODE_ENV === "production") {
    // 线上环境
    url = localStorage.getItem('oss_url')
  }

  // console.log(localStorage.getItem('oss_url'), "localStorage.getItem('oss_url')")

  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'POST',
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then((res) => {
      // console.log('上传图片response',res)
      let url = ''
      url = localStorage.getItem('oss_url') + key
      if (res.code == 401 || res.code == 402) {
        localStorage.removeItem('userinfo')
        // localStorage.removeItem('token')
        window.location.href = window.location.href.split('#')[0] + '#/login'
      }
      resolve(url)
      // console.log('上传图片resolve',url)
    }).catch(error => {
      // console.log('error',error)
      reject(error)
    })
  })
}

