import { render, staticRenderFns } from "./SubmitInfo.vue?vue&type=template&id=39640135&scoped=true"
import script from "./SubmitInfo.vue?vue&type=script&lang=js"
export * from "./SubmitInfo.vue?vue&type=script&lang=js"
import style0 from "./SubmitInfo.vue?vue&type=style&index=0&id=39640135&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39640135",
  null
  
)

export default component.exports