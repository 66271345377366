<template>
<!-- 我要发单 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <!-- 页面内容 -->
    <div class="content">
      <!-- 未认证 -->
      <NotCertified v-if="!merchant_info&&!submit_show" @authenticationClick="authenticationClick"></NotCertified>

      <!-- 派单认证 提交信息 -->
      <SubmitInfo v-if="submit_show" @submitCancel="submitCancel" @submitSuccess="getMerchant" :Certified="Certified"></SubmitInfo>

      <!-- 派单认证 审核中 -->
      <UnderReview v-if="merchant_info&&merchant_info.authState=='Wait'"></UnderReview>

      <!-- 派单认证 审核未通过 -->
      <AuditFailed :info="merchant_info" v-if="merchant_info&&merchant_info.authState=='Fail'&&!submit_show" @aginClick="aginClick"></AuditFailed>

      <!-- 已认证 -->
      <Certified 
        ref="certified"
        v-if="merchant_info&&merchant_info.authState=='Success'" 
        @carTeamsAdd="carteamClick" 
        @carTeamsDelClick="carTeamsDelClick" 
        @carsAdd="carClick"
        @carDelClick="carDelClick"
        @submitSuccess="submitSuccess"
        @quotaInsufficient="quotaInsufficient"
        @agreementCarTeam="shipCompanyCarTeam"
        @collectAddress="collectAddress"
        :carTeams_select='carTeams_select' 
        :cars_select='cars_select' 
        :merchantId="merchant_info.id"
        :quotaUnused="merchant_info.quotaUnused"
        :edit_id="edit_id"
        :type="type"
      ></Certified>

    </div>

    <!-- 添加指定车队 -->
    <div class="mask" v-if="carTeams_show">
      <div class="pop">
        <div class="title">
          <div>添加指定车队</div>
          <img class="close" src="@/assets/close.png" alt="" @click="carTeams_show=false">
        </div>
        <div class="search">
          <input v-model="carTeams_params.name" type="text" placeholder="搜索车队名称">
          <div class="btn" @click="searchClick">搜索</div>
          <div class="btn" style="background:#fff;color:#666;border:1px solid #999;margin-left:8px" @click="resetClick">重置</div>
        </div>
        <div class="list-container">
          <div class="list">
            <div class="item" v-for="(item,index) in carTeams_list" :key="index">
              <div>{{item.name}}</div>
              <div class="blue" v-if="!item.checked" @click="addCarTeamsClick(item.id,item.name,index)">添加</div>
              <div class="grey" v-else>已添加</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 船公司协议车队 -->
    <div class="mask" v-if="shipCompany_carTeams_show">
      <div class="pop" style="width:750px">
        <div class="title">
          <div>查看协议车队</div>
          <img class="close" src="@/assets/close.png" alt="" @click="shipCompany_carTeams_show=false">
        </div>
        <div class="list-container">
          <el-table :data="shipCompany_carTeams_list" style="width: 100%">
            <el-table-column prop="name" label="车队名称" width="180" />
            <el-table-column prop="leader" label="负责人" width="80" />
            <el-table-column prop="leaderPhone" label="电话" width="120" />
            <el-table-column prop="personAmount" label="车辆数量" width="100" />
            <el-table-column prop="remark" label="备注" width="180" />
          </el-table>
          <el-pagination
            v-if="shipCompany_carTeams_count"
            background
            :page-size="shipCompany_carTeams_params.limit"
            layout="prev, pager, next"
            @current-change="shipCompanyPageChange"
            :total="shipCompany_carTeams_count">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 地址列表 -->
    <div class="mask" v-if="address_show">
      <div class="pop" style="width:750px; height:100%; overflow: auto;">
        <div class="title">
          <div>收藏的地址</div>
          <img class="close" src="@/assets/close.png" alt="" @click="address_show=false">
        </div>
        <div class="search">
          <input v-model="address_params.searchKey" type="text" placeholder="搜索关键字">
          <div class="btn" @click="addressSearch">搜索</div>
          <div class="btn" style="background:#fff;color:#666;border:1px solid #999;margin-left:8px" @click="addressReset">重置</div>
        </div>
        <div class="list-container">
          <el-table :data="address_list" style="width: 100%">
            <el-table-column prop="province" label="省市" width="180">
              <template slot-scope="scope">
                {{scope.row.province}}/{{scope.row.city}}
              </template>
            </el-table-column>
            <el-table-column prop="area" label="区" width="180" />
            <el-table-column prop="detailAddress" label="详细地址" min-width="120" />
            <el-table-column prop="" label="操作" width="80">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="addressSelect(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="address_count"
            background
            :page-size="address_params.limit"
            @current-change="addressPageChange"
            :total="address_count">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 车辆列表 -->
    <div class="mask" v-if="car_show">
      <div class="pop" style="width:750px">
        <div class="title">
          <div>添加指定车辆</div>
          <img class="close" src="@/assets/close.png" alt="" @click="car_show=false">
        </div>
        <div class="search">
          <input v-model="carTeams_params.name" type="text" placeholder="搜索关键字">
          <div class="btn" @click="carSearch">搜索</div>
          <div class="btn" style="background:#fff;color:#666;border:1px solid #999;margin-left:8px" @click="carReset">重置</div>
        </div>
        <div class="list-container">
          <el-table :data="car_list" style="width: 100%">
            <el-table-column prop="carNumber" label="车牌号" min-width="180" />
            <el-table-column prop="" label="操作" width="80">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.checked" size="mini" type="primary" @click="carSelect(scope.row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="car_count"
            background
            :page-size="carTeams_params.limit"
            layout="prev, pager, next"
            @current-change="carPageChange"
            :total="car_count">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 当前授信额度不足以发单 -->
    <div class="mask" v-if="quota_show">
      <div class="pop issue-documents">
        <img src="@/assets/shouxinedu.png" alt="">
        <div class="text">当前授信额度不足以发单</div>
        <div class="btn" @click="quota_show=false">确定</div>
      </div>
    </div>
   
  </div>
</template>

<script>
import Header from '../../components/Header.vue'

import NotCertified from '../../components/send_bill/NotCertified.vue'
import SubmitInfo from '../../components/send_bill/SubmitInfo.vue'
import UnderReview from '../../components/send_bill/UnderReview.vue'
import AuditFailed from '../../components/send_bill/AuditFailed.vue'
import Certified from '../../components/send_bill/Certified.vue'


export default {
  name: 'send_bill',
  components:{Header, NotCertified, SubmitInfo, UnderReview, AuditFailed, Certified},
  data(){
    return{
      userinfo:{},//用户详情
      submit_show:false,//
      merchant_info:{},//派单方信息
      carTeams_params:{ //车队列表参数
        page:1,
        limit:999,
        name:''
      },
      carTeams_count:0,
      carTeams_list:[], //车队
      carTeams_show:false,//选择车队弹窗
      carTeams_select:[],//选择的车队列表
      cars_select:[],//选择的车辆列表
      edit_id:'',
      type:'',
      quota_show:false,//额度不足弹窗

      shipCompany_carTeams_params:{ //船公司协议车队列表参数
        page:1,
        limit:6,
      },
      shipCompany_carTeams_list:[],
      shipCompany_carTeams_count:0,
      shipCompany_carTeams_show:false,

      address_show:false,
      address_list:[],
      address_count:0,
      address_params:{
        limit:99,
        page:1,
        searchKey:null,
        userId:'',
      },

      car_show:false,
      car_list:[],
      car_count:0,
      car_params:{
        limit:6,
        page:1,
        searchKey:null,
      }
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      vm.userinfo = localStorage.getItem('userinfo') ? JSON.parse(localStorage.getItem('userinfo')) : {}
      vm.getMerchant()
      vm.edit_id = vm.$route.query.id || ''
      vm.type = vm.$route.query.type || ''
    })
  },
  watch:{
    // 'carTeams_params.name':{
    //   handler:async function(to){
    //     if(!to){
    //       this.carTeams_params = {
    //         page:1,
    //         limit:6,
    //         name:''
    //       }
    //       await this.getCarTeams()
    //     }
    //   },
    //   deep:true
    // }
  },
  methods:{
    // 车辆列表
    async carClick(){
      let res = await this.$req({
        url:`/driver/query`,
        method:'GET',
        data:this.car_params
      })
      if(res.code==200){
        for(let item of this.cars_select){
          for(let opt of res.data){
            if(item==opt.carNumber){
              opt.checked = true
            }
          }
        }
        this.car_list = res.data
        this.car_count = res.count
        this.car_show = true
      }
    },
    async carSearch(){
      this.car_params.page = 1
      await this.carClick()
    },
    async carReset(){
      this.car_params.page = 1
      this.car_params.searchKey = null
      await this.carClick()
    },
    async carPageChange(){
      this.car_params.page = e
      this.carClick()
    },
    // 选择车辆
    carSelect(data){
      this.cars_select.push(data.carNumber)
      this.car_show = false
      console.log('this.$refs.certified.cars_select',this.$refs.certified.cars_select)
    },
    // 删除选中车辆
    carDelClick(index){
      console.log('carDelClick',this.cars_select);

      this.cars_select.splice(index,1)
    },
    // 选择地址
    addressSelect(data){
      if(this.address_type=='cradle'){
        this.$refs.certified.cradle_province_city = [data.province, data.city]
        setTimeout(()=>{ this.$refs.certified.cradle_district = data.area },300)
        this.$refs.certified.ruleForm.firstPlaceDetail = data.detailAddress
      }else if(this.address_type=='assemble'){
        this.$refs.certified.assemble_province_city = [data.province, data.city]
        setTimeout(()=>{ this.$refs.certified.assemble_district = data.area },300)
        this.$refs.certified.ruleForm.secondPlaceDetail = data.detailAddress
      }else if(this.address_type=='purpose'){
        this.$refs.certified.purpose_province_city = [data.province, data.city]
        setTimeout(()=>{ this.$refs.certified.purpose_district = data.area },300)
        this.$refs.certified.ruleForm.thirdPlaceDetail = data.detailAddress
      }
      
      this.address_show = false
    },
    // 地址列表
    async collectAddress(type){
      this.address_type = type
      let res = await this.$req({
        url:`/address/query`,
        api:'/mng',
        method:'GET',
        data:{...this.address_params, userId:JSON.parse(localStorage.getItem("userinfo")).id}
      })
      if(res.code==200){
        this.address_list = res.data
        this.address_count = res.count
        this.address_show = true
      }
    },
    async addressSearch(){
      this.address_params.page = 1
      await this.collectAddress()
    },
    async addressReset(){
      this.address_params.page = 1
      this.address_params.searchKey = null
      await this.collectAddress()
    },
    async addressPageChange(){
      this.address_params.page = e
      this.collectAddress()
    },
    // 查看船公司协议车队
    async shipCompanyCarTeam(shipCompanyId){
      let res = await this.$req({
        url:`/shipCompanyCarTeam/${shipCompanyId}/queryByShipCompany`,
        method:'GET',
        data:this.shipCompany_carTeams_params
      })
      if(res.code==200){
        this.shipCompany_carTeams_list = res.data
        this.shipCompany_carTeams_count = res.count
        this.shipCompany_carTeams_show = true
      }
    },
    shipCompanyPageChange(e){
      this.shipCompany_carTeams_params.page = e
      this.shipCompanyCarTeam()
    },
    // 点击重新认证
    aginClick(){
      this.submit_show = true
      this.Certified = 'again'
    },
    // 额度不足
    quotaInsufficient(){
      this.quota_show = true
    },
    // 发单成功
    submitSuccess(){
      this.merchant_info = {}
      this.getMerchant()
    },
    // 点击移除车队
    carTeamsDelClick(index){
      this.carTeams_select.splice(index,1)
    },
    // 点击添加车队
    addCarTeamsClick(id,name,index){
      this.carTeams_select.push({id,name})
      this.$set(this.carTeams_list[index],'checked',true)
    },
    // 展示选择车队弹窗
    carteamClick(shipCompanyId){
      this.carTeams_params = { //车队列表参数
        page:1,
        limit:6,
        name:''
      }
      this.shipCompanyId = shipCompanyId
      this.getCarTeams()
      this.carTeams_show = true
    },
    
    // 点击搜索
    searchClick(){
      console.log(this.carTeams_params);
      if(this.carTeams_params.name!==''){
        this.carTeams_list = []
        this.carTeams_list_all.forEach((item)=>{
          if(item.name.indexOf(this.carTeams_params.name)!=-1){
            this.carTeams_list.push(item)
          }
        })
      }else{
        this.resetClick()
      }
    },
    resetClick(){
      this.$set(this.carTeams_params,'name','')
      this.carTeams_list = JSON.parse(JSON.stringify(this.carTeams_list_all))
    },
    
    // 获取车队列表
    async getCarTeams(){
      let shipCompany_list = []
      let merchant_list = []
      this.carTeams_list_all = []
      if(this.shipCompanyId > 0) {
        let shipCompany_res = await this.$req({
          url:`/shipCompanyCarTeam/${this.shipCompanyId}/queryByShipCompany`,
          method:'GET',
          data:{page:1,limit:999}
        })
        if(shipCompany_res.code==200){
          shipCompany_list = shipCompany_res.data
          shipCompany_list.forEach((opt)=>{
          // if(ite.id==opt.id){
            this.carTeams_list_all.push(opt)
          // }
        })
        }
      }

      let merchant_res = await this.$req({
        url:`/merchantCarTeam/${JSON.parse(localStorage.getItem('merchant')).id}/queryByMerchant`,
        method:'GET',
        data:{page:1,limit:999}
      })
      if(merchant_res.code==200){
        merchant_list = merchant_res.data
      }
      // shipCompany_list.forEach((ite)=>{
        merchant_list.forEach((opt)=>{
          // if(ite.id==opt.id){
            this.carTeams_list_all.push(opt)
          // }
        })
      // })
      this.carTeams_list = JSON.parse(JSON.stringify(this.carTeams_list_all))
      this.carTeams_count = this.carTeams_list.length
      if(this.carTeams_select.length){
        for(let item of this.carTeams_select){
          for(let option of this.carTeams_list){
            if(item.id==option.id){
              this.$set(option,'checked',true)
            }
          }
        }
      }
    },
    // 根据用户id获取派单方信息
    async getMerchant(){
      let res = await this.$req({
        url:`/merchant/detailByUser/${JSON.parse(localStorage.getItem('userinfo')).id}`,
        method:'GET'
      })
      if(res.code==200){
        this.merchant_info = res.data || ''
        this.merchant_info.quotaUnused = this.merchant_info.quotaUnused/100;
        localStorage.setItem('merchant',JSON.stringify(res.data))
        console.log('this.merchant_info',this.merchant_info.authState)
        this.submit_show = false
      }
    },
    // 提交认证点击取消按钮
    submitCancel(){
      this.submit_show = false
    },
    // 点击去认证按钮
    authenticationClick(){
      this.submit_show = true
    },
  }
}
</script>

<style scoped lang="stylus">
.container
  &>.content
    min-height 810px
    padding: 40px 0 0
    margin-bottom: 200px
  .mask
    width 100vw
    height 100vh
    background: rgba(0, 0, 0, .6);
    position: fixed
    top 0
    left: 0
    z-index 2
    display: flex
    align-items: center
    justify-content: center
    .pop
      background: #fff
      width 634px
      border-radius: 4px;
      .title
        display: flex
        justify-content: space-between
        height 77px
        align-items: center
        padding: 0 32px
        img
          width 16px
          height 16px
          transform: translate(16px,-16px)
      .search
        display: flex
        padding: 0 32px
        input
          width: 230px; 
          height: 36px;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(199, 209, 216, 1);
          padding: 0 12px
          margin-right: 10px
        .btn
          width: 70px;
          height: 36px;
          border-radius: 4px;
          background-color: rgba(1, 93, 231, 1);
          font-size: 14px
          line-height: 36px
          text-align: center
          color: #fff
          cursor pointer
      .list-container
        padding: 20px 32px
        display: flex
        flex-direction: column
        align-items: center
        .list
          .item
            display: flex
            align-items: center
            justify-content: space-between
            width: 570px;
            height: 42px;
            background-color: rgba(246, 247, 249, 1);
            padding: 0 14px
            margin-bottom: 8px
            font-size: 14px
            color: #1A2537
            .blue
              color: #015DE7
              cursor pointer
            .grey
              color: #646A73
              cursor pointer
        .el-pagination
          margin-top: 24px
    .issue-documents
      width 440px
      display: flex
      flex-direction: column
      align-items: center
      padding: 30px 0 36px
      img
        width: 320px;
        height: 249px;
        margin-bottom: 32px
      .text
        font-size: 18px
        line-height: 26px
        color: #585E60
        margin-bottom: 32px
      .btn
        width: 96px;
        height: 32px;
        background-color: rgba(39, 137, 238, 1);
        font-size: 14px
        color: #fff
        line-height: 32px
        text-align: center
</style>
