<template>
<!-- 个人信息——投诉  -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="title">投诉</div>

    <!-- 页面内容 -->
    <div class="content">
      <div class="form-content">
          <div class="form">
              <div class="input">
                <div class="label">
                  <span>*</span>
                  投诉标题
                </div>
                <input type="text" v-model="params.title">
              </div>
              <div class="input">
                <div class="label">
                  <span>*</span>
                  投诉内容
                </div>
                <textarea v-model="params.content" name="" id="" cols="30" rows="10"></textarea>
              </div>
              <div class="btn" @click="submitClick">提交</div>
          </div>
      </div>
    </div>
   
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'complaint',
  components:{Header},
  data(){
    return{
      params:{
        "content": "",
        "title": "",
        "userId": "",
        "category": "cms_feedback_category_complaint",
      }
    }
  },
  methods:{
    // 点击保存
    async submitClick(){
      if(!this.params.title){
        this.$message({
          message:'请输入标题'
          , duration:1000
        })
        return
      }else if(!this.params.content){
        this.$message({
          message:'请输入内容'
          , duration:1000
        })
        return
      }
      this.params.userId = JSON.parse(localStorage.getItem('userinfo')).userId
      let res = await this.$req({
        url:'/feedback',
        method:'POST',
        api:'/content',
        data:this.params
      })
      if(res.code==200){
        this.$message({
          type:'success',
          message:'保存成功！'
          , duration:1000
        })
        this.$router.back(-1)
      }else{
        this.$message({
          message:res.msg
          , duration:1000
        })
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.container
  &>.content
    padding: 30px 0 56px
.form
  width 552px
  .input
    width 100%
    margin-bottom: 20px
    .label
      font-size: 14px
      line-height: 22px
      margin-bottom: 8px
      span
        color: #F54A45
    input
      font-size: 16px
      width 100%
      border: 1px solid #D0D3D6;
      border-radius: 4px;
      outline: none
      height: 100%
      height: 32px;
      padding: 0 12px
      font-size: 14px
    textarea
      border: 1px solid #D0D3D6;
      border-radius: 4px;
      height 144px
      outline: none
      width 100%
      padding: 12px
  .btn
    width 360px
    height: 44px;
    border-radius: 2px;
    background-color: rgba(1, 93, 231, 1);
    line-height: 44px
    text-align: center
    font-size: 16px
    color: #fff
    margin: 50px auto 0
</style>
