<template>
<!-- 查看司机评价 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="content">
      <!-- 司机 -->
      <div class="person">
        <div class="avatar">
          <img :src="driver_info.headImage" v-if="driver_info.headImage" alt="">
          <img class="avatar" src='@/assets/avatar.png' v-else alt="">
        </div>
        <div>
          <div class="name">
            <div>{{driver_info.name}}</div>
            <div class="phone">{{driver_info.contactPhone}}</div>
          </div>
          <div class="team">{{driver_info.carTeamName}} · {{driver_info.carNumber}}</div>
        </div>
      </div>

      <!-- 评价列表 -->
      <div class="comment">
        <div class="title">
          <img src="@/assets/star.png" alt="">
          <div>{{driver_info.evalAmount?(driver_info.evalScore/driver_info.evalAmount).toFixed(1)+' · ':''}}{{driver_info.evalAmount}}条评价</div>
        </div>
        <div class="list">
          <div class="item" v-for="(item,index) in list" :key="index">
            <div class="item-top">
              <div class="left">
                <img class="avatar" :src="item.headImage" alt="">
                <div class="">
                  <div class="name">{{item.userNickname}}</div>
                  <div class="date">{{item.createTime | datestamp}}</div>
                </div>
              </div>
              <div class="right">
                <img src="@/assets/star.png" alt="">
                <div>{{item.evalScore}}</div>
              </div>
            </div>
            <div class="text">{{item.evalContent}}</div>
          </div>

          <div class="pages">
            <el-pagination
              layout="prev, pager, next"
              :page-size="params.limit"
              :total="count"
              @current-change="currentChange"
              v-if="count">
            </el-pagination>
          </div>
        </div>
      </div>


    </div>
   
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'driver_evaluation',
  components:{Header},
  data(){
    return{
      driver_info:{},
      params:{
        limit:10,
        page:1,
        driverId:'',
        sortField:'create_time'
      },
      list:[],
      count:0
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
        vm.driver_info = JSON.parse(to.query.info)
        vm.params.driverId = JSON.parse(to.query.info).id
        vm.getList()
        // console.log('司机',vm.driver_info)
    })
  },
  methods:{
    currentChange(index){
      this.params.page = index
      this.getList()
    },
    async getList(){
      let res = await this.$req({
        url:'/driverEval/query',
        method:'GET',
        data:this.params
      })
      if(res.code==200){
        this.list = res.data
        this.count = res.count
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.container
  .content
    background: transparent
    margin-top: 30px
    padding: 0
    display: block
    // 司机
    .person
      width 100%
      display: flex
      align-items: center
      .avatar
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 12px
        overflow: hidden
        position relative
        img
          min-width 100%
          min-height: 100%
          max-width: 120%
          position: absolute
          top 50%
          left: 50%
          transform: translate(-50%,-50%)
      &>div
        .name
          display: flex
          align-items: flex-end
          color: #1F2329
          font-size: 20px
          line-height: 28px
          margin-bottom: 10px
          .phone
            font-size: 16px
            line-height: 24px
            margin-left: 12px
        .team
          font-size: 14px;
          color: #015DE7;
          line-height: 22px;
    .comment
      background: #fff
      margin-top: 30px
      .title
        display: flex
        align-items: center
        font-size: 20px;
        color: #1F2329;
        line-height: 28px;
        height 88px
        padding-left: 60px
        border-bottom: 1px dashed #E0E0E0
        img
          width 20px
          height 20px
          margin-right: 8px
      // 列表
      .list
        padding: 0 60px
        .item
          border-bottom: 1px solid #E0E0E0
          padding: 36px 0 30px
          .item-top
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 30px
            .left
              display: flex
              align-items: center
              .avatar
                width 48px
                height 48px
                margin-right: 10px
                border-radius: 4px;
              .name
                font-size: 16px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #2C2E32;
                line-height: 24px;
              .date
                margin-top: 4px
                font-size: 12px;
                color: #808080;
                line-height: 20px;
            .right
              display: flex
              align-items: center
              img
                width 16px
                height 16px
                margin-right: 10px
              div
                font-size: 16px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #2C2E32;
                line-height: 24px;
          .text
            font-size: 16px;
            color: #231815;
            line-height: 22px;
      .pages
        display: flex
        justify-content: center
        padding: 60px 0
        /deep/ .el-pagination
          display: flex
          align-items: center
          button
            i
              display: none
          .el-pager
            .number,.more
              width: 40px;
              height: 40px;
              line-height: 40px
              font-size: 16px
              margin-right: 8px
            .active
              background: #000000;
              border-radius: 50%
              color: #fff
</style>
