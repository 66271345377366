// 使用这些方法，仍然需要注意数值范围、舍入策略和比较运算等方面的问题，根据具体的应用场景进行适当的调整和处理。
export const math = {
    // 加法运算
    add: function (a, b) {
        const precision = Math.max(getPrecision(a), getPrecision(b));
        const multiplier = Math.pow(10, precision);
        const result=(Math.round(a * multiplier) + Math.round(b * multiplier)) / multiplier;
        return transferToNumber(result)
    },
 
    // 减法运算
    subtract: function (a, b) {
        return transferToNumber(math.add(a, -b));
    },
 
    // 乘法运算
    multiply: function (a, b) {
        const precision = getPrecision(a) + getPrecision(b);
        const multiplier = Math.pow(10, precision);
        const result=(Math.round(a * multiplier) * Math.round(b * multiplier)) / (multiplier * multiplier);
        return transferToNumber(result)
    },
 
    // 除法运算
    divide: function (num1, num2) {
        var str1 = Number(num1).toString(),
            str2 = Number(num2).toString(),
            result,
            str1Length,
            str2Length;
        //解决整数没有小数点方法
        try {
            str1Length = str1.split(".")[1].length;
        } catch (error) {
            str1Length = 0;
        }
        try {
            str2Length = str2.split(".")[1].length;
        } catch (error) {
            str2Length = 0;
        }
        var step = Math.pow(10, Math.max(str1Length, str2Length));
        result = (num1 * step) / (num2 * step);
        return transferToNumber(result);
    },
 
    // 获取浮点数的小数位数
};
function getPrecision(num) {
    const str = String(num);
    const decimalIndex = str.indexOf(".");
    return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1;
}
// 处理出现科学计数法
function transferToNumber(num) {
    if (isNaN(num)) {
      return num
    }
    num = '' + num
    num = parseFloat(num)
    let eformat = num.toExponential() // 转换为标准的科学计数法形式（字符串）
    let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/) // 分离出小数值和指数值
    let number = num.toFixed(Math.max(0, (tmpArray[1] || '').length - tmpArray[2]))
    return number 
}
