import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import './util/prototype'
import router from './router'
import store from './store'
import {Request,uploadImg,tokenRefresh,DownloadFile,uploadFile} from './util/request'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

import {math} from "./util/maths";


Vue.prototype.$req = Request
Vue.prototype.$uploadImg = uploadImg
Vue.prototype.$tokenRefresh = tokenRefresh
Vue.prototype.$downloadFile = DownloadFile
Vue.prototype.$uploadFile=uploadFile
Vue.prototype.$math=math;


// 拼接Get参数
Vue.prototype.joinGetParam = function(data) {
  let params = ''

  for (const key in data) {
    if (data[key]) {
      if (params.length === 0) {
        params += `?${key}=${data[key]}`
      } else {
        params += `&${key}=${data[key]}`
      }
    }
  }

  return params
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 日期格式化
Vue.prototype.dateFormat2Str = function(data) {
  if (data) {
    const time = data + ''

    if (time.length === 8) {
      return time.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
    } else if (time.length === 12) {
      return time.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5')
    } else if (time.length === 14) {
      return time.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6')
    } else {
      return ''
    }
  } else {
    return ''
  }
}