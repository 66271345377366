<template>
  <!-- 运单列表 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <!-- 筛选 -->
    <div class="screen">
      <div class="screen-content">
        <div class="search">
          <div class="search-item">
            <div class="label">提（运）单号：</div>
            <el-input size="small" v-model="params.waybillExtractIdentity" placeholder="请输入"></el-input>
          </div>
          <div class="search-item">
            <div class="label">发布时间：</div>
            <el-date-picker size="small" v-model="create_time" format="yyyy-MM-dd" value-format="yyyyMMdd"
              type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
            </el-date-picker>
          </div>
          <div class="search-item">
            <div class="label">提箱地：</div>
            <el-input size="small" v-model="params.firstPlace" placeholder="请输入"></el-input>
          </div>
          <div class="search-item">
            <div class="label">送货/装货地：</div>
            <el-input size="small" v-model="params.secondPlace" placeholder="请输入"></el-input>
          </div>
          <div class="search-item">
            <div class="label">收箱地：</div>
            <el-input size="small" v-model="params.thirdPlace" placeholder="请输入"></el-input>
          </div>

          <div class="btns">
            <div class="blue-btn" @click="searchClick">筛选</div>
            <div style="margin-right: 10px" class="reset" @click="resetClick">重置</div>
            <div class="reset" @click="exportClick">导出</div>
          </div>
        </div>
        <nav>
          <div class="nav" :class="{ 'nav-active': nav_active == 1 }" @click="nav_active = 1">
            <div>全部</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 2 }" @click="nav_active = 2">
            <div>已转单</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 3 }" @click="nav_active = 3">
            <div>已撤单</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 4 }" @click="nav_active = 4">
            <div>已接单</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 5 }" @click="nav_active = 5">
            <div>提箱确认</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 6 }" @click="nav_active = 6">
            <div>到达卸货/送货地</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 7 }" @click="nav_active = 7">
            <div>卸/装货已确认</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 8 }" @click="nav_active = 8">
            <div>已提箱确认</div>
            <div class="line"></div>
          </div>
          <div class="nav" :class="{ 'nav-active': nav_active == 9 }" @click="nav_active = 9">
            <div>已结案</div>
            <div class="line"></div>
          </div>
        </nav>
      </div>
    </div>

    <div class="content">
      <!-- 列表 -->
      <div class="list" v-if="0">
        <div class="item" v-for="(item, index) in order_list" :key="item.id"
          @click="detailClick(item.id, item.waybillId)">
          <!-- 进度 -->
          <div class="progress">
            <div class="progress-state">
              <!-- 未完成 -->
              <div class="black"
                v-if="(item.orderState == 'Wait_First_Address_Confirm' && item.waybillCradleLastTime > now_time) || item.orderState == 'Undo' || item.orderState == 'Give_Up'">
                起始</div>
              <!-- 已完成 -->
              <div class="green" v-else-if="
                item.orderState == 'Wait_Second_Address_Confirm' ||
                item.orderState == 'Wait_Third_Address_Confirm' ||
                item.orderState == 'Wait_End_Confirm' ||
                item.orderState == 'End'
              ">
                <img src="@/assets/green.png" alt="">
                <div>起始</div>
              </div>
            </div>

            <div class="progress-state">
              <img class="dot" src="@/assets/dot.png" alt="">

              <div class="black"
                v-if="item.orderState == 'Wait_First_Address_Confirm' || item.orderState == 'Wait_Second_Address_Confirm' || item.orderState == 'Undo' || item.orderState == 'Give_Up'">
                卸装</div>

              <div class="green" v-else-if="
                item.orderState == 'Wait_Third_Address_Confirm' ||
                item.orderState == 'Wait_End_Confirm' ||
                item.orderState == 'End'
              ">
                <img src="@/assets/green.png" alt="">
                <div>卸装</div>
              </div>
            </div>

            <div class="progress-state" v-if="item.waybillIncludeThirdPlace == 'Yes'">
              <img class="dot" src="@/assets/dot.png" alt="">

              <div class="black"
                v-if="item.orderState == 'Wait_First_Address_Confirm' || item.orderState == 'Wait_Second_Address_Confirm' || item.orderState == 'Wait_Third_Address_Confirm' || item.orderState == 'Undo' || item.orderState == 'Give_Up'">
                目的</div>

              <div class="green" v-else-if="item.orderState == 'End'">
                <img src="@/assets/green.png" alt="">
                <div>目的</div>
              </div>
            </div>
          </div>
          <!-- 信息 -->
          <div class="item-content">
            <!-- 信息 -->
            <div class="left">
              <div class="info-item">
                <div class="name">{{ item.firstPlaceDetail }}</div>
                <div class="address">{{ item.firstPlaceProvince }} {{ item.firstPlaceCity }} {{ item.firstPlaceDistrict }}
                </div>
                <div class="date">
                  <div class="label">提箱有效时间段：</div>
                  <div>{{ item.firstPlaceBeginTime | timereplace }} — {{ item.firstPlaceEndTime | timereplace }}</div>
                </div>
              </div>
              <div class="info-item">
                <div class="name">{{ item.secondPlaceDetail }}</div>
                <div class="address">{{ item.secondPlaceProvince }} {{ item.secondPlaceCity }} {{ item.secondPlaceDistrict }}
                </div>
                <div class="date">
                  <div class="label">送货/装货有效时间段：</div>
                  <div>{{ item.secondPlaceBeginTime | timereplace }} — {{ item.secondPlaceEndTime | timereplace }}</div>
                </div>
              </div>
              <div class="info-item" v-if="item.waybillIncludeThirdPlace == 'Yes'">
                <div class="name">{{ item.thirdPlaceDetail }}</div>
                <div class="address">{{ item.thirdPlaceProvince }} {{ item.thirdPlaceCity }} {{ item.thirdPlaceDistrict }}
                </div>
                <div class="date">
                  <div class="label">到目的地有效时间段：</div>
                  <div>{{ item.thirdPlaceBeginTime | timereplace }} — {{ item.thirdPlaceEndTime | timereplace }}</div>
                </div>
              </div>
            </div>
            <!-- 操作、 状态 -->
            <div class="operation">
              <div class="state"
                v-if="item.waybillCradleLastTime > now_time && item.orderState == 'Wait_First_Address_Confirm'">待提箱</div>
              <div class="state" v-if="item.orderState == 'Wait_Second_Address_Confirm'">到达卸货/送货地</div>
              <div class="state" v-if="item.orderState == 'Wait_End_Confirm' && item.waybillIncludeThirdPlace == 'No'">
                卸/装载待确认</div>
              <div class="state" v-if="item.orderState == 'Wait_Third_Address_Confirm'">已卸/装载</div>
              <div class="state" v-if="item.orderState == 'Wait_End_Confirm' && item.waybillIncludeThirdPlace == 'Yes'">
                目的地待确认</div>
              <div class="state" v-if="item.orderState == 'End'">已完成</div>
              <div class="state red-state" v-if="item.orderState == 'Undo'">已撤单</div>
              <div class="state red-state" v-if="item.orderState == 'Give_Up'">已弃单</div>
              <!-- 转单状态 -->
              <div class="tag" v-if="item.transferOrderState == 'Wait_Confirm'">转单中</div>
            </div>
          </div>
          <div class="item-bottom">
            <div class="left">
              <div class="info-item-bottom">
                <div>
                  <img src="@/assets/box.png" alt="">
                  <div class="label">箱子数量：</div>
                  <div>{{ item.transportAmount }}个</div>
                </div>
              </div>
              <div class="info-item-bottom">
                <div>
                  <img src="@/assets/driver.png" alt="">
                  <div class="label">接单司机：</div>
                  <div>{{ item.driverName }} &nbsp; {{ item.driverContactPhone }} &nbsp; {{ item.driverCarNumber }}</div>
                </div>
              </div>
              <div class="info-item-bottom fee">
                <div>
                  <img src="@/assets/money.png" alt="">
                  <div class="label">运费：</div>
                  <div>{{ item.freightCost }}元</div>
                </div>
              </div>
            </div>
            <div class="btns">
              <div class="blue-btn" v-if="item.orderState == 'Wait_End_Confirm' && item.waybillIncludeThirdPlace == 'No'"
                @click.stop="unloadingClick(item)">确认卸装</div>
              <div class="blue-btn" v-if="item.orderState == 'Wait_End_Confirm' && item.waybillIncludeThirdPlace == 'Yes'"
                @click.stop="arriveClick(item)">确认抵达</div>
              <div class="white-btn" v-if="item.orderState == 'End' && !item.driverEvalState"
                @click.stop="evaluateClick(item, index)">评价司机</div>
            </div>
          </div>
        </div>
      </div>
      <el-table :data="order_list" style="width: 100%">
        <el-table-column type="index" label="序号" width="50" />
        <el-table-column prop="waybillExtractIdentity" label="提（运）单号" min-width="120" />
        <el-table-column prop="driverName" label="接单司机" width="100" />
        <el-table-column prop="driverContactPhone" label="手机号" width="120" />
        <el-table-column prop="driverCarNumber" label="车牌号" width="120">
          <template slot-scope="scope">
            {{ scope.row.driverCarNumber || scope.row.teamCarNumber }}
          </template>
        </el-table-column>
        <el-table-column prop="orderState" label="运单状态" width="130">
          <template slot-scope="scope">
            {{ getOrderState(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="orderState" label="转单状态" width="80">
          <template slot-scope="scope">
            <div v-if="scope.row.transferState">{{ transferState[scope.row.transferState].label }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="containerSealNums" label="箱号" min-width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-for="(opt, optIndex) in JSON.parse(scope.row.containerSealNums)" :key="optIndex">{{ opt.identity }},
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" width="330">
          <template slot-scope="scope">
            <el-button size="mini" @click="waybillDetail(scope.row)">运单详情</el-button>
            <el-button
              v-if="scope.row.orderState == 'Third_Place_Confirm' || (scope.row.orderState == 'Second_Place_Confirm' && scope.row.waybillIncludeThirdPlace == 'No')"
              size="mini" type="primary" @click="waybillConfirm(scope.row)">运单确认完成</el-button>
            <el-button size="mini" @click="orderDetail(scope.row.waybillId)">所属订单详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination v-if="count" background :page-size="params.limit" layout="prev, pager, next"
        @current-change="pageChange" :total="count">
      </el-pagination>
    </div>

    <!-- 确认卸装/确认到达目的地弹窗 -->
    <div class="mask" v-if="confirm_show">
      <div class="pop">
        <div class="pop-title">
          <div>{{ title }}</div>
          <img src="@/assets/close.png" alt="">
        </div>
        <div class="item">
          <div class="left">箱一</div>
          <div class="right">
            <div>
              <div class="label">箱号：</div>
              <div class="value">{{ order_info.firstContainerIdentity }}</div>
            </div>
            <div v-if="waybill_info.containerFullRequire">
              <div class="label">铅封号：</div>
              <div class="value">{{ order_info.firstContainerSealIdentity }}</div>
            </div>
          </div>
        </div>
        <div class="item" v-if="waybill_info.doubleDelivery && order_info.secondContainerIdentity">
          <div class="left">箱二</div>
          <div class="right">
            <div>
              <div class="label">箱号：</div>
              <div class="value">{{ order_info.secondContainerIdentity }}</div>
            </div>
            <div v-if="waybill_info.containerFullRequire">
              <div class="label">铅封号：</div>
              <div class="value">{{ order_info.secondContainerSealIdentity }}</div>
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="cancel" @click="confirm_show = false">取消</div>
          <div class="confirm" @click="confirmClick">确定</div>
        </div>
      </div>
    </div>

    <!-- 评价司机弹窗 -->
    <div class="mask" v-if="evaluate_show">
      <div class="pop driver-pop">
        <div class="pop-title">
          <div>评价司机</div>
          <img src="@/assets/close.png" @click="evaluate_show = false" alt="">
        </div>
        <div class="form-item">
          <div class="label"><span>*</span>评分</div>
          <div class="stars">
            <!-- <img v-for="(item,index) in 5" :key="index" src="" alt=""> -->
            <el-rate v-model="evaluate_params.evalScore" :allow-half="false" />
          </div>
        </div>
        <div class="form-item">
          <div class="label"><span>*</span>评价</div>
          <textarea v-model="evaluate_params.evalContent" name="" id="" cols="30" rows="10"></textarea>
        </div>
        <div class="btns">
          <div class="cancel" @click="evaluate_show = false">取消</div>
          <div class="confirm" @click="evaluateConfirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'waybill_list',
  components: { Header },
  data() {
    return {
      nav_active: 1,
      value1: [],
      params: { //订单列表参数
        limit: 10,
        page: 1,
        waybillExtractIdentity: '',
        waybillId: '',
        sortField: 'create_time',
        starDate: null,
        endDate: null,
        firstPlace: null,
        secondPlace: null,
        thirdPlace: null,
      },
      order_list: [],//订单列表
      now_time: '',//当前时间
      confirm_show: false,
      title: '',
      waybill_info: {},//当前操作订单的运单详情
      order_info: {},//当前操作订单详情
      evaluate_show: false,
      evaluate_params: {
        "driverId": '',
        "headImage": "",
        "userId": '',
        "userNickname": "",
        "evalContent": "",
        "evalScore": '',
      },
      count: '',
      create_time: [],
      orderState: {
        Wait: { label: '待接单', value: 'Wait' },
        Accept: { label: '已接单', value: 'Accept' },
        First_Place_Confirm: { label: '提箱确认', value: 'First_Place_Confirm' },
        Second_Place_Arrive: { label: '到达卸货/送货地', value: 'Second_Place_Arrive' },
        Second_Place_Confirm: { label: '卸/装货已确认', value: 'Second_Place_Confirm' },
        Third_Place_Confirm: { label: '收箱确认', value: 'Third_Place_Confirm' },
        Merchant_Confirm: { label: '已结案', value: 'Merchant_Confirm' },
        Undo: { label: '已撤单', value: 'Undo' },
        Refuse: { label: '转单拒绝', value: 'Refuse' },
        back_address: { label: '返箱回提箱地确认', value: 'back_address' },
        driver_finish: { label: '返箱至提箱地完成', value: 'driver_finish' },
      },
      transferState: {
        Wait: { label: '待接单', value: 'Wait' },
        Accept: { label: '已转单', value: 'Accept' },
        Reject: { label: '已拒绝', value: 'Reject' },
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('to', to)
    console.log('form', from)
    next(async vm => {
      await vm.getOrders()
    })
  },
  watch: {
    async nav_active(to) {
      this.params = {
        limit: 10,
        page: 1,
        waybillExtractIdentity: '',
        firstPlace: null,
        secondPlace: null,
        thirdPlace: null,
        waybillId: this.$route.query.id,
        sortField: 'create_time',
        starDate: null,
        endDate: null,
        waybillIncludeThirdPlace: null,
        orderState: null,
        transferState: null,
      }
      if (to == 2) { //已转单
        this.params.transferState = 'Accept'
      } else if (to == 3) { //已撤单
        this.params.orderState = 'Undo'
      } else if (to == 4) { //已接单
        this.params.orderState = 'Accept'
      } else if (to == 5) { //提箱确认
        this.params.orderState = 'First_Place_Confirm'
      } else if (to == 6) { //到达卸货/送货地
        this.params.orderState = 'Second_Place_Arrive'
      } else if (to == 7) { //卸/装货已确认
        this.params.orderState = 'Second_Place_Confirm'
      } else if (to == 8) { //已提箱确认
        this.params.orderState = 'Third_Place_Confirm'
      } else if (to == 9) { //已结案
        this.params.orderState = 'Merchant_Confirm'
      }
      await this.getOrders()
    },
    evaluate_show(to) {
      if (!to) {
        this.evaluate_params = {
          "driverId": '',
          "headImage": "",
          "userId": '',
          "userNickname": "",
          "evalContent": "",
          "evalScore": '',
        }
      }
    },
    create_time(to) {
      if (to.length) {
        this.params.starDate = to[0]
        this.params.endDate = to[0]
      } else {
        this.params.starDate = null
        this.params.endDate = null
      }
    }
  },
  methods: {
    getOrderState(data) {
      var label = this.orderState[data.orderState].label;
      return label;
    },
    exportClick() {

    },
    async waybillConfirm(data) {
      let res = await this.$req({
        url: `/waybillOrder/${data.id}/merchant/confim`,
        method: 'POST',
        data: {
          freightDriverManualCost: data.freightDriverCost,
          freightServiceFeeManualCost: data.freightServiceFeeCost,
          placeManuaFee: ((data.firstPlaceFee || 0) * 1) + ((data.secondPlaceFee || 0) * 1) + ((data.thirdPlaceFee || 0) * 1),
          urgentDriverManualCost: data.urgentDriverCost,
          urgentServiceFeeManualCost: data.urgentServiceFeeCost,
        }
      })
      if (res.code == 200) {
        this.getOrders()
        this.$message({
          type: 'success',
          message: '操作成功'
          , duration: 1000
        })
      } else {
        this.$message({ message: res.msg, duration: 1000 });
      }
    },
    orderDetail(id) {
      this.$router.push({ path: '/order_detail', query: { id } })
    },
    // 点击跳转运单详情
    waybillDetail({ id, waybillId }) {
      this.$router.push({ path: '/waybill_detail', query: { id, waybillId } })
    },
    async confirmClick() {
      let res = await this.$req({
        url: `/waybillOrder/confimEnd/${this.order_info.id}`,
        method: 'POST'
      })
      if (res.code == 200) {
        this.confirm_show = false
        this.getOrders()
        this.$message({
          type: 'success',
          message: '确认成功！'
          , duration: 1000
        })
      } else {
        this.$message({
          message: res.msg
          , duration: 1000
        })
      }
    },
    // 获取运单详情
    async getWaybill(id) {
      let res = await this.$req({
        url: `/waybill/${id}`,
        method: 'GET'
      })
      if (res.code == 200) {
        this.waybill_info = res.data
      }
    },
    // 点击确认卸装
    async unloadingClick(data) {
      this.title = '确认卸/装载'
      this.order_info = data
      await this.getWaybill(data.waybillId)
      this.confirm_show = true
    },
    // 点击确认抵达
    async arriveClick(data) {
      this.title = '确认抵达目的地'
      this.order_info = data
      await this.getWaybill(data.waybillId)
      this.confirm_show = true
    },
    // 点击评价司机
    evaluateClick(data, index) {
      let merchant = JSON.parse(localStorage.getItem('merchant'))
      if (!merchant.nickname || !merchant.headImage) {
        this.$message({
          message: '上传头像并填写昵称后才能评价'
          , duration: 1000
        })
        return
      }
      this.evaluate_show = true
      this.evaluate_data = data
    },
    async evaluateConfirm() {
      if (!this.evaluate_params.evalScore) {
        this.$message({
          message: '请选择评价分数'
          , duration: 1000
        })
        return
      } else if (!this.evaluate_params.evalContent) {
        this.$message({
          message: '请输入评价内容'
          , duration: 1000
        })
        return
      }
      this.evaluate_params.driverId = this.evaluate_data.currentDriverId
      this.evaluate_params.userId = this.evaluate_data.currentDriverUserId
      this.evaluate_params.userNickname = JSON.parse(localStorage.getItem('merchant')).nickname
      this.evaluate_params.headImage = JSON.parse(localStorage.getItem('merchant')).headImage

      // console.log('this.evaluate_params',this.evaluate_params,JSON.parse(localStorage.getItem('merchant')))
      let res = await this.$req({
        url: `/driverEval/addByMerchant/${this.evaluate_data.id}`,
        method: 'POST',
        data: this.evaluate_params
      })
      if (res.code == 200) {
        this.getOrders()
        this.$message({
          message: '评价成功',
          type: 'success'
          , duration: 1000
        })
        this.evaluate_show = false
      } else {
        this.$message({
          message: res.msg
          , duration: 1000
        })
      }
    },
    // 获取现在时间
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //获取完整的年份(4位,1970-????)
      let month = now.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let today = now.getDate(); //获取当前日(1-31)
      let hour = now.getHours(); //获取当前小时数(0-23)
      let minute = now.getMinutes(); //获取当前分钟数(0-59)
      let second = now.getSeconds(); //获取当前秒数(0-59)
      this.now_time = year + this.fillZero(month) + this.fillZero(today) + this.fillZero(hour) + this.fillZero(minute) + this.fillZero(second)
    },
    fillZero(str) {
      var realNum;
      if (str < 10) {
        realNum = '0' + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
    // 点击筛选
    searchClick() {
      this.params.page = 1
      this.getOrders()
    },
    // 点击重置
    resetClick() {
      this.params = {
        limit: 10,
        page: 1,
        waybillExtractIdentity: '',
        firstPlace: null,
        secondPlace: null,
        thirdPlace: null,
        waybillId: this.$route.query.id,
        starDate: null,
        endDate: null,
        sortField: 'create_time',
      }
      this.create_time = []
      this.getOrders()
    },
    // 获取订单列表
    async getOrders() {
      var accept = 0;
      if (this.params.orderState == 'Accept_2') {
        accept = 2;
        this.params.orderState = 'Accept'
      } else if (this.params.orderState == 'Accept') {
        accept = 1;
      }
      let res = await this.$req({
        url: '/waybillOrder/queryForMerchant',
        method: "GET",
        data: {
          ...this.params,
          waybillId: this.$route.query.id || ''
        }
      })
      if (res.code == 200) {
        this.order_list = res.data
        if (accept == 1) {
          for (var i = 0; i < this.order_list.length; i++) {
            if (this.order_list[i].containerSealNums) {
              this.order_list.splice(i, 1);
            }
          }
        } else if (accept == 2) {
          for (var i = 0; i < this.order_list.length; i++) {
            if (!this.order_list[i].containerSealNums) {
              this.order_list.splice(i, 1);
            }
          }
        }
        this.count = res.count
      }
    },
    pageChange(e) {
      console.log(e)
      this.params.page = e
      this.getOrders()
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  padding-top: 280px
  .screen
    width 100vw
    position: fixed
    background: #fff
    background-color: #fff
    top 70px
    left: 0
    z-index 1
    display: flex
    justify-content: center
    .screen-content
      width 1200px
      // 搜索
      .search
        padding: 30px 0 0px
        display: flex
        flex-wrap: wrap
        .search-item
          display: flex
          align-items: cennter
          margin-right: 40px
          margin-bottom 20px
          .el-input,.el-date-editor
            width 200px
            /deep/ .el-range-separator
              width 20px
            /deep/ .el-input__icon
              display: none
          div
            line-height: 32px
        .btns
          display: flex
          div
            width: 84px;
            height: 32px;
            border-radius: 4px;
            font-size: 14px
            text-align: center
            line-height: 32px
            cursor pointer
          .blue-btn
            color: #fff
            background: #015DE7;
            margin-right: 10px
          .reset
            border: 1px solid #D0D3D6;
            color: #22272E
      // 状态
      nav
        height 72px
        display: flex
        .nav
          height 100%
          position relative
          margin-right: 60px
          cursor pointer
          div
            line-height: 72px
          .line
            width: 36px;
            height: 3px;
            background-color: rgba(1, 93, 231, 1);
            position: absolute
            bottom: 0
            left: 50%
            transform: translateX(-50%)
            display: none
        .nav-active
          .line
            display: block
  &>.content
    width 1200px
    margin: 20px auto 0
    padding: 0
    background: transparent
    display: block
    // 列表
    .list
      width 100%
      &>.item
        padding: 30px 20px 25px
        margin-bottom: 10px
        background: #fff
        cursor pointer
        // 进度
        .progress
          display: flex
          align-items: center
          margin-bottom: 10px
          .progress-state
            width 350px
            position relative
            .black
              width: 32px;
              height: 20px;
              border-radius: 2px;
              background-color: rgba(38, 44, 52, 1);
              color: #fff
              line-height: 20px
              text-align: center
              font-size: 12px
            .green
              display: flex
              align-items: center
              font-size: 14px
              color #00A045
              img 
                width 16px
                height 16px
                margin-right: 6px
            .orange
              display: flex
              align-items: center
              font-size: 14px
              color #FF9100
              img 
                width 16px
                height 16px
                margin-right: 6px
            .dot
              position: absolute
              height 4px
              margin-right: 44px
              width 214px
              top 50%
              right: 350px
              transform: translateY(-50%)
        .item-content
          display: flex
          justify-content: space-between
          &>.left
            display: flex
            .info-item
              width 350px
              color #646A73
              .name
                color: #22272E
                font-size: 16px
                line-height: 24px
                margin-bottom: 2px
              .address
                font-size: 14px
                line-height: 22px
                margin-bottom: 12px
              .date
                font-size: 12px
                line-height: 20px
                margin-bottom: 24px
                .label
                  color: #8F959E
          .operation
            display: flex
            flex-direction: column  
            align-items: flex-end
            .state
              color: #015DE7
              font-size: 20px
              line-height: 28px
            .red-state
              color: #F54A45
            .tag
              background: #FF9100;
              border-radius: 2px;
              font-size: 12px
              color: #fff
              padding: 1px 6px
              margin-top: 6px
        .item-bottom
          display: flex
          justify-content: space-between
          .left
            display: flex
            .info-item-bottom
              width 350px
              display: flex
              align-items: center
              justify-content: space-between
              font-size: 12px
              line-height: 22px
              &>div 
                display: flex
                align-items: center
                img
                  width 22px
                  height 22px
                  margin-right: 10px
                .label
                  color: #8F959E   
            .fee
              width: auto  
          .btns
            display: flex
            align-items: flex-end
            padding-right: 16px 
            div
              padding: 5px 10px
              border-radius: 4px;
              text-align: center
              font-size: 14px
              margin-left: 10px
              cursor pointer
            .white-btn
              border: 1px solid rgba(208, 211, 214, 1);
              color: #22272E 100%
            .blue-btn
              background-color: #015DE7
              color: #fff
            .red-btn
              color: #EF4141
              border: 1px solid #FFAAAA;
    .el-pagination
      margin: 20px auto
      display: flex
      justify-content: center
// 确认卸装/确认到达目的地弹窗
.mask
  width 100vw
  height: 100vh
  position: fixed
  top 0
  left: 0
  z-index: 999
  background: rgba(0, 0, 0, .6)
  display: flex
  align-items: center
  justify-content: center
  .pop
    width: 600px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 22px 24px
    color #22272E
    .pop-title
      display: flex
      justify-content: space-between
      align-items: center
      font-size: 24px
      margin-bottom: 24px
      img
        width 28px
        height 28px
        transform: translateX(14px)
        cursor pointer
    .item
      display: flex
      height: 78px;
      background: #F4F5F9;
      margin-bottom: 10px
      &>div
        display: flex
        flex-direction: column
        justify-content: center
      .left
        width: 72px;
        height: 78px;
        align-items: center
        background: #6F757E;
        color: #fff
      .right
        &>div
          color #101D37
          font-size: 16px
          line-height: 24px
          margin-bottom: 6px
          display: flex
          .label
            width 94px
            padding-right: 12px
            text-align: right
    .form-item
      margin-bottom: 20px
      .label
        display: flex
        font-size: 14px
        line-height: 22px
        margin-bottom: 8px
        span
          color: #F54A45
          margin-right: 4px
      .stars
        display: flex
        img
          width 36px
          height 36px
          margin-right: 12px
      textarea
        height: 144px;
        width 100%
        padding: 10px
        outline: none
        border: 1px solid #D0D3D6;

    .btns
      display: flex
      justify-content: flex-end
      margin-top: 24px
      div
        width: 80px;
        height: 32px;
        border-radius: 4px;        
        line-height: 30px
        text-align: center
        font-size: 14px
        margin-left: 12px
        cursor pointer
      .cancel
        border: 1px solid #DDDEDE;
        color: #1F2329
      .confirm
        color: #fff
        background: #015DE7
        border: 1px solid #015DE7;
            
</style>
