<template>
<!-- 运单详情 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>
    <div class="content">
      <div class="item state-item" v-if="waybill_detail">
        <div class="state">
          <div v-if="waybill_detail.orderState=='Accept'">已接单</div>
          <div v-if="waybill_detail.orderState=='First_Place_Confirm'">提箱确认</div>
          <div v-if="waybill_detail.orderState=='Second_Place_Arrive'">到达卸货/送货地</div>
          <div v-if="waybill_detail.orderState=='Second_Place_Confirm'">卸/装货已确认</div>
          <div v-if="waybill_detail.orderState=='Third_Place_Confirm'">收箱确认</div>
          <div v-if="waybill_detail.orderState=='Merchant_Confirm'">已结案</div>
          <div v-if="waybill_detail.orderState=='driver_finish'">返箱回提箱地完成</div>
          <div class="red-state" v-if="waybill_detail.orderState=='Undo'">已撤单</div>

          <!-- <div class="give-up" v-if="waybill_detail.orderState=='Give_Up'">
            <div>您将获得弃单补偿</div>
            <div class="giveup-money">
              <span>{{waybill_detail.giveUpCost?(waybill_detail.giveUpCost/100).toFixed(2):'0'}}</span>元
            </div>
          </div> -->

          <span class="transfer-state" v-if="waybill_detail.transferState=='Wait'">转单中</span>
        </div>

        <div class="date">
          <div>发布时间：{{order_detail.createTime | timestamp}}</div>
          <div>接单的最晚截止时间：{{order_detail.acceptEndTime | timereplace}}</div>
        </div>
        <!-- <div class="btn" v-if="waybill_detail.orderState=='Wait_End_Confirm' && order_detail.includeThirdPlace=='No'" @click.stop="unloadingClick()">确认卸装</div> -->
        <div class="btn" v-if="waybill_detail.orderState=='Merchant_Confirm' && !waybill_detail.driverEvalState" @click.stop="evaluateClick()">评价司机</div>
        <!-- <div class="btn" v-if="waybill_detail.orderState=='Wait_End_Confirm' && order_detail.includeThirdPlace=='Yes'" @click.stop="arriveClick()">确认到达收箱地</div> -->
        <!-- 评价 -->
        <div class="evaluate" v-if="waybill_detail.orderState=='Merchant_Confirm'">
          <div class="line"></div>
          <div class="evaluate-list">
            <div class="evaluate-item" v-if="waybill_detail.merchantEvalState">
              <div class="title">
                <div class="left">
                  <img src="@/assets/evla.png" alt="">
                  <div>司机给我的评价</div>
                </div>
                <div class="right" v-if="driver_evaluate.evalScore">
                  <img src="@/assets/star.png" alt="">
                  <div>{{driver_evaluate.evalScore.toFixed(1)}}</div>
                </div>
              </div>
              <div class="text">{{driver_evaluate.evalContent}}</div>
            </div>
            <div v-else></div>
            <div class="evaluate-item" v-if="waybill_detail.driverEvalState">
              <div class="title">
                <div class="left">
                  <img src="@/assets/evla.png" alt="">
                  <div>我给司机的评价</div>
                </div>
                <div class="right" v-if="my_evaluate.evalScore">
                  <img src="@/assets/star.png" alt="">
                  <div>{{my_evaluate.evalScore?my_evaluate.evalScore.toFixed(1):''}}</div>
                </div>
              </div>
              <div class="text">{{my_evaluate.evalContent}}</div>
            </div>
          </div>
        </div>
        <!-- 撤单 -->
        <div class="cancel-order" v-if="waybill_detail.orderState=='Undo'">
          <div class="line"></div>
          <div class="cancel-order-content">
            <div class="compensate">
              <div class="label">撤单补偿</div>
              <div class="text">{{waybill_detail.undoCost?waybill_detail.undoCost+'元':'无'}}</div>
            </div>
            <div class="">
              <div class="label">撤单原因</div>
              <div class="text">{{waybill_detail.undoMsg||'未填写'}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 进度 -->
      <div class="item speed">
        <!-- 进度 -->
        <div class="progress">
          <div class="progress-state">
            <div class="black" v-if="waybill_detail.orderState=='Wait' || waybill_detail.orderState=='Accept' || waybill_detail.orderState=='Undo' || waybill_detail.orderState=='Refuse'|| !waybill_detail.orderState">提箱</div>
            <div class="green" v-else-if="waybill_detail.orderState">
              <img src="@/assets/green.png" alt="">
              <div>提箱</div>
            </div>
          </div>
          <img class="dot" src="@/assets/dot.png" alt="">
          <div class="progress-state">
            <div class="green" style="width:90px" v-if="waybill_detail.orderState&&(waybill_detail.orderState=='Second_Place_Confirm' || waybill_detail.orderState=='Third_Place_Confirm' || waybill_detail.orderState=='Merchant_Confirm')">
              <img src="@/assets/green.png" alt="">
              <div>送货/装货</div>
            </div>
            <div class="black" style="width:62px" v-else>送货/装货</div>
          </div>
          <img class="dot" src="@/assets/dot.png" v-if="order_detail.includeThirdPlace=='Yes'" alt="">
          <div class="progress-state" v-if="order_detail.includeThirdPlace=='Yes'">
            <div class="green" v-if="waybill_detail.orderState&&(waybill_detail.orderState=='Third_Place_Confirm'||waybill_detail.orderState=='Merchant_Confirm')">
              <img src="@/assets/green.png" alt="">
              <div>收箱</div>
            </div>
            <div class="black" v-else>收箱</div>
          </div>
        </div>
        <!-- 信息 -->
        <div class="item-content">
          <!-- 信息 -->
          <div class="left">
            <div class="info-item">
              <div class="name">{{order_detail.firstPlaceDetail}}</div>
              <div class="address">{{order_detail.firstPlaceProvince}} {{order_detail.firstPlaceCity}} {{order_detail.firstPlaceDistrict}}</div>
              <div class="date">
                <div class="label">提箱有效时间段：</div>
                <div>{{order_detail.firstPlaceBeginTime | timereplace}} — {{order_detail.firstPlaceEndTime | timereplace}}</div>
              </div>
            </div>
            <div class="info-item">
              <div class="name">{{order_detail.secondPlaceDetail}}</div>
              <div class="address">{{order_detail.secondPlaceProvince}} {{order_detail.secondPlaceCity}} {{order_detail.secondPlaceDistrict}}</div>
              <div class="date">
                <div class="label">送货/装货有效时间段：</div>
                <div>{{order_detail.secondPlaceBeginTime | timereplace}} — {{order_detail.secondPlaceEndTime | timereplace}}</div>
              </div>
            </div>
            <div class="info-item" v-if="order_detail.includeThirdPlace=='Yes'">
              <div class="name">{{order_detail.thirdPlaceDetail}}</div>
              <div class="address">{{order_detail.thirdPlaceProvince}} {{order_detail.thirdPlaceCity}} {{order_detail.thirdPlaceDistrict}}</div>
              <div class="date">
                <div class="label">到收箱地有效时间段：</div>
                <div>{{order_detail.thirdPlaceBeginTime | timereplace}} — {{order_detail.thirdPlaceEndTime | timereplace}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 当前接单司机 -->
      <div class="item driver" v-if="driver_info">
        <div class="driver-top">
          <div>
            <div class="item-name">当前接单司机</div>
            <div class="option">
              <div class="tag" v-if="driver_info.giveUpAmountForOneMonth">该车在30天内弃单{{driver_info.giveUpAmountForOneMonth}}次，请注意履约风险</div>
              <img class="avatar" :src="driver_info.headImage" alt="" v-if="driver_info.headImage">
              <img class="avatar" src='@/assets/avatar.png' alt="">
              <div>
                <div class="name">{{driver_info.name}} {{driver_info.contactPhone}}</div>
                <div class="team">{{driver_info.carTeamName}} · {{driver_info.carNumber}}</div>
                <div class="evaluate">
                  <div class="evaluate-left">
                    <img src="@/assets/star-1.png" alt="">
                    <div>{{driver_info.evalAmount?(driver_info.evalScore/driver_info.evalAmount).toFixed(1):0}} ·{{driver_info.evalAmount||0}}条评价</div>
                  </div>
                  <div class="evaluate-right" @click="driverevalClick(driver_info)">查看评价</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="waybill_detail.transferState=='Accept'||waybill_detail.transferState=='Wait'">
            <div class="item-name" v-if="waybill_detail.transferState=='Wait'">转单目标司机</div>
            <div class="item-name" v-if="waybill_detail.transferState=='Accept'">前任接单司机</div>
            <div class="option">
              <div class="tag" v-if="transfer_info.giveUpAmountForOneMonth">该车在30天内弃单{{transfer_info.giveUpAmountForOneMonth}}次，请注意履约风险</div>
              <img class="avatar" :src="transfer_info.headImage" v-if="transfer_info.headImage" alt="">
              <img class="avatar" src='@/assets/avatar.png' v-else alt="">
              <div>
                <div class="name">{{transfer_info.name}} {{transfer_info.contactPhone}}</div>
                <div class="team">{{transfer_info.carTeamName}} · {{transfer_info.carNumber}}</div>
                <div class="evaluate">
                  <div class="evaluate-left">
                    <img src="@/assets/star-1.png" alt="">
                    <div>{{transfer_info.evalAmount?(transfer_info.evalScore/transfer_info.evalAmount).toFixed(2):0}} ·{{transfer_info.evalAmount}}条评价</div>
                  </div>
                  <div class="evaluate-right" @click="driverevalClick(transfer_info)">查看评价</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="driver-bottom">
          <div>
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.createTime | timestamp}}</div>
              <div class="text">接单</div>
            </div>
          </div>
          <div v-if="waybill_detail.undoTime">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.undoTime | timereplace}}</div>
              <div class="text">撤单</div>
            </div>
          </div>
          <!-- <div v-if="waybill_detail.giveUpTime">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.giveUpTime | timereplace}}</div>
              <div class="text">弃单</div>
            </div>
          </div> -->
          <div v-if="waybill_detail.firstPlaceConfirmTime">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.firstPlaceConfirmTime | timereplace}}</div>
              <div class="text">提箱</div>
            </div>
          </div>
          <div v-if="waybill_detail.transferApplyTime">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.transferApplyTime | timereplace}}</div>
              <div class="text">申请转单</div>
            </div>
          </div>
          <div v-if="waybill_detail.transferAcceptTime">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.transferAcceptTime | timereplace}}</div>
              <div class="text" v-if="waybill_detail.transferState=='Accept'">转单成功</div>
              <div class="text" v-if="waybill_detail.transferState=='Reject'">转单失败</div>
            </div>
          </div>
          <div v-if="waybill_detail.secondPlaceArriveTime">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.secondPlaceArriveTime | timereplace}}</div>
              <div class="text">到达装卸地</div>
            </div>
          </div>
          <div v-if="waybill_detail.secondPlaceConfirmTime">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.secondPlaceConfirmTime | timereplace}}</div>
              <div class="text">送货/装货</div>
            </div>
          </div>
          <div v-if="waybill_detail.thirdPlaceConfirmTime	">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.thirdPlaceConfirmTime	 | timereplace}}</div>
              <div class="text">到达收箱地</div>
            </div>
          </div>
          <div v-if="waybill_detail.containerFinishTime	">
            <img src="@/assets/grey.png" alt="">
            <div>
              <div>{{waybill_detail.containerFinishTime	 | timereplace}}</div>
              <div class="text">返箱回提箱地</div>
            </div>
          </div>
        </div>
      </div>

      <div class="details">
        <!-- 基本信息 -->
        <div class="details-item">
          <div class="name">基本信息</div>
          <div class="base">
            <div class="base-item">
              <div class="label">下单方电话</div>
              <div class="value">{{order_detail.merchantContactPhone}}</div>
            </div>
            <div class="base-item">
              <div class="label">提（运）单号</div>
              <div class="value">{{order_detail.extractIdentity}}</div>
            </div>
            <div class="base-item">
              <div class="label">船公司</div>
              <div class="value" v-if="ship_info">
                <div>{{ship_info.code}} {{ ship_info.name }}</div>
                <div class="tips" v-if="ship_info.remark">{{ship_info.remark}}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 箱子信息 -->
        <div class="details-item">
          <div class="name">箱子信息</div>
          <div class="table">
            <div>
              <div class="th">尺寸</div>
              <div class="td">{{containerSize}}</div>
            </div>
            <div>
              <div class="th">箱型要求</div>
              <div class="td">{{containerShape}}</div>
            </div>
            <div>
              <div class="th">箱子数量</div>
              <div class="td" v-if="order_detail.deliveryMode=='Single'">1个</div>
              <div class="td" v-if="order_detail.deliveryMode=='Double'">2个</div>
              <div class="td" v-if="order_detail.deliveryMode=='More'">{{waybill_detail.containerAmount}}个</div>
            </div>
            <div>
              <div class="th">空/重要求</div>
              <div class="td" v-if="order_detail.containerEmptyOrFull=='Empty'">空箱</div>
              <div class="td" v-if="order_detail.containerEmptyOrFull=='Full'">重箱</div>
            </div>
            <div v-if="order_detail.containerWeight">
              <div class="th">箱子重量区间</div>
              <div class="td">{{order_detail.containerWeight}}</div>
            </div>
            <div>
              <div class="th">配送形式</div>
              <div class="td" v-if="order_detail.deliveryMode=='Single'">单背</div>
              <div class="td" v-if="order_detail.deliveryMode=='Double'">双背</div>
              <div class="td" v-if="order_detail.deliveryMode=='More'">多背</div>
            </div>
            <div>
              <div class="th">是否无货回空</div>
              <div class="td">{{ waybill_detail.outStock=='Yes'?'是':'否' || '' }}</div>
            </div>
          </div>
          <div class="boxs" v-if="waybill_detail.containerSealNums">
            <div class="box" v-for="(item,index) in JSON.parse(waybill_detail.containerSealNums)" :key="index">
              <div class="img">
                <div class="bg">
                  <img src="@/assets/box-1.png" alt="">
                </div>
                <div class="num">{{index+1}}</div>
              </div>
              <div class="info">
                <div class="label">箱号</div>
                <div class="value">{{item.identity}}</div>
              </div>
              <div class="info" v-if="item.sealIdentity">
                <div class="label">铅封号</div>
                <div class="value">{{item.sealIdentity}}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 配送信息 -->
        <div class="details-item">
          <div class="name">配送信息</div>
          <div class="delivery">
            <div v-if="order_detail.specifyCarTeams=='Yes'">
              <span>指定车队</span>
              <div v-for="(item,index) in carTeams" :key="index">{{item.carTeamName}}、</div>
            </div>
            <div v-if="order_detail.specifyCars=='Yes'">
              <span>指定车辆</span>
              <div v-for="(item,index) in cars" :key="index">{{item.carNumber}}、</div>
            </div>
            <div>
              <span>车况要求</span>
              {{order_detail.carConditionDemand || '选填'}}
            </div>
            <div>
              <span>配送要求</span>
              {{order_detail.carDeliveryDemand || '选填'}}
            </div>
            <div style="margin-bottom:0">
              <span>备注</span>
              {{order_detail.remark || '选填'}}
            </div>
          </div>
        </div>
        

        <!-- 运费信息 -->
        <div class="details-item">
          <div class="name">运费信息</div>
          <div class="base">
            <div class="base-item">
              <div class="label">本单运费</div>
              <div class="value">{{(waybill_detail.freightTotalCost||0)}}元</div>
            </div>
            <div class="base-item">
              <div class="label">发票</div>
              <div class="value" v-if="order_detail.provideInvoice=='Yes'">提供发票</div>
              <div class="value" v-if="order_detail.provideInvoice=='No'">不提供发票</div>
            </div>
          </div>
        </div>
        <div class="details-item">
          <div class="name">节点信息</div>
          <div class="base">
            <div class="base-item">
              <div class="label">提箱垫付费</div>
              <div class="value">{{(waybill_detail.firstPlaceFee||0)}}元</div>
            </div>
            <div class="base-item">
              <div class="label">提箱图片</div>
              <div class="value" v-if="waybill_detail.firstPlaceImage">
                <div v-for="(img,index) in JSON.parse(waybill_detail.firstPlaceImage)" :key="index">
                  <img class="point-img" :src="img" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="base">
            <div class="base-item">
              <div class="label">卸/送垫付费</div>
              <div class="value">{{(waybill_detail.secondPlaceFee||0)}}元</div>
            </div>
            <div class="base-item">
              <div class="label">卸/送图片</div>
              <div class="value" v-if="waybill_detail.secondPlaceImage">
                <div v-for="(img,index) in JSON.parse(waybill_detail.secondPlaceImage)" :key="index">
                  <img class="point-img" :src="img" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="base">
            <div class="base-item">
              <div class="label">收箱垫付费</div>
              <div class="value">{{(waybill_detail.thirdPlaceFee||0)}}元</div>
            </div>
            <div class="base-item">
              <div class="label">收箱图片</div>
              <div class="value" v-if="waybill_detail.thirdPlaceImage">
                <div v-for="(img,index) in JSON.parse(waybill_detail.thirdPlaceImage)" :key="index">
                  <img class="point-img" :src="img" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="base">
            <div class="base-item">
              <div class="label">磅单照片</div>
              <div class="value" v-if="waybill_detail.weighImage">
                <div v-for="(img,index) in JSON.parse(waybill_detail.weighImage)" :key="index">
                  <img class="point-img" :src="img" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="base">
            <div class="base-item">
              <div class="label">单据照片</div>
              <div class="value" v-if="waybill_detail.billsImage">
                <div v-for="(img,index) in JSON.parse(waybill_detail.billsImage)" :key="index">
                  <img class="point-img" :src="img" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>

    <!-- 确认送货/装货/确认到达收箱地弹窗 -->
    <div class="mask" v-if="confirm_show">
      <div class="pop">
        <div class="pop-title">
          <div>{{title}}</div>
          <img src="@/assets/close.png" alt="">
        </div>
        <div class="item" v-for="(item,index) in waybill_detail.containerSealNums?JSON.parse(waybill_detail.containerSealNums):[]" :key="index">
          <div class="left">箱{{index+1}}</div>
          <div class="right">
            <div>
              <div class="label">箱号：</div>
              <div class="value">{{item.identity}}</div>
            </div>
            <div v-if="item.sealIdentity">
              <div class="label">铅封号：</div>
              <div class="value">{{item.sealIdentity}}</div>
            </div>
          </div>
        </div>
        <div class="btns">
          <div class="cancel" @click="confirm_show=false">取消</div>
          <div class="confirm" @click="confirmClick">确定</div>
        </div>
      </div>
    </div>

    <!-- 评价司机弹窗 -->
    <div class="mask" v-if="evaluate_show">
      <div class="pop driver-pop">
        <div class="pop-title">
          <div>评价司机</div>
          <img @click="evaluate_show=false" src="@/assets/close.png" alt="">
        </div>
        <div class="form-item">
          <div class="label"><span>*</span>评分</div>
          <div class="stars">
            <!-- <img v-for="(item,index) in 5" :key="index" src="" alt=""> -->
            <el-rate v-model="evaluate_params.evalScore" allow-half />
          </div>
        </div>
        <div class="form-item">
          <div class="label"><span>*</span>评价</div>
          <textarea v-model="evaluate_params.evalContent" name="" id="" cols="30" rows="10"></textarea>
        </div>
        <div class="btns">
          <div class="cancel" @click="evaluate_show=false">取消</div>
          <div class="confirm" @click="evaluateConfirm">确定</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'order_detail',
  components:{Header},
  data(){
    return{
      waybill_detail:{},//运单详情
      order_detail:{},//运订单详情
      driver_info:{},//当前司机信息
      transfer_info:{},//转单司机信息 || 转单目标司机
      ship_info:{},//船公司信息
      containerSize:'',
      containerShape:'',
      my_evaluate:{},//我给司机的评价
      driver_evaluate:{},//司机给我的评价
      evaluate_show:false,
      evaluate_params:{
        "driverId": '',
        "headImage": "",
        "userId": '',
        "userNickname": "",
        "evalContent": "",
        "evalScore": '',
      },
      confirm_show:false,
      title:'',
      merchant:{},
      carTeams:[],
      cars:[]
    }
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
        vm.id = to.query.id || ''
        vm.waybillId = to.query.waybillId || ''
        vm.merchant = localStorage.getItem('merchant')?JSON.parse(localStorage.getItem('merchant')):{}
        // console.log('商户信息',JSON.parse(localStorage.getItem('merchant')))
        
        vm.getWaybillDetail()
        vm.getOrderDetail()
    })
  },
  watch:{
    evaluate_show(to){
      if(!to){
        this.evaluate_params = {
          "driverId": '',
          "headImage": "",
          "userId": '',
          "userNickname": "",
          "evalContent": "",
          "evalScore": '',
        }
      }
    }
  },
  methods:{
    // 点击确认送货/装货
    async unloadingClick(){
      this.title = '确认送货/装货'
      this.confirm_show = true
    },
    // 点击确认抵达
    async arriveClick(){
      this.title = '确认抵达收箱地'
      this.confirm_show = true
    },
    async confirmClick(){
      let res = await this.$req({
        url:`/waybillOrder/confimEnd/${this.waybill_detail.id}`,
        method:'POST'
      })
      if(res.code==200){
        this.confirm_show = false
        this.getWaybillDetail()
        this.$message({
          type:'success',
          message:'确认成功！'
          , duration:1000
        })
      }else{
        this.$message({
          message:res.msg
          , duration:1000
        })
      }
    },
    // 点击评价司机
    evaluateClick(){
      let merchant = JSON.parse(localStorage.getItem('merchant'))
      if(!merchant.nickname||!merchant.headImage){
        this.$message({
          message:'上传头像并填写昵称后才能评价'
          , duration:1000
        })
        return
      }
      this.evaluate_show = true
    },
    async evaluateConfirm(){
      if(!this.evaluate_params.evalScore){
        this.$message({
          message:'请选择评价分数'
          , duration:1000
        })
        return
      }else if(!this.evaluate_params.evalContent){
        this.$message({
          message:'请输入评价内容'
          , duration:1000
        })
        return
      }
      this.evaluate_params.driverId = this.waybill_detail.driverId
      this.evaluate_params.userId = this.waybill_detail.driverUserId
      this.evaluate_params.userNickname = JSON.parse(localStorage.getItem('merchant')).nickname
      this.evaluate_params.headImage = JSON.parse(localStorage.getItem('merchant')).headImage
      
      console.log('this.evaluate_params',this.evaluate_params,JSON.parse(localStorage.getItem('merchant')))
      let res = await this.$req({
        url:`/driverEval/addByMerchant/${this.waybill_detail.id}`,
        method:'POST',
        data:this.evaluate_params
      })
      if(res.code==200){
        this.getWaybillDetail()
        this.$message({
          message:'评价成功',
          type:'success'
          , duration:1000
        })
        this.evaluate_show = false
      }else{
        this.$message({
          message:res.msg
          , duration:1000
        })
      }
    },
    // 获取我给司机的评价
    async getMyEval(){
      let res = await this.$req({
        url:`/driverEval/${this.waybill_detail.driverEvalId}`,
      })
      if(res.code==200){
        this.my_evaluate = res.data
      }
    },
    // 获取司机给我的评价
    async getDriverEval(){
      let res = await this.$req({
        url:`/merchantEval/${this.waybill_detail.merchantEvalId}`,
      })
      if(res.code==200){
        this.driver_evaluate = res.data
      }
    },
    // 获取尺寸名称
    async getShape(code){
      let res = await this.$req({
        url:`/dictionary/cache/${code}/name`,
        method:'GET',
        api:'/mng'
      })
      if(res.code==200){
        this.containerShape = res.data
      }
    },
    // 获取尺寸名称
    async getSize(code){
      let res = await this.$req({
        url:`/dictionary/cache/${code}/name`,
        method:'GET',
        api:'/mng'
      })
      if(res.code==200){
        this.containerSize = res.data
      }
    },
    // 获取船公司详情
    async getShip(id){
      let res = await this.$req({
        url:`/shipCompany/${id}`,
        method:'GET'
      })
      if(res.code==200){
        this.ship_info = res.data
      }
    },
    // 跳转查看司机评价
    driverevalClick(data){
      console.log('driverevalClick',data)
      this.$router.push({path:'driver_evaluation',query:{info:JSON.stringify(data)}})
    },
    // 获取车队名
    async getCarTeam(id){
      let res = await this.$req({
        url:`/carTeam/${id}`,
        method:'GET'
      })
      if(res.code==200){
        return res.data.name
      }
    },
    // 获取转单司机信息
    async getTransfer(id){
      let res = await this.$req({
        url:`/driver/detailByStats/${id}`,
        method:'GET'
      })
      if(res.code==200){
        res.data.carTeamName = await this.getCarTeam(res.data.carTeam)
        this.transfer_info = res.data
      }
    },
    // 获取司机信息
    async getDriver(id){
      let res = await this.$req({
        url:`/driver/detailByStats/${id}`,
        method:'GET'
      })
      if(res.code==200){
        this.driver_info = res.data || ''
        let carTeamName = await this.getCarTeam(res.data.carTeam)
        this.$set(this.driver_info,'carTeamName',carTeamName)
      }
    },
    // 获取订单详情
    async getOrderDetail(){
      let res = await this.$req({
        url:`/waybill/${this.waybillId}`,
        method:'GET'
      })
      if(res.code==200){
        this.order_detail = res.data || {}
        await this.getShip(res.data.shipCompanyId)
        await this.getShape(res.data.containerShape)
        await this.getSize(res.data.containerSize)
        if(this.order_detail.specifyCarTeams=='Yes'){
          this.getSpecifyCarTeams()
        }
        if(this.order_detail.specifyCars=='Yes'){
          this.getSpecifyCars()
        }
        
      }
    },
    // 获取订单指定车队
    async getSpecifyCarTeams(){
      let res = await this.$req({
        url:`/waybillCarTeams/queryAllJoinCarTeam`,
        method:'GET',
        data:{waybillId:this.order_detail.id}
      })
      if(res.code==200){
        this.carTeams = res.data
      }
    },
    // 获取订单指定车辆
    async getSpecifyCars(){
      let res = await this.$req({
        url:`/waybillCars/queryAll`,
        method:'GET',
        data:{waybillId:this.order_detail.id}
      })
      if(res.code==200){
        this.cars = res.data
      }
    },
    // 获取运单详情
    async getWaybillDetail(){
      let res = await this.$req({
        url:`/waybillOrder/${this.id}`,
        method:'GET'
      })
      if(res.code==200){
        this.waybill_detail = res.data || {}
        // 获取当前司机
        await this.getDriver(res.data.driverId)
        // 获取转单司机
        if(res.data.transferState=='Wait'){
          await this.getTransfer(res.data.transferDriverId)
        }
        if(res.data.transferState=='Accept'){
          await this.getTransfer(res.data.transferDriverId)
        }
        // 获取司机给我的评价
        if(res.data.driverEvalState){
          this.getMyEval()
        }
        // 获取我给司机的评价
        if(res.data.merchantEvalState){
          this.getDriverEval()
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  &>.content
    width 1200px
    background-color: transparent
    padding: 0
    display: block
    margin-bottom: 253px
    &>.item
      width 100%
      background-color: #fff
      margin-top: 10px
      padding: 20px
    &>.state-item
      .state
        line-height: 28px;
        color: rgba(1, 93, 231, 100);
        font-size: 20px;
        margin-bottom: 5px
        display: flex
        align-items: center
        .transfer-state
          padding: 0 6px
          height 20px
          background: #FF9100;
          border-radius: 2px;
          margin-left: 10px
          font-size: 12px
          text-align: center
          line-height: 20px
          color: #fff
      .red-state
        color: #F54A45
      .give-up
        color: #8F959E
        font-size: 12px
        display flex
        align-items: baseline
        margin-left: 20px
        .giveup-money
          color #FC6901
          margin-left: 6px
          display flex
          align-items: baseline
          span
            font-size: 18px
            margin-right 2px
      .date
        display: flex
        color: #8F959E
        font-size: 12px
        line-height: 20px
        div
          margin-right: 40px
      .btn
        padding: 7px 14px
        display: inline-block
        background: #015DE7;
        border-radius: 4px;
        font-size: 14px
        text-align: center
        margin-top: 20px
        color: #fff
        cursor pointer
      // 评价
      .evaluate
        padding: 20px 0 0
        .line
          width 1200px
          height 1px
          background: #F0F2F5
          transform: translateX(-20px)
          margin-bottom: 20px
        .evaluate-list
          display: flex
          justify-content: space-between
          .evaluate-item
            width 371px
            .title
              display: flex
              justify-content: space-between
              margin-bottom: 8px
              color: #2C2E32
              font-size: 16px
              line-height: 24px
              div
                display: flex
                align-items: center
              .left
                font-size: 14px
                line-height: 22px
                color: #1F2329
                img
                  width 16px
                  height: 16px
                  margin-right: 6px
              .right
                font-weight: bold
                img
                  width 16px
                  height: 16px
                  margin-right: 10px
            .text
              color: #646A73
              font-size: 14px
              line-height: 22px
              padding-left: 22px
      // 撤单
      .cancel-order
        padding: 20px 0 0
        .line
          width 1200px
          height 1px
          background: #F0F2F5
          transform: translateX(-20px)
          margin-bottom: 20px
        .cancel-order-content
          display: flex
          &>div 
            font-size: 14px
            line-height: 22px
            .label
              color: #1F2329
            .text
              color: #6F757E
          .compensate
            width 160px
    &>.speed
      // 进度
      .progress
        display: flex
        align-items: center
        margin-bottom: 10px
        .progress-state
          width 92px
          .black
            width: 32px;
            height: 20px;
            border-radius: 2px;
            background-color: rgba(38, 44, 52, 1);
            color: #fff
            line-height: 20px
            text-align: center
            font-size: 12px
          .green
            display: flex
            align-items: center
            font-size: 14px
            color #00A045
            img 
              width 16px
              height 16px
              margin-right: 6px
        .dot
          height 4px
          margin-right: 44px
          width 214px
      .item-content
        display: flex
        justify-content: space-between
        &>.left
          display: flex
          .info-item
            width 350px
            color #646A73
            .name
              color: #22272E
              font-size: 16px
              line-height: 24px
              margin-bottom: 2px
            .address
              font-size: 14px
              line-height: 22px
              margin-bottom: 12px
            .date
              display: block
              font-size: 12px
              line-height: 20px
              .label
                color: #8F959E
            .info-item-bottom
              display: flex
              align-items: center
              font-size: 12px
              line-height: 22px
              img
                width 22px
                height 22px
                margin-right: 10px
              .label
                color: #8F959E
    // 当前接单司机
    &>.driver
      color: #1F2329;
      .driver-top
        display: flex
        &>div
          margin-right: 20px
        .item-name
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px
        .option
          width: 373px;
          height: 115px;
          background: #F4F5F9;
          border-radius: 4px;
          display: flex
          padding: 12px
          position relative
          .tag
            position: absolute
            width: 236px;
            height: 20px;
            background: #FEEAD2;
            color: #FF8800
            font-size: 12px
            line-height: 20px
            text-align: center
            top -20px
            right: 0
          .avatar
            width: 60px;
            height: 60px;
            border-radius: 4px;
            margin-right: 12px
          &>div
            width calc(100% - 72px)
            .name
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 8px
            .team
              font-size: 14px;
              color: #015DE7;
              line-height: 22px;
              margin-bottom: 9px
            .evaluate
              display: flex
              justify-content: space-between
              align-items: center
              .evaluate-left
                display: flex
                align-items: center
                font-size: 14px
                line-height: 22px
                img
                  width 16px
                  height 16px
                  margin-right: 6px
              .evaluate-right
                width: 72px;
                height: 28px;
                background: #FFFFFF;
                border-radius: 18px;
                border: 1px solid #D0D3D6;
                color: #22272E
                font-size: 12px
                line-height: 26px
                text-align: center
                cursor pointer
      .line
        height: 1px;
        background: #F0F2F5;
        width 1200px
        margin-top: 20px
        transform: translateX(-20px)
      .driver-bottom
        display: flex
        width: 100%
        overflow-x: auto
        padding-top: 20px
        &>div
          display: flex
          font-size: 14px
          line-height: 22px
          margin-right: 70px
          img
            width 14px
            height 14px
            margin-right: 10px
            margin-top: 3px
          .text
            color: #6F757E

    .details
      background: #fff
      padding: 0 20px
      margin-top: 10px
      color: #1F2329;
      .details-item
        padding: 20px 0 14px
        .name
          font-size: 16px
          line-height: 24px
          margin-bottom: 27px
        // 基本信息
        .base
          display: flex
          .base-item
            width 373px
            display: flex
            font-size: 14px
            line-height: 22px
            .label
              width 98px
              color: #6F757E
              margin-right: 20px
              text-align: right
            .tips
              width: 255px;
              background: #FFFFE5;
              border-radius: 4px;
              border: 1px solid #FFCC7F;
              padding: 4px 12px
              color: #585E60
              font-size: 12px
              line-height: 18px
              margin-top: 7px
        // 箱子信息
        .table
          display: flex
          border-left: 1px solid #D0D3D6
          border-top: 1px solid #D0D3D6
          &>div
            flex: 1
            div
              height 60px
              line-height: 60px
              text-align: center
              font-size: 14px
            .th
              background: #F4F5F9
              border-right: 1px solid #D0D3D6
              border-bottom: 1px solid #D0D3D6
              color: #6F757E
            .td
              color: #22272E
              border-right: 1px solid #D0D3D6
              border-bottom: 1px solid #D0D3D6
        .boxs
          display: flex
          padding: 40px 0 16px
          flex-wrap: wrap
          .box
            display: flex
            margin-right: 55px
            margin-bottom: 10px
            .img
              position relative
              .bg
                width: 74px;
                height: 74px;
                background: #F4F5F9;
                border-radius: 50%
                display: flex
                align-items: center
                justify-content: center
                img
                  width 36px
                  height 36px
              .num
                width: 24px;
                height: 24px;
                background: #22272E;
                font-size: 14px
                line-height: 24px
                font-weight: 600
                text-align: center
                position: absolute
                color: #fff
                left: 0
                top 0
                border-radius: 50%
            .info
              height 74px
              display: flex
              flex-direction: column
              justify-content: center
              font-size: 14px
              line-height: 22px
              margin-left: 20px
              .label
                color: #6F757E
                margin-bottom: 10px
        // 配送信息
        .delivery
          div
            font-size: 14px
            line-height: 22px
            display: flex
            margin-bottom: 14px
            span
              width 118px
              text-align: right
              color: #6F757E
              padding-right: 20px
        .point-img
          max-width:200px
          max-height:200px


// 确认送货/装货/确认到达收箱地弹窗
.mask
  width 100vw
  height: 100vh
  position: fixed
  top 0
  left: 0
  z-index: 999
  background: rgba(0, 0, 0, .6)
  display: flex
  align-items: center
  justify-content: center
  .pop
    width: 600px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 22px 24px
    color #22272E
    .pop-title
      display: flex
      justify-content: space-between
      align-items: center
      font-size: 24px
      margin-bottom: 24px
      img
        width 28px
        height 28px
        transform: translateX(14px)
        cursor pointer
    .item
      display: flex
      height: 78px;
      background: #F4F5F9;
      margin-bottom: 10px
      &>div
        display: flex
        flex-direction: column
        justify-content: center
      .left
        width: 72px;
        height: 78px;
        align-items: center
        background: #6F757E;
        color: #fff
      .right
        &>div
          color #101D37
          font-size: 16px
          line-height: 24px
          margin-bottom: 6px
          display: flex
          .label
            width 94px
            padding-right: 12px
            text-align: right
    .form-item
      margin-bottom: 20px
      .label
        display: flex
        font-size: 14px
        line-height: 22px
        margin-bottom: 8px
        span
          color: #F54A45
          margin-right: 4px
      .stars
        display: flex
        img
          width 36px
          height 36px
          margin-right: 12px
      textarea
        height: 144px;
        width 100%
        padding: 10px
        outline: none
        border: 1px solid #D0D3D6;

    .btns
      display: flex
      justify-content: flex-end
      margin-top: 24px
      div
        width: 80px;
        height: 32px;
        border-radius: 4px;        
        line-height: 30px
        text-align: center
        font-size: 14px
        margin-left: 12px
        cursor pointer
      .cancel
        border: 1px solid #DDDEDE;
        color: #1F2329
      .confirm
        color: #fff
        background: #015DE7
        border: 1px solid #015DE7;
</style>
