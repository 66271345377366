<template>

    <el-dialog title="提示" :visible.sync="identityVisible" width="60%">
        <div class="content">
            <el-form>
                <el-form-item label="身份" required>
                    <el-checkbox-group v-model="paramsFrom.powerArray" size="small">
                        <el-checkbox label="sendOrder" border size="medium">派单方</el-checkbox>
                        <el-checkbox label="takOrder" border size="medium">接单方</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <template v-if="paramsFrom.powerArray.includes('takOrder')">
                    <el-form-item label="车队名称">
                        <el-input v-model="paramsFrom.carTeamName" placeholder="请输入车队名称" />
                    </el-form-item>
                    <el-form-item label="车队负责人">
                        <el-input v-model="paramsFrom.carTeamLeader" placeholder="请输入车队负责人" />
                    </el-form-item>
                    <el-form-item label="车队负责人电话">
                        <el-input v-model="paramsFrom.carTeamLeaderPhone" placeholder="请输入车队负责人电话" />
                    </el-form-item>
                </template>
                <el-form-item>
                    <el-button type="primary" @click="submitOrderTak">保存
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</template>
<script>
import { Checkbox } from 'element-ui';

export default {
    name: "changeIdentity",
    data() {
        return {
            paramsFrom: {
                category: "mng_user_category_hd_motorcade",
                //权限
                powerArray: ['sendOrder'],
                //车队信息
                carTeamName: "",
                //车队负责人
                carTeamLeader: "",
                //车队负责人电话
                carTeamLeaderPhone: "",
            },

            identityVisible:false
        }
    },
    methods: {
        showInfo() {
            console.log("切换身份")

            this.identityVisible=true;

            this.$req({
                url: `/carTeam/getByEntity`,
                method: 'GET'
            }).then(res => {
                if (res.code == 200) {
                    let data = res.data;
                    this.paramsFrom.powerArray = data.power ? data.power.split(',') : ["sendOrder"];
                    this.paramsFrom.carTeamName = data.entity.name;
                    this.paramsFrom.carTeamLeader = data.entity.leader;
                    this.paramsFrom.carTeamLeaderPhone = data.entity.leaderPhone;
                }
                console.log(res, "res")
            })

        },
        submitOrderTak() {
            if (this.paramsFrom.powerArray.length < 1) {
                this.$message({ message: '请选择身份', duration: 1000 });
                return
            }
            if (this.paramsFrom.powerArray.includes('takOrder')) {
                if (!this.paramsFrom.carTeamName) {
                    this.$message({ message: '请输入车队名称', duration: 1000 });
                    return
                } else if (!this.paramsFrom.carTeamLeader) {
                    this.$message({ message: '请输入车队联系人', duration: 1000 });
                    return
                } else if (!this.paramsFrom.carTeamLeaderPhone) {
                    this.$message({ message: '请输入车队联系电话', duration: 1000 });
                    return
                }
            }

            this.$req({
                url: `/carTeam/updateEntity`,
                method: 'POST',
                data: { ...this.paramsFrom, power: this.paramsFrom.powerArray.join(",") }
            }).then(res => {
                if (res.code == 200) {
                    this.identityVisible=false;
                    this.$message({ message: '提交成功,等待审核', duration: 1000 });
                }else{
                    this.$message({ message: '提交失败，请联系管理员', duration: 1000 });
                }
            })
        }
    }
}
</script>