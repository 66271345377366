<template>
<!-- 注册账号 -->
  <div class="container">
    <!-- 页面头部 -->
    <!-- <Header :show="false"></Header> -->
    <div class="head">
        <img class="logo" src="@/assets/logo1.png" alt="">
    </div>
    <div class="title">注册账号</div>
    <!-- 页面内容 -->
    <div class="content">
      <div class="form-content">
          <div class="form">
              <div class="input">
                  <input v-model="params.phone" type="text" placeholder="请输入手机号">
              </div>
              <div class="input">
                  <input v-model="params.captcha" type="text" placeholder="请输入短信验证码">
                  <div class="code pointer" @click="getCode" v-if="!time">获取验证码</div>
                  <div class="code pointer grey" @click="getCode" v-else>{{time}}s后重新获取</div>
              </div>
              <div class="input">
                  <input v-model="params.password" v-if="!password_open" type="password" placeholder="请输入密码">
                  <input v-model="params.password" v-else type="text" placeholder="请输入密码">
                  <img class="pointer" v-if="!password_open" src="@/assets/biyan.png" @click="password_open=true">
                  <img class="pointer" v-else src="@/assets/zhengyan.png" @click="password_open=false">
              </div>
              <div class="input">
                  <input v-model="confirm_password" v-if="!confirm_show" type="password" placeholder="请确认密码">
                  <input v-model="confirm_password" v-else type="text" placeholder="请确认密码">
                  <img class="pointer" v-if="!confirm_show" src="@/assets/biyan.png" @click="confirm_show=!confirm_show">
                  <img class="pointer" v-else src="@/assets/zhengyan.png" @click="confirm_show=!confirm_show">
              </div>
              <el-checkbox v-model="agreement">阅读并同意 <span class="agreement">《注册协议》</span> <span class="agreement">《隐私政策协议》</span> </el-checkbox>
              <div class="btn pointer" @click="loginClick">注册</div>
              <div class="back pointer" @click="backClick">返回登录</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'register',
  data(){
    return{
      params:{
        "password": '',
        "captcha": '',
        "phone": '',
        "category": "mng_user_category_hd_merchant",
        appletFlag:"hongde"
      },
      confirm_password:'',
      time:0,
      agreement:false,
      password_open:false,
      confirm_show:false
    }
  },
  async mounted(){
    
  },
  methods:{
    // 点击返回登录
    backClick(){
      this.$router.back(-1)
    },
    // 点击登录
    async loginClick(){
      if(!this.params.phone){
        this.$message({message: '请输入手机号', duration:1000});
        return
      }else if(!this.params.captcha){
        this.$message({message: '请输入短信验证码', duration:1000});
        return
      }else if(!this.params.password){
        this.$message({message: '请输入密码', duration:1000});
        return
      }else if(this.params.password != this.confirm_password){
        this.$message({message: '请输入两次输入密码不符', duration:1000});
        return
      }else if(!this.agreement){
        this.$message({message: '请阅读并同意《注册协议》《隐私政策协议》', duration:1000});
        return
      }

      let res = await this.$req({
        url:`/user/register/phoneAndCaptcha`,
        api:'/mng',
        method:'POST',
        data:{...this.params,nickname:this.params.phone.slice(0,3)+'***'+this.params.phone.slice(7)}
      })
      if(res.code==200){
        this.$message({
          message: '注册成功',
          type: 'success'
          , duration:1000
        });
        this.$router.replace('login')
      }else{
        this.$message({
          message: res.msg, duration:1000
        });
      }
    },

    // 获取手机验证码
    async getCode(){
      if(!this.params.phone){
        this.$message({message: '请输入手机号', duration:1000});
        return
      }
      
      let res = await this.$req({
        url:`/common/smsRegister/${this.params.phone}`,
        api:'/mng',
        method:'POST'
      })
      if(res.code==200){
        this.time = 60
        this.timer = setInterval(() => {
          if(this.time > 0){
            this.time --
          }else{
            clearInterval(this.timer)
          }
        }, 1000);
        this.$message({
          message: '发送成功',
          type: 'success'
          , duration:1000
        });
      }
    },

    
  }
}
</script>

<style scoped lang="stylus">
.container
  height: 100vh
  width: 100vw
  overflow: hidden
  position relative
  padding-top: 0
  // 头部
  .head
    width 100%
    height: 70px;
    background: #015DE7;
    padding: 15px 18px
    position relative
    z-index 2
    .logo
        width 150px
        height 50px
  .form
    .input
      border-bottom: 1px solid #E6E8F2
      height 48px
      margin-bottom: 20px
      position relative
      input
        font-size: 16px
        width 100%
        border: none
        outline: none
        height: 100%
      .code,img
        color: #015DE7
        font-size: 16px;
        position: absolute
        right: 0
        top 50%
        transform: translateY(-50%)
      .grey
        color: rgba(16, 29, 55, .3);
      img
        width 16px
        height 16px
        padding: 16px
        box-sizing: content-box
        z-index: 999
    /deep/ .el-checkbox__label
      color: #9399A5
      font-size: 12px
      span
        color #015DE7
        cursor pointer
    .btn
      height: 44px;
      border-radius: 2px;
      background-color: rgba(1, 93, 231, 1);
      line-height: 44px
      text-align: center
      font-size: 16px
      color: #fff
      margin-top: 32px
    .back
      font-size: 12px
      line-height: 20px
      text-align: center
      margin-top: 20px
</style>
