<template>
<!-- 修改密码 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>

    <div class="title">修改密码</div>

    <!-- 页面内容 -->
    <div class="content">
      <div class="form-content">
          <div class="form">
              <div class="input">
                  <input v-model="params.oldPassword" v-if="!old_show" type="password" placeholder="请输入旧密码">
                  <input v-model="params.oldPassword" v-else type="text" placeholder="请输入旧密码">
                  <img v-if="old_show" @click="old_show=!old_show" src="@/assets/zhengyan.png" alt="">
                  <img v-else @click="old_show=!old_show" src="@/assets/biyan.png" alt="">
              </div>
              <div class="input">
                  <input v-model="params.newPassword" v-if="!new_show" type="password" placeholder="请输入新密码">
                  <input v-model="params.newPassword" v-else type="text" placeholder="请输入新密码">
                  <img v-if="new_show" @click="new_show=!new_show" src="@/assets/zhengyan.png" alt="">
                  <img v-else @click="new_show=!new_show" src="@/assets/biyan.png" alt="">
              </div>
              <div class="input">
                  <input v-model="confirm" v-if="!confirm_show" type="password" placeholder="请再次输入密码">
                  <input v-model="confirm" v-else type="text" placeholder="请再次输入密码">
                  <img v-if="confirm_show" @click="confirm_show=!confirm_show" src="@/assets/zhengyan.png" alt="">
                  <img v-else @click="confirm_show=!confirm_show" src="@/assets/biyan.png" alt="">
              </div>
              <div class="btn" @click="saveClick">保存</div>
          </div>
      </div>
    </div>
   
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
export default {
  name: 'change_password',
  components:{Header},
  data(){
    return{
      params:{
        "newPassword": "",
        "oldPassword": ""
      },
      confirm:'',
      old_show:false,
      new_show:false,
      confirm_show:false
    }
  },
  methods:{
    // 点击保存
    async saveClick(){
      if(!this.params.newPassword){
        this.$message({
          message:'请输入新密码'
          , duration:1000
        })
        return
      }else if(!this.params.oldPassword){
        this.$message({
          message:'请输入旧密码'
          , duration:1000
        })
        return
      }else if(this.params.newPassword!=this.confirm){
        this.$message({
          message:'两次密码输入不同'
          , duration:1000
        })
        return
      }
      let res = await this.$req({
        url:'/user/password',
        method:'POST',
        api:'/mng',
        data:this.params
      })
      if(res.code==200){
        this.$message({
          type:'success',
          message:'修改成功！'
          , duration:1000
        })
        localStorage.removeItem('token')
        this.$router.replace('/login')
      }else{
        this.$message({
          message:res.msg
          , duration:1000
        })
      }
    },
  }
}
</script>

<style scoped lang="stylus">
.container
  &>.content
    padding: 30px 0 164px
.form
  .input
    border-bottom: 1px solid #E6E8F2
    height 48px
    margin-bottom: 20px
    position relative
    input
      font-size: 16px
      width 100%
      border: none
      outline: none
      height: 100%
    .code,img
      color: rgba(16, 29, 55, .3);
      font-size: 16px;
      position: absolute
      right: 0
      top 50%
      transform: translateY(-50%)
    img
      width 16px
      height 16px
  .btn
    height: 44px;
    border-radius: 2px;
    background-color: rgba(1, 93, 231, 1);
    line-height: 44px
    text-align: center
    font-size: 16px
    color: #fff
    margin-top: 32px
</style>
