<template>
  <!-- 运单列表 -->
  <div class="container">
    <!-- 页面头部 -->
    <Header></Header>
    <div class="screen-content">
      <div class="search">
        <el-form label-position="left" label-width="80px" :model="searchForm" ref="searchForm">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="提单号" prop="extractIdentity">
                <el-input v-model="searchForm.extractIdentity" placeholder="搜索提单号" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="派单方" prop="merchantNickname">
                <el-input v-model="searchForm.merchantNickname" placeholder="搜索派单方名称" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话" prop="merchantPhone">
                <el-input v-model="searchForm.merchantPhone" placeholder="联系电话" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="订单地址" prop="firstPlaceCity">
                <el-input v-model="searchForm.firstPlaceCity" placeholder="运单地址" style="width: 250px;"
                  size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="作业地点" prop="firstPlaceDistrict">
                <el-input style="width:250px;" v-model="searchForm.firstPlaceDistrict" placeholder="请输入作业订单"
                  size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="配送形式" prop="waybillDeliveryMode">
                <el-select v-model="searchForm.waybillDeliveryMode" placeholder="请选择" clearable style="width: 250px;"
                  size="small">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="单背" value="Single"></el-option>
                  <el-option label="双背" value="Double"></el-option>
                  <el-option label="多背" value="More"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="开始时间">
                <el-date-picker v-model="searchForm.starDate" type="date" placeholder="选择接单开始时间" size="small"
                  value-format="yyyyMMdd" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="结束时间">
                <el-date-picker v-model="searchForm.endDate" type="date" placeholder="选择接单结束时间" size="small"
                  value-format="yyyyMMdd" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <div style="margin-top: 5px;margin-left: 120px;">
                <el-button type="primary" size="mini" @click="searchClick">筛选</el-button>
                <el-button type="primary" size="mini" @click="resetClick">重置</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <el-table :data="orderList" stripe border style="width: 100%; margin-bottom: 20px" row-key="id"
        v-loading="queryLoading" element-loading-text="正在努力加载中...">
        <el-table-column prop="extractIdentity" label="提单号" show-overflow-tooltip width="170" />
        <el-table-column label="操作" width="200">
          <template v-slot="scope">
            <el-button size="mini" type="warning" @click="infoDetail(scope.row)">详情</el-button>
            <el-button size="mini" type="danger" @click="orderTakHandel(scope.row)">接单</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="businessLicenseName" label="派单方" show-overflow-tooltip width="130" />
        <el-table-column prop="deliveryMode" label="形式" show-overflow-tooltip width="50">
          <template v-slot="scope">
            <div v-if="scope.row.deliveryMode == 'Double'" class="text-suggest">双背</div>
            <div v-if="scope.row.deliveryMode == 'Single'" class="text-style">单背</div>
            <div v-if="scope.row.deliveryMode == 'More'">多背</div>
          </template>
        </el-table-column>
        <el-table-column prop="secondPlaceBeginTime" label="送货/装货开始时间" width="160">
          <template v-slot="scope">
            <div>{{ dateFormat2Str(Math.trunc(scope.row.secondPlaceBeginTime / 100)) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="secondPlaceEndTime" label="送货/装货截至时间" width="160">
          <template v-slot="scope">
            <div>{{ dateFormat2Str(Math.trunc(scope.row.secondPlaceEndTime / 100)) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="车次" show-overflow-tooltip width="125">
          <template v-slot="scope">
            <div>{{ trainNumber(scope.row) }}</div>
          </template>
        </el-table-column>

        <el-table-column label="单箱货重" prop="goodsWeightMin" show-overflow-tooltip width="125">
          <template v-slot="scope">
            <div>{{ scope.row.goodsWeightMin }}吨</div>
          </template>
        </el-table-column>
        <el-table-column prop="cradleLbs" label="提箱地" show-overflow-tooltip width="250">
          <template v-slot="scope">
            <div>{{ `${scope.row.firstPlaceProvince} ${scope.row.firstPlaceCity} ${scope.row.firstPlaceDistrict}
              ${scope.row.firstPlaceDetail}` }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="assembleLbs" label="送货/装货地" show-overflow-tooltip width="250">
          <template v-slot="scope">
            <div>{{ `${scope.row.secondPlaceProvince} ${scope.row.secondPlaceCity} ${scope.row.secondPlaceDistrict}
              ${scope.row.secondPlaceDetail}` }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="level" label="派单方信用" show-overflow-tooltip width="150">
          <template v-slot="scope">
            <div v-if="scope.row.level && scope.row.level == 'logistics_hd_merchant_level_1'">一级商户</div>
            <div v-if="scope.row.level && scope.row.level == 'logistics_hd_merchant_level_2'">二级商户</div>
          </template>
        </el-table-column>
        <el-table-column prop="level" label="支付方式" show-overflow-tooltip width="150">
          <template v-slot="scope">
            <span v-if="scope.row.payMethod === 'wechat'" class="text-style">支付宝</span>
            <span v-if="scope.row.payMethod === '23'" class="text-suggest">支付宝支付</span>
            <span v-if="scope.row.payMethod === '33'" class="text-interrupt">银行卡转账</span>
            <span v-if="scope.row.payMethod === 'CASH'" class="text-bug">现金</span>
            <span v-if="scope.row.payMethod === '45'" class="text-bug">月结</span>
          </template>
        </el-table-column>
        <el-table-column prop="containerCirculateAmount" label="剩余箱量" show-overflow-tooltip width="110">
          <template v-slot="scope">
            <div>{{ (scope.row.containerCirculateAmount || 0) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="deliveryOnceCost" label="每车运费" show-overflow-tooltip width="110">
          <template v-slot="scope">
            <div>{{ (scope.row.deliveryOnceCost || 0) }} 元</div>
          </template>
        </el-table-column>

        <el-table-column prop="urgentDeliveryOnceCost" label="加急费" show-overflow-tooltip width="110">
          <template v-slot="scope">
            <div>{{ (scope.row.urgentDeliveryOnceCost || 0) }} 元</div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination background align="center" layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 50, 100]"
        :current-page="searchForm.page" :page-size="searchForm.limit" @current-change="pageChange"
        :total="totalCount" />
    </div>

    <!-- 详情页 -->
    <el-dialog :visible.sync="detailDialogShow" title="订单详情">
      <div class="addressDiv">
        <el-row :gutter="20">
          <el-col :span="detailData.hasDestination ? 8 : 12"
            style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
            <el-row style="color: dodgerblue; font-weight: bold;">提箱地</el-row>
            <el-row style="color: black; font-weight: bold; font-size: 18px;">{{ detailData.firstPlaceDetail ||
              '' }}</el-row>
            <el-row style="color: black; font-size: 14px;">{{ `${detailData.firstPlaceProvince}
              ${detailData.firstPlaceCity}
              ${detailData.firstPlaceDistrict}` }}</el-row>
            <el-row style="color: dimgrey; font-size: 12px;">提箱有效时间段:</el-row>
            <el-row style="color: dimgrey; font-size: 12px;">{{ dateFormat2Str(Math.trunc(detailData.firstPlaceBeginTime
              /
              100)) }} - {{ dateFormat2Str(Math.trunc(detailData.firstPlaceEndTime / 100)) }}</el-row>
          </el-col>

          <el-col :span="detailData.hasDestination ? 8 : 12"
            style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
            <el-row style="color: darkorange; font-weight: bold;">送货/装货地</el-row>
            <el-row style="color: black; font-weight: bold; font-size: 18px;">{{ detailData.secondPlaceDetail ||
              '' }}</el-row>
            <el-row style="color: black; font-size: 14px;">{{ `${detailData.secondPlaceProvince}
              ${detailData.secondPlaceCity} ${detailData.secondPlaceDistrict}` }}</el-row>
            <el-row style="color: dimgrey; font-size: 12px;">送货/装货有效时间段:</el-row>
            <el-row style="color: dimgrey; font-size: 12px;">{{
              dateFormat2Str(Math.trunc(detailData.secondPlaceBeginTime /
                100)) }} - {{ dateFormat2Str(Math.trunc(detailData.secondPlaceEndTime / 100)) }}</el-row>
          </el-col>

          <el-col v-if="detailData.hasDestination" :span="8"
            style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); padding: 5px; padding-left: 20px;">
            <el-row style="color: forestgreen; font-weight: bold;">收箱地</el-row>
            <el-row style="color: black; font-weight: bold; font-size: 18px;">{{ detailData.thirdPlaceDetail ||
              '' }}</el-row>
            <el-row style="color: black; font-size: 14px;">{{ `${detailData.thirdPlaceProvince}
              ${detailData.thirdPlaceCity}
              ${detailData.thirdPlaceDistrict}` }}</el-row>
            <el-row style="color: dimgrey; font-size: 12px;">到收箱地有效时间段:</el-row>
            <el-row style="color: dimgrey; font-size: 12px;">{{ dateFormat2Str(Math.trunc(detailData.thirdPlaceBeginTime
              /
              100)) }} - {{ dateFormat2Str(Math.trunc(detailData.thirdPlaceEndTime / 100)) }}</el-row>
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="2">
        <el-col :span="12" style="margin-top:20px;">
          <el-card class="box-card basicDiv">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold; font-size: 16px;">基本信息</span>
            </div>

            <el-row :gutter="5">
              <el-col :span="12">派单方：</el-col>
              <el-col :span="12">{{ detailData.businessLicenseName || '' }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">联系方式：</el-col>
              <el-col :span="12">{{ detailData.contactPhone || '' }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">船公司：</el-col>
              <el-col :span="12">{{ detailData.shipCompanyCode || '' }} {{ detailData.shipCompanyName || '' }}</el-col>
            </el-row>

            <!-- <el-row :gutter="5">
              <el-col :span="12">提示：</el-col>
              <el-col :span="12" class="text-lose">{{ detailData.shipRemark || '' }}</el-col>
            </el-row> -->

            <el-row :gutter="5">
              <el-col :span="12">提（运）单号：</el-col>
              <el-col :span="12">{{ detailData.extractIdentity || '' }}</el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="12">类型：</el-col>
              <el-col :span="12">{{ detailData.importAndExport == 'Include' ? '进出口运输' : detailData.importAndExport ==
                'None'
                ? '无进出口运输' : '' }}，{{ detailData.goodsLoadingAndUnloading == 'Include' ? '有卸/装货要求' :
                  detailData.goodsLoadingAndUnloading == 'None' ? '无卸/装货要求' : '' }}</el-col>
            </el-row>
            <el-row :gutter="5" v-if="detailData.importAndExport == 'Include'">
              <el-col :span="12">进出口类别：</el-col>
              <el-col :span="12">{{ detailData.importAndExportCategory }}</el-col>
            </el-row>
          </el-card>

          <el-card class="box-card deliveryDiv" style="margin-top: 5px;">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold; font-size: 16px;">配送要求</span>
            </div>

            <el-row :gutter="5">
              <el-col :span="12">车队要求：</el-col>
              <el-col :span="12" v-if="detailData.waybillCarTeams">
                <div v-for="item in detailData.waybillCarTeams" :key='item.id'>{{ item.carTeamName }}， </div>
              </el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="12">车辆要求：</el-col>
              <el-col :span="12" v-if="detailData.waybillCars">
                <div v-for="item in detailData.waybillCars" :key='item.id'>{{ item.carNumber }}， </div>
              </el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">车况要求：</el-col>
              <el-col :span="12">{{ detailData.carConditionDemand || '' }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">配送要求：</el-col>
              <el-col :span="12">{{ detailData.carDeliveryDemand || '' }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">备注：</el-col>
              <el-col :span="12">{{ detailData.remark || '' }}</el-col>
            </el-row>
          </el-card>


          <el-card class="box-card costDiv" style="margin-top: 5px;">
              <div>附件</div>
              <div>
                <a style="margin-right: 10px;" :href="item.path" :download="item.name" target="_blank" v-for="(item,index) in detailData.billsFiles" :key="index">{{ item.name }}</a>
              </div>
            </el-card>
        </el-col>

        <el-col :span="12" style="margin-top:20px">
          <el-card class="box-card containerDiv">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold; font-size: 16px;">集装箱信息</span>
            </div>

            <el-row :gutter="5">
              <el-col :span="12">尺寸：</el-col>
              <el-col :span="12">{{ detailData.containerSize }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">箱型：</el-col>
              <el-col :span="12">{{ detailData.containerShape }}</el-col>
            </el-row>
            <el-row :gutter="5">
                <el-col :span="12">单箱货重:</el-col>
                <el-col :span="12">{{ detailData.goodsWeightMin || '0' }}吨</el-col>
            </el-row>


            <el-row :gutter="5">
              <el-col :span="12">空/重箱：</el-col>
              <el-col :span="12">{{ detailData.containerEmptyOrFull == 'Full' ? '重箱' : '空箱' }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">配送形式：</el-col>
              <el-col :span="12">{{ detailData.deliveryMode == 'Double' ? '双背' : detailData.deliveryMode == 'Single'
                ? '单背' : '多背' }}</el-col>
            </el-row>
            <el-row :gutter="5" v-if="detailData.deliveryMode=='More'">
              <el-col :span="12">多背数量</el-col>
              <el-col :span="12">{{ detailData.deliveryOnceAmount }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">总箱量：</el-col>
              <el-col :span="12">
                {{ detailData.containerTotalAmount || 0 }}
              </el-col>
            </el-row>
            <el-row :gutter="5">
              <el-col :span="12">箱门朝向：</el-col>
              <el-col :span="12">
                {{ detailData.orientationStr }}
              </el-col>
            </el-row>
          </el-card>

          <el-card class="box-card costDiv" style="margin-top: 5px;">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold; font-size: 16px;">费用信息</span>
            </div>

            <el-row :gutter="5">
              <el-col :span="12">每车运费：</el-col>
              <el-col :span="12">{{ (detailData.deliveryOnceCost || 0) }} 元/辆</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="12">运费总额：</el-col>
              <el-col :span="12">{{ (detailData.deliveryEstimateTotalCost || 0) }} 元</el-col>
            </el-row>

            <!-- <el-row :gutter="5">
              <el-col :span="12">已抢单服务费：</el-col>
              <el-col :span="12">{{
                (detailData.orderValidFreightServiceFeeCost + detailData.orderValidUrgentServiceFeeCost ||
                  0) }} 元</el-col>
            </el-row> -->
            <el-row :gutter="5">
              <el-col :span="12">发票：</el-col>
              <el-col :span="12">{{ detailData.provideInvoice == 'Yes' ? '提供' : '不提供' }}</el-col>
            </el-row>
          </el-card>

          <el-card class="box-card costDiv" style="margin-top: 5px;">
              <div>单据图片</div>
              <div>
                <el-image v-for="(item,index) in detailData.billsImage" :key="index"
                  style="width: 100px; height: 100px"
                  :src="item.path"
                ></el-image>
              </div>
            </el-card>
        </el-col>

        <el-col v-if="detailType == 'upd'">
          <el-form>
            <el-form-item label="接单箱数" required>
              <el-input v-model="orderTakNumber" placeholder="请输入接单箱数"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitOrderTak">接 单</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import Header from '../../components/Header.vue'

export default {
  name: 'order_taK_list',
  components: { Header },
  data() {
    return {
      dayjs,

      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        merchantNickname: null,
        merchantPhone: null,
        extractIdentity: null,
        waybillDeliveryMode: null,
        //作业地点
        firstPlaceCity:null,
        firstPlaceDistrict:null,

        starDate: null,
        endDate: null,
      },
      orderList: [],//订单列表
      totalCount: 0,
      queryLoading: false,

      //详情
      detailData: {},
      detailDialogShow: false,

      detailType: "info",

      //接单
      orderTakNumber: null,
    }
  },
  mounted() {
    this.searchForm.page = 1;
    this.orderList = [];
    this.totalCount = 0;
    this.getOrderList()
  },
  methods: {
    // 根据用户id获取派单方信息
    async getOrderList() {
      this.queryLoading = true;
      let res = await this.$req({
        url: `/waybillPc/query`,
        method: 'GET',
        data: this.searchForm
      })

      if (res.code == 200) {
        this.orderList = res.data;
        this.totalCount = res.count;
      }
      this.queryLoading = false;
    },
    pageChange(e) {
      this.searchForm.page = e;
      this.getOrderList()
    },
    trainNumber(data) {

      // console.log(data.deliveryOnceAmount,"data")

      return parseInt(Math.ceil(data.containerCirculateAmount / data.deliveryOnceAmount));

      // if (data.deliveryMode == 'Double') {
      //   return parseInt(data.containerCirculateAmount / 2);
      // } else if (data.deliveryMode == 'More') {
      //   return parseInt(data.containerCirculateAmount / 3);
      // } else {
      //   return data.containerCirculateAmount;
      // }
    },
    async infoDetail(row) {
      this.detailType = "info";

      let res = await this.$req({
        url: `/waybillPc/getDetailById/` + row.id,
        method: 'GET',
      });

      this.detailData = res.data
      this.detailData.serviceFeeChargeCost = this.detailData.serviceFeeChargeCost || 0
      this.detailData.serviceFeeTotalCost = this.detailData.serviceFeeTotalCost || 0
      this.detailData.bills =JSON.parse(this.detailData.bills);

      this.detailData.billsImage= this.detailData.bills.imgs;
      this.detailData.billsFiles= this.detailData.bills.files;


      this.detailDialogShow = true
    },
    async orderTakHandel(row) {
      this.detailType = "upd";
      let res = await this.$req({
        url: `/waybillPc/getDetailById/` + row.id,
        method: 'GET',
      });

      this.detailData = res.data
      this.detailData.serviceFeeChargeCost = this.detailData.serviceFeeChargeCost || 0
      this.detailData.serviceFeeTotalCost = this.detailData.serviceFeeTotalCost || 0
      this.detailDialogShow = true
      //剩余接单数量
      this.orderTakNumber = this.detailData.containerCirculateAmount;

    },
    async submitOrderTak() {
      if (!this.orderTakNumber) {
        this.$message({ type: 'error', message: '请输入接单箱数', duration: 1000 })
        return ;
      }
      //计算是否是 正背的数量
      if(this.orderTakNumber%this.detailData.deliveryOnceAmount!=0){
        this.$message({ type: 'error', message: '接单箱数必须是'+this.detailData.deliveryOnceAmount+'的倍数', duration: 1000 })
        return;
      }



      let data = {
        sourceId: this.detailData.id,
        orderTakNumber: this.orderTakNumber,
        userId: JSON.parse(localStorage.getItem("userinfo")).id,
      }

      let res = await this.$req({
        url: `/waybillPc/getTakReqVO`,
        method: 'GET',
        data: data
      });
      if (res.code == 200) {
        this.$message({ type: 'success', message: '接单成功', duration: 1000 })
        this.getOrderList()
        this.detailDialogShow = false;
      } else {
        this.$message({ type: 'error', message: res.msg, duration: 1000 })
      }
    },
    resetClick() { 
     this.searchForm={
        page: 1,
        limit: 10,
        searchKey: null,
        merchantNickname: null,
        merchantPhone: null,
        extractIdentity: null,
        waybillDeliveryMode: null,
        //作业地点
        firstPlaceCity:null,
        firstPlaceDistrict:null,

        starDate: null,
        endDate: null,
      }
      this.orderList=[];
      this.totalCount=0;
      this.getOrderList();
    },
    searchClick() { 
        this.searchForm.page=1;
        this.orderList=[];
        this.totalCount=0;
        this.getOrderList();
    },

  }
}
</script>

<style scoped lang="stylus">
.container
  padding-top: 104px
  width 100vw
  .screen-content
    width 100vw
    .search
      width 1200px
      display: flex
      justify-content: center;
      .btns
        display: flex
    
</style>
